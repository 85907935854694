import React, {
    useCallback, useEffect, createContext, ReactNode, useState,
} from 'react';
import classNames from 'classnames';
import { take } from 'ramda';

const CUSTOM_EVENT_MONIKER = 'Snackbar Tick';
export const DEFAULT_MAX_AGE_MS = 3000;
export const MAX_COUNT = 3;

export enum AlertType {
    Information,
    Error,
    Warning,
}

interface SnackbarItem {
    text: string,
    createdAt: number,

    // If not persistent, defines how long (ms) the alert is displayed (defaults to DEFAULT_MAX_AGE_MS)
    maxAge: number,
    alertType: AlertType,

    // If set, prevents automatic removal after the maxAge has elapsed
    persistent?: boolean,
}

export const SnackbarContext = createContext({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    append: (text: string, alertType: AlertType, lifecycle?: number | boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dismiss: (timestamp: number) => {},
    items: [] as Array<SnackbarItem>,
});

interface WithSnackbarProps {
    children: ReactNode,
}

setInterval(() => {
    document.dispatchEvent(new Event(CUSTOM_EVENT_MONIKER));
}, 250);

interface SnackbarProps {
    items: Array<SnackbarItem>,
    dismiss: (timestamp: number) => void,
}

export function Snackbar(props: SnackbarProps) {
    const { items, dismiss } = props;

    if (!items.length) {
        return null;
    }

    const snackbarItems = take(MAX_COUNT, items).map((item) => {
        const className = classNames('snack', AlertType[item.alertType]);
        const dismissItem = () => dismiss(item.createdAt);

        return (
            <div className={className}>
                <button
                    onClick={dismissItem}
                    type="button"
                    className="dismiss"
                >X
                </button>
                <div className="message">{item.text}</div>
            </div>
        );
    });

    return (
        <section className="snackbar">
            {snackbarItems}
        </section>
    );
}

export default function WithSnackbar(props: WithSnackbarProps) {
    const { children } = props;

    const [snackbars, setSnackbars] = useState<Array<SnackbarItem>>([]);
    const append = useCallback((text: string, alertType: AlertType, lifecycle?: number | boolean) => {
        const persistent = typeof lifecycle === 'boolean' ? lifecycle as boolean : false;
        const maxAge = typeof lifecycle === 'number' ? lifecycle as number : DEFAULT_MAX_AGE_MS;

        setSnackbars([
            ...snackbars,
            {
                text,
                alertType,
                persistent,
                maxAge,
                createdAt: new Date().getTime(),
            },
        ]);
    }, [snackbars]);
    const dismiss = useCallback((timestamp: number) => {
        setSnackbars(
            snackbars.filter((item) => item.createdAt !== timestamp),
        );
    }, [snackbars]);
    const trimDeadSnackbarItems = useCallback(() => {
        const now = new Date().getTime();
        const alive = (item: SnackbarItem) => item.persistent || item.createdAt + item.maxAge >= now;
        const remaining = snackbars.filter(alive);

        if (remaining.length !== snackbars.length) {
            setSnackbars(remaining);
        }
    }, [snackbars]);
    useEffect(() => {
        document.addEventListener(CUSTOM_EVENT_MONIKER, trimDeadSnackbarItems);

        return () => {
            document.removeEventListener(CUSTOM_EVENT_MONIKER, trimDeadSnackbarItems);
        };
    }, [trimDeadSnackbarItems]);

    return (
        <SnackbarContext.Provider value={{ append, dismiss, items: snackbars }}>
            {children}
        </SnackbarContext.Provider>
    );
}
