import { groupBy } from 'ramda';
import React, { ChangeEvent, useRef } from 'react';
import dayjs from 'dayjs';

import Axle from './Axle';
import { State as ApplicationState } from '../../state/application';
import { Action, actionCreators, State } from '../../state/inspection-edit';
import TrackTireDetail from './TrackTireDetail';
import {
    Assignment, Client,
    EquipmentAsset,
    InspectionStatus,
    SubjectCondition,
    TrackTireInspection, UserLogin,
} from '../../types';
import AppHeader from '../../controls/AppHeader';
import {
    DetailPanel, LabelWrapper, NamedInput, NotesInput,
} from '../../controls/index';
import Services from '../../services';
import formatDueDate from '../../utils/format-due-date';
import RenderIf from '../../controls/RenderIf';
import {
    ADMIN, DATE_FORMAT, UNKNOWN, Unset,
} from '../../constants';
import isConsideredOpen from '../../utils/is-considered-open';
import getInspectionStatusOptions from '../../utils/get-inspection-status-options';
import StaticImageWithOverlay from '../../controls/StaticImageWithOverlay';

interface RenderProps {
    photosCache: Record<string, string | symbol>,
    currentUser: UserLogin,
    isReadOnly: boolean,
    isReadyToSubmit: boolean,
    state: State,
    dispatch: React.Dispatch<Action>,
    onError: (message: string) => void,
    client: Client,
    asset: EquipmentAsset,
    apiServices: Services,
    replaceSelectedSubject: (
        replaceWith: TrackTireInspection,
        newlyAddedImages: Record<number, string>,
    ) => void,
    deleteSubject: (toRemove: TrackTireInspection) => void,
    getApplicationState: () => ApplicationState,
    updateStatus: (newStatus: InspectionStatus, stayOnPage: boolean, reason?: string | undefined) => void,
    assignment: Assignment,
    handleIncludeInReportChanged: (event: React.ChangeEvent<HTMLInputElement>) => void,
    updateCondition: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    handleHoursChanged: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleNotesChanged: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onImageDeleted: (imageId: string) => void,
    handleInspectionDeletion: () => void,
}

export default function Page(props: RenderProps) {
    const {
        photosCache,
        currentUser,
        state,
        dispatch,
        onError,
        asset,
        apiServices,
        replaceSelectedSubject,
        deleteSubject,
        getApplicationState,
        updateStatus,
        assignment,
        handleIncludeInReportChanged,
        updateCondition,
        handleHoursChanged,
        handleNotesChanged,
        handleInspectionDeletion,
        onImageDeleted,
        isReadOnly,
        isReadyToSubmit,
        client,
    } = props;

    const axles = groupBy((subj) => subj.axle || '(unknown)', state.subjects);
    const axlesList = Object
        .keys(axles)
        .sort()
        .map(
            (name) => (
                <Axle
                    isReadOnly={isReadOnly}
                    key={name}
                    name={name}
                    subjects={axles[name]}
                    selectedSubject={state.selectedSubject}
                    setSelectedSubject={(subj) => dispatch(actionCreators.setSelectedSubject(subj))}
                    addSubjectOnSide={(side) => {
                        dispatch(
                            actionCreators.addSubjectForAxleAndSide(name, side, asset?.id ?? UNKNOWN),
                        );
                    }}
                />
            ),
        );
    const justAboveTiresAndTracks = useRef(null);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleImageDeletion = (imageId: string) => apiServices.deletePhoto(imageId)
        .then(() => onImageDeleted(asset.primaryImageId ?? UNKNOWN));

    const tireTrackDetail = state.selectedSubject
        && (
            <TrackTireDetail
                onImageDeleted={handleImageDeletion}
                onError={onError}
                isReadOnly={isReadOnly}
                justAboveTiresAndTracks={justAboveTiresAndTracks}
                photosCache={photosCache}
                specs={state.specs}
                setSpecs={(specs) => dispatch(actionCreators.setSpecs(specs))}
                apiServices={apiServices}
                selectedSubject={state.selectedSubject}
                onSubjectUpdated={replaceSelectedSubject}
                onSubjectDeleted={deleteSubject}
            />
        );
    const conditionOptions = Object.entries(SubjectCondition)
        .filter(([, value]) => {
            const isNumber = !Number.isNaN(parseInt(value as string, 10));

            return isNumber;
        })
        .map(([name, value]) => <option value={value}>{name}</option>);
    const handleStatusChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        const { value } = event.target;
        const statusValue = parseInt(value, 10) as InspectionStatus;

        updateStatus(statusValue, true);
    };
    const imageSrc = (
        asset.primaryImageId
        && photosCache[asset.primaryImageId] !== undefined
        && photosCache[asset.primaryImageId] !== Unset
    )
        ? photosCache[asset.primaryImageId] as string
        : null;

    let submitButton;
    let headImage;

    switch (state.status) {
        case InspectionStatus.Open:
        case InspectionStatus.Errored:
            submitButton = (
                <button
                    disabled={!isReadyToSubmit}
                    onClick={() => updateStatus(InspectionStatus.SubmittedForReview, false)}
                    type="button"
                    title={isReadyToSubmit ? '' : 'At least one track or tire has not been marked as ready'}
                >Complete
                </button>
            );

            headImage = (
                <StaticImageWithOverlay src={imageSrc} alt="" />
            );

            break;
        case InspectionStatus.SubmittedForReview:
            submitButton = (
                <button
                    onClick={() => updateStatus(InspectionStatus.Open, false, `Reopened by ${currentUser.username} on ${dayjs().format(DATE_FORMAT)}.`)}
                    type="button"
                    title={isReadyToSubmit ? '' : 'At least one track or tire has not been marked as ready'}
                >Edit
                </button>
            );

            headImage = (
                <StaticImageWithOverlay src={imageSrc} alt="" />
            );

            break;
        default:
            submitButton = null;
            break;
    }

    return (
        <div className="inspectionDetail">
            <main>
                <AppHeader getApplicationState={getApplicationState} title={client.name}>
                    <></>
                </AppHeader>
                <div className="assignmentDetail">
                    <h1>{formatDueDate(assignment.dueDate)}</h1>
                    <span className="assetName">{asset.name}</span>
                </div>

                <div className="inspectionDetail--head">
                    {headImage}
                    <DetailPanel className="assetDetails">
                        <dl>
                            {asset.vin && (
                                <>
                                    <dt>Vin</dt>
                                    <dd>{asset?.vin}</dd>
                                </>
                            )}

                            {asset.internalIdentifier && (
                                <>
                                    <dt>ID</dt>
                                    <dd>{asset?.internalIdentifier}</dd>
                                </>
                            )}

                            {asset.year && (
                                <>
                                    <dt>Year</dt>
                                    <dd>{asset?.year}</dd>
                                </>
                            )}
                        </dl>
                    </DetailPanel>
                </div>
                <hr />
                <form className="detailInspection--fields">
                    <NamedInput
                        className="large-label"
                        type="checkbox"
                        name="includeInReport"
                        label="Include in Report"
                        value={state.includeInReport}
                        onChange={isReadOnly ? undefined : handleIncludeInReportChanged}
                    />

                    <RenderIf condition={currentUser.role === ADMIN}>
                        <LabelWrapper
                            className="large-label field statusInput"
                            childId="namedInputstatus"
                            label="Status"
                        >
                            <select
                                id="namedInputcondition"
                                value={state.status}
                                disabled={isReadOnly}
                                onChange={handleStatusChanged}
                            >
                                {getInspectionStatusOptions()}
                            </select>
                        </LabelWrapper>

                        <hr />
                    </RenderIf>

                    <LabelWrapper
                        className="large-label field conditionInput"
                        childId="namedInputcondition"
                        label="Condition"
                    >
                        <select
                            id="namedInputcondition"
                            value={state.condition}
                            disabled={isReadOnly}
                            onChange={updateCondition}
                        >
                            {conditionOptions}
                        </select>
                    </LabelWrapper>

                    <NamedInput
                        className="large-label"
                        type="number"
                        name="hours"
                        label="Hours"
                        value={state.hours}
                        onChange={isReadOnly ? undefined : handleHoursChanged}
                    />

                    <NotesInput
                        className="large-label"
                        name="notes"
                        label="Notes"
                        value={state.notes}
                        onChange={isReadOnly ? undefined : handleNotesChanged}
                    />

                    <section className="tiresAndTracks" ref={justAboveTiresAndTracks}>
                        <h3 className="section-head"><span className="large">Tires &amp; Tracks</span></h3>
                        <div className="axles">
                            {axlesList}
                            <button
                                className="addAxle"
                                type="button"
                                data-usage="add-axle"
                                disabled={isReadOnly}
                                onClick={() => dispatch(actionCreators.addNewAxle(asset?.id ?? UNKNOWN))}
                            >
                                Add Axle
                            </button>
                        </div>
                        {tireTrackDetail}

                        <div className="actions">
                            {submitButton}
                            <RenderIf condition={isConsideredOpen(state.status)}>
                                <button
                                    type="button"
                                    onClick={() => updateStatus(InspectionStatus.SubmittedForReview, false, `Completed without inspection by ${currentUser.username} on ${dayjs().format(DATE_FORMAT)}.`)}
                                    disabled={isReadOnly}
                                >Complete Without Inspection
                                </button>
                            </RenderIf>
                            <button
                                type="button"
                                className="risky"
                                disabled={isReadOnly}
                                onClick={handleInspectionDeletion}
                            >Delete Inspection
                            </button>
                        </div>
                    </section>
                </form>
            </main>
        </div>
    );
}
