import {
    Action,
    ActionType,
    AddNewAxleAction,
    AddSubjectToAxleAndSideAction,
    SetConditionAction,
    SetHoursAction,
    SetIncludeInReportAction,
    SetNotesAction,
    SetPendingSaveAction,
    SetSelectedSubjectAction,
    SetSpecsAction,
    SetStatusAction,
    SetSubjectsAction,
    State,
} from '../index';
import addSubjectToAxleAndSide from './add-subject-to-axle-and-side';
import addNewAxle from './add-new-axle';

export default function index(state: State, action: Action): State {
    switch (action.actionType) {
        case ActionType.SetInitialized: {
            return {
                ...state,
                initialized: true,
            };
        }
        case ActionType.SetPendingSave: {
            const { value } = action as SetPendingSaveAction;

            return {
                ...state,
                pendingSave: value,
            };
        }
        case ActionType.SetStatus: {
            const { value } = action as SetStatusAction;

            return {
                ...state,
                status: value,
            };
        }
        case ActionType.SetCondition: {
            const { value } = action as SetConditionAction;

            return {
                ...state,
                condition: value,
            };
        }
        case ActionType.SetHours: {
            const { value } = action as SetHoursAction;

            return {
                ...state,
                hours: value,
            };
        }
        case ActionType.SetNotes: {
            const { value } = action as SetNotesAction;

            return {
                ...state,
                notes: value,
            };
        }
        case ActionType.SetIncludeInReport: {
            const { value } = action as SetIncludeInReportAction;

            return {
                ...state,
                includeInReport: value,
            };
        }
        case ActionType.SetSubjects: {
            const { value } = action as SetSubjectsAction;

            return {
                ...state,
                subjects: value,
            };
        }
        case ActionType.AddSubjectForAxleAndSide:
            return addSubjectToAxleAndSide(state, action as AddSubjectToAxleAndSideAction);
        case ActionType.SetSelectedSubject: {
            const { value } = action as SetSelectedSubjectAction;

            return {
                ...state,
                selectedSubject: value,
            };
        }
        case ActionType.SetSpecs: {
            const { value } = action as SetSpecsAction;

            return {
                ...state,
                specs: value,
            };
        }
        case ActionType.AddNewAxle:
            return addNewAxle(state, action as AddNewAxleAction);
        default:
            throw new Error('Unknown action type');
    }
}
