import { Inspection, TrackTireInspection } from '../types';

function coerceToNumber(value: any): number {
    const valueAsNumber = parseFloat(value);

    if (Number.isNaN(valueAsNumber)) {
        return 0;
    }
    return valueAsNumber;
}

export default function inspectionsFromDtoEntities(inspections: [any]) {
    return inspections.map((inspection) => {
        const reparsedSubjects = (inspection.subjects ?? [])
            .map((subject: any): TrackTireInspection[] => {
                const [alignment, axlePosition] = subject.position.split(' ');
                const position = { alignment, axlePosition };

                return {
                    ...subject,
                    position,
                    images: subject.images ?? [],
                };
            });

        const ret: Inspection = {
            ...inspection,
            condition: coerceToNumber(inspection?.condition),
            subjects: reparsedSubjects,
        };

        return ret;
    });
}
