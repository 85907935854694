import React from 'react';
import classNames from 'classnames';

interface InputWrapperProps {
    className: string | undefined,
    children: React.ReactChildren | React.ReactNode,
}
export default function InputWrapper(props: InputWrapperProps) {
    const { className, children } = props;

    return <div className={classNames('inputWrapper', className)}>{children}</div>;
}
