import { History } from 'history';
import { Client, Inspection } from '../types';
import Services from '../services';
import { UNKNOWN } from '../constants';

export default function tryDeleteInspection(
    inspection: Inspection,
    apiServices: Services,
    onInspectionDeleted: (inspectionId: string) => void,
    onError: (message: string) => void,
    history: History,
    client: Client,
) {
    // eslint-disable-next-line no-alert
    if (inspection && window.confirm('Are you sure you wish to delete this inspection?')) {
        apiServices.deleteInspection(inspection.id ?? UNKNOWN)
            .then(() => {
                onInspectionDeleted(inspection.id ?? UNKNOWN);
                history.replace(`/clients/${client.id}`);
            })
            .catch((e) => onError(e.message));
    }
}
