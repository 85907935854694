import React from 'react';

import unload from '../state/application/thunks/unload';
import Settings from '../views/Settings';
import { AppMessages, User } from '../types';
import { AppComponentProps } from './route-wrapper';
import Loading from '../views/Loading';
import backgroundLoad from '../state/application/thunks/background-load';
import { actionCreators } from '../state/application';
import { AlertType } from '../controls/Snackbar';
import upsertSettings from '../state/application/thunks/upsert-settings';

export default function SettingsConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
    } = props;
    const state = getState();

    if (loading.currentUser) {
        return <Loading />;
    }

    const appendLogRecord = (entry: string, level: AlertType) => dispatch(actionCreators.appendBackgroundLoadLogs(entry, level));

    return (
        <Settings
            lastAutomatedRefresh={state.backgroundLoad}
            backgroundLoadLogs={state.backgroundLoadLogs}
            getApplicationState={getState}
            currentUser={state.currentUser as User}
            onLogout={unload(dispatch, getState)}
            onUnregister={() => state.appEvents.next(
                { message: AppMessages.ServiceWorkerShouldUnregister },
            )}
            onTriggerBackgroundLoad={backgroundLoad(dispatch, getState, appendLogRecord)}
            onUpsertSettings={upsertSettings(dispatch, getState)}
        />
    );
}
