import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { sortBy } from 'ramda';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import { DATE_FORMAT, UNKNOWN, Unset } from '../constants';
import {
    Assignment, AssignmentShare,
    Category,
    Client,
    EquipmentAsset,
    Geolocation,
    Inspection,
    InspectionStatus,
    Employee,
} from '../types';
import Loading from './Loading';
import AppHeader from '../controls/AppHeader';
import { DetailPanel } from '../controls/index';
import Services from '../services';
import tryAddEquipmentInspection from '../utils/try-add-equipment-inspection';
import parseLocationDisplayDetails, { LocationDisplayType } from '../utils/parse-location-display-details';
import formatLatLngForAppleMaps from '../utils/format-lat-lng-for-apple-maps';
import formatStreetAddressForAppleMaps from '../utils/format-street-address-for-apple-maps';
import { tryGetOpenAssignmentForAsset } from '../utils/assignment-status';
import List, { ListItem } from '../controls/List';
import Actions from '../controls/Actions';
import RenderIf from '../controls/RenderIf';
import CategoryTrail from '../controls/CategoryTrail';
import { State } from '../state/application';
import isConsideredOpen from '../utils/is-considered-open';
import StaticImageWithOverlay from '../controls/StaticImageWithOverlay';

interface Props {
    photosCache: Record<string, string | symbol>,
    clients: Array<Client>,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    assignments: Array<Assignment>,
    locations: Array<Geolocation>,
    categories: Array<Category>,
    employees: Array<Employee>,
    getApplicationState: () => State,
    apiServices: Services,
    onError: (message: string) => void,
    onDelete: (assetId: string | null) => Promise<void>,
    onInspectionAdded: (inspection: Inspection) => void,
    onAssignmentAdded: (assignment: Assignment) => void,
    onShareAdded: (share: AssignmentShare) => void,
    loading: {
        clients: boolean,
        equipment: boolean,
        inspections: boolean,
        assignments: boolean,
        locations: boolean,
        categories: boolean,
    },
    match: routerMatch<ExtractRouteParams<string, string>>,
}

export default function EquipmentDetail(props: Props) {
    const {
        photosCache,
        match,
        onError,
        loading,
        equipment,
        employees,
        assignments,
        clients,
        inspections,
        locations,
        categories,
        getApplicationState,
        onDelete,
        onInspectionAdded,
        onAssignmentAdded,
        onShareAdded,
        apiServices,
    } = props;

    const asset = loading.equipment ? null : equipment.find((item) => `${item.id}` === match.params.equipmentId);
    const client = loading.clients ? null : clients.find((item) => item.id === asset?.clientId);

    const addNewInspection = () => {
        if (!asset) {
            throw new Error('should not happen');
        }

        tryAddEquipmentInspection(
            asset,
            clients,
            assignments,
            inspections,
            apiServices,
        )
            .then(([maybeCreatedAssignment, inspection, share]) => {
                if (!assignments.find(({ id }) => id === maybeCreatedAssignment.id)) {
                    onAssignmentAdded(maybeCreatedAssignment);
                }
                if (share) {
                    onShareAdded(share);
                }

                const newRoute = [
                    `/clients/${client?.id}`,
                    `/assignments/${inspection.assignmentId}`,
                    `/inspections/${inspection.id}`,
                ].join('');

                onInspectionAdded(inspection);
                getApplicationState().appHistory.push(newRoute);
            })
            .catch((e) => onError(e));
    };

    if (loading.clients
        || loading.equipment
        || loading.inspections
        || loading.assignments
        || loading.locations
        || loading.categories
    ) {
        return <Loading />;
    }

    if (!asset || !client) {
        return null;
    }

    const onDeleteClicked = () => {
        if (asset.id) {
            onDelete(asset.id)
                .then(() => {
                    getApplicationState().appHistory.replace(`/clients/${client?.id}/equipment`);
                })
                .catch(() => {});
        }
    };

    const horsepowerDetails = asset?.horsepower
        ? (
            <>
                <dt>Horsepower</dt>
                <dd>{asset.horsepower}</dd>
            </>
        )
        : undefined;

    const matchingInspectionsWithAssignment: Array<[Inspection, Assignment]> = inspections
        .filter((item) => item.equipmentId === asset.id)
        .map((inspection) => {
            const assignment = assignments.find((asg) => asg.id === inspection.assignmentId);

            if (!assignment) {
                throw new Error('failed to match assignment for inspection');
            }

            return [inspection, assignment];
        });

    const inspectionItems: Array<ListItem> = sortBy(
        ([inspection]) => (inspection.createdOn ?? '~'),
        matchingInspectionsWithAssignment,
    )
        .map(
            ([inspection, assignment]) => {
                const to = [
                    `/clients/${client.id}`,
                    `assignments/${assignment.id}`,
                    `inspections/${inspection.id}`,
                ].join('/');

                const dueDate = assignment.dueDate
                    ? dayjs(assignment.dueDate).format(DATE_FORMAT)
                    : 'Due date not set';

                const foundInspector = employees.find((employee) => employee.id === inspection.employeeId);

                return {
                    id: inspection.id ?? UNKNOWN,
                    title: dueDate,
                    route: to,
                    className: `status-${inspection.status}`,
                    children: (
                        <>
                            <span className="name">{`${dueDate} by ${foundInspector?.name}`}</span>
                            <RenderIf condition={inspection.status === InspectionStatus.Errored}>
                                <span className="errorLabel">Error</span>
                            </RenderIf>
                            <RenderIf condition={
                                inspection.status === InspectionStatus.SubmittedForReview
                                || inspection.status === InspectionStatus.Closed
                            }
                            >
                                <div className="submitted" />
                            </RenderIf>
                            <RenderIf condition={isConsideredOpen(inspection.status) || inspection.status === null}>
                                <span>Edit</span>
                            </RenderIf>
                        </>
                    ),
                };
            },
        );

    const lastKnownLocation = locations.find(
        ({ id }) => id === asset.currentLocationId,
    );
    const locationDetails = parseLocationDisplayDetails(lastKnownLocation ?? null);
    let addressLink;

    switch (locationDetails) {
        case LocationDisplayType.Geo:
            addressLink = (
                <a
                    target="_blank"
                    href={formatLatLngForAppleMaps(lastKnownLocation)}
                    rel="noreferrer"
                >{lastKnownLocation?.name}
                </a>
            );
            break;
        case LocationDisplayType.Address:
            addressLink = (
                <a
                    target="_blank"
                    href={formatStreetAddressForAppleMaps(lastKnownLocation)}
                    rel="noreferrer"
                >{lastKnownLocation?.name}
                </a>
            );
            break;
        default:
            addressLink = <span>{lastKnownLocation?.name}</span>;
            break;
    }

    const lastKnownLocationDetail = lastKnownLocation
        ? (
            <section className="lastKnownLocation">
                <strong>Last Known Location</strong>
                {addressLink}
                <Link to={`/clients/${client?.id}/equipment/${asset?.id}/edit`}>Edit</Link>
            </section>
        )
        : null;

    const assetCategory = categories.find((cat) => cat.id === asset.categoryId) ?? null;
    const parentCategory = assetCategory?.parentId
        ? (categories.find((cat) => cat.id === assetCategory.parentId) ?? null)
        : null;

    const categoryTrail = (
        <CategoryTrail
            client={client}
            category={assetCategory}
            parentCategory={parentCategory}
        />
    );

    const imageSrc = photosCache[asset.primaryImageId ?? UNKNOWN];
    const photoPanel = imageSrc !== null && imageSrc !== Unset
        ? <StaticImageWithOverlay src={imageSrc as string} alt="" />
        : (
            <Link
                className="image-placeholder"
                to={`/clients/${client?.id}/equipment/${asset?.id}/edit`}
            >Edit to add Photo
            </Link>
        );

    const nextPendingAssignment = tryGetOpenAssignmentForAsset(
        asset,
        assignments,
        inspections,
    );
    const hasMatchingInspection = inspections.find(
        (item) => item.equipmentId === asset.id
        && item.assignmentId === nextPendingAssignment?.id,
    );

    const hasOpenAssignmentButNoInspection = Boolean(nextPendingAssignment) && !hasMatchingInspection;
    const hasNoOpenAssignmentForClient = !assignments.find(
        (asg) => asg.clientId === client.id && isConsideredOpen(asg.status),
    );

    return (
        <div className="equipmentDetail">
            <main>
                <AppHeader getApplicationState={getApplicationState} title={client.name}>
                    <></>
                </AppHeader>
                <h1>{asset.name}</h1>
                {categoryTrail}
                <div className="equipmentDetail--head">
                    <section className="photo">
                        {photoPanel}
                    </section>

                    <DetailPanel className="assetDetails">
                        <dl>
                            {asset.vin && (
                                <>
                                    <dt>Vin</dt>
                                    <dd>{asset?.vin}</dd>
                                </>
                            )}

                            {asset.internalIdentifier && (
                                <>
                                    <dt>ID</dt>
                                    <dd>{asset?.internalIdentifier}</dd>
                                </>
                            )}

                            {asset.year && (
                                <>
                                    <dt>Year</dt>
                                    <dd>{asset?.year}</dd>
                                </>
                            )}

                            {horsepowerDetails}
                        </dl>

                        <Link to={`/clients/${client?.id}/equipment/${asset?.id}/edit`}>Edit</Link>
                    </DetailPanel>
                    {lastKnownLocationDetail}
                </div>

                <section className="inspections">

                    <h2>Inspections</h2>

                    <List
                        className="whitebars inspections-list"
                        itemClass=""
                        prepend={(
                            <Actions>
                                <>
                                    <RenderIf condition={hasOpenAssignmentButNoInspection || hasNoOpenAssignmentForClient}>
                                        <button
                                            type="button"
                                            className="add-action"
                                            onClick={addNewInspection}
                                        >Add new inspection
                                        </button>
                                    </RenderIf>
                                    <RenderIf condition={!hasOpenAssignmentButNoInspection && !hasNoOpenAssignmentForClient}>
                                        <div className="disabled">
                                            <button
                                                type="button"
                                                className="add-action"
                                                disabled
                                            >Add new inspection
                                            </button>
                                            <span>(limit 1 of active)</span>
                                        </div>
                                    </RenderIf>
                                </>
                            </Actions>
                        )}
                        items={inspectionItems}
                    />
                </section>

                <div className="actions">
                    <button
                        type="button"
                        className="risky"
                        onClick={onDeleteClicked}
                    >Delete Equipment
                    </button>
                </div>
            </main>
        </div>
    );
}
