import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { AssignmentShare } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function upsertAssignmentShare(dispatch: Dispatch<Action>, getState: () => State) {
    return (assignmentShare: AssignmentShare) => {
        const { assignmentShares } = getState();

        if (assignmentShares === Unset || assignmentShares === Loading) {
            return;
        }

        dispatch(actionCreators.setAssignmentShares([
            ...(assignmentShares as Array<AssignmentShare>).filter(
                (item) => item.id !== assignmentShare.id,
            ),
            assignmentShare,
        ]));
    };
}
