// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useState } from 'react';
import Services from '../../services';
import AppHeader from '../../controls/AppHeader';
import NamedInput from '../../controls/NamedInput';
import Geo from '../../controls/Geo';
import { GEOLOCATION_OPTIONS, UNKNOWN } from '../../constants';
import Actions from '../../controls/Actions';
import { Client, Geolocation } from '../../types';
import createNewClient from '../../utils/create-new-client';
import { State } from '../../state/application';

interface Props {
    apiServices: Services,
    getApplicationState: () => State,
    onError: (message: string) => void,
    onClientAdded: (client: Client) => void,
    onLocationAdded: (location: Geolocation) => void,
    onCancel: () => void,
}

export default function AddClient(props: Props) {
    const {
        onError,
        apiServices,
        onClientAdded,
        onLocationAdded,
        getApplicationState,
        onCancel,
    } = props;

    const [name, setName] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);

    const isNameValid = name.length > 0;
    const isEmailValid = !email || email.length > 0; // TODO: Employ better email address validation
    const isPhoneValid = !phoneNumber || phoneNumber.length > 0; // TODO: Employ better phone # validation

    const formIsValid = isNameValid && isEmailValid && isPhoneValid;

    const handleGeolocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                setLatitude(coords.latitude);
                setLongitude(coords.longitude);
            },
            () => {
                onError('Failed to retrieve location information.');
            },
            GEOLOCATION_OPTIONS,
        );
    }, [onError]);

    const createClient = useCallback(() => {
        const client: Client = {
            email,
            name,
            phoneNumber,
        };
        const location: Geolocation = {
            clientId: UNKNOWN,
            name: 'Primary location',
            street1,
            street2,
            city,
            state,
            postalCode,
            lat: latitude,
            lng: longitude,
        };

        createNewClient(apiServices, client, location)
            .then((result) => {
                const {
                    client: createdClient,
                    location: createdLocation,
                } = result;

                onClientAdded(createdClient);
                onLocationAdded(createdLocation);

                getApplicationState().appHistory.replace(`/clients/${createdClient.id}`);
            })
            .catch(() => {
                onError('Failed to create the client; please review your selections and try again.');
            });
    }, [
        onError,
        apiServices,
        city,
        email,
        getApplicationState,
        latitude,
        longitude,
        name,
        onClientAdded,
        onLocationAdded,
        phoneNumber,
        postalCode,
        state,
        street1,
        street2,
    ]);

    return (
        <div className="addClient">
            <AppHeader getApplicationState={getApplicationState}>
                <></>
            </AppHeader>
            <h1>Add new client</h1>
            <div>
                <NamedInput
                    valid={isNameValid}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    label="Client Name"
                />

                <h2>Address</h2>
                <NamedInput
                    type="text"
                    name="address1"
                    value={street1}
                    onChange={(event) => setStreet1(event.target.value)}
                    label="Address Line 1"
                />
                <NamedInput
                    type="text"
                    name="address2"
                    value={street2}
                    onChange={(event) => setStreet2(event.target.value)}
                    label="Address Line 2"
                />
                <NamedInput
                    type="text"
                    name="city"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    label="City"
                />
                <NamedInput
                    type="text"
                    name="state"
                    value={state}
                    onChange={(event) => setState(event.target.value)}
                    label="State"
                />
                <NamedInput
                    type="text"
                    name="postalCode"
                    value={postalCode}
                    onChange={(event) => setPostalCode(event.target.value)}
                    label="Zip"
                />
                <Geo
                    canEdit
                    latitude={latitude}
                    longitude={longitude}
                    setLatitude={setLatitude}
                    setLongitude={setLongitude}
                    handleGeolocation={handleGeolocation}
                />

                <h2>Contact Info</h2>
                <NamedInput
                    valid={isPhoneValid}
                    type="text"
                    name="phone"
                    value={phoneNumber}
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    label="Phone"
                />
                <NamedInput
                    valid={isEmailValid}
                    type="text"
                    name="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    label="Email"
                />
            </div>

            <Actions>
                <button
                    type="button"
                    disabled={!formIsValid}
                    onClick={createClient}
                >Create new client
                </button>
                <button type="button" className="risky" onClick={onCancel}>Cancel</button>
            </Actions>
        </div>
    );
}
