import React from 'react';
import { SubjectCondition } from '../types';

export default function getConditionOptions() {
    const entries = Object.entries(SubjectCondition)
        .filter(([, value]) => !Number.isNaN(parseInt(value as string, 10)))
        .map(([name, value]) => <option key={value} value={value}>{name}</option>);

    return <>{entries}</>;
}
