import React, { useMemo } from 'react';

import Assignments from '../../views/Assignments';
import { AppComponentProps } from '../route-wrapper';
import { actionCreators } from '../../state/application';
import {
    Assignment,
    Client,
    EquipmentAsset,
    Geolocation,
    Inspection,
    StatusFilter,
} from '../../types';
import Loading from '../../views/Loading';

export default function AllAssignmentsConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
    } = props;
    const state = getState();

    const assignmentsPageFilters = useMemo(() => ({
        ...state.viewState.assignments,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setAssignmentsViewStateSearch(search),
        ),
        onDateStartChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateStart(date),
        ),
        onDateEndChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateEnd(date),
        ),
        onStatusFilterChanged: (filter: StatusFilter) => dispatch(
            actionCreators.setAssignmentsViewStateFilter(filter),
        ),
    }), [dispatch, state.viewState.assignments]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
    ) {
        return <Loading />;
    }

    return (
        <Assignments
            clients={state.clients as Array<Client>}
            assignments={state.assignments as Array<Assignment>}
            locations={state.locations as Array<Geolocation>}
            equipment={state.equipment as Array<EquipmentAsset>}
            inspections={state.inspections as Array<Inspection>}
            getApplicationState={getState}
            loading={loading}
            pageFilters={assignmentsPageFilters}
            match={match}
        />
    );
}
