import React, {
    ReactChildren,
    ReactElement,
    ReactNode,
    useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { groupBy, sortBy, toPairs } from 'ramda';
import classNames from 'classnames';
import { ViewportList } from 'react-viewport-list';

import NoResults from './NoResults';

export interface ListItem {
    id: string,
    className?: string | undefined,
    route?: string | undefined,
    title: string,
    prepend?: ReactNode | undefined,
    children?: ReactChildren | ReactElement | undefined,
}

interface Props {
    className: string,
    items: Array<ListItem>,
    itemClass: string,
    groupByFirstLetter?: boolean,
    prepend?: ReactNode | undefined,
    append?: ReactNode | undefined,
}

function getIndexLetter(char: string): string {
    const [upper, minChar, maxChar] = [char.toUpperCase(), 'A', 'Z'].map((x) => x.charCodeAt(0));

    if (upper >= minChar && upper <= maxChar) {
        return char.toUpperCase();
    }
    return '#';
}

interface GroupedByLetterProps {
    className: string,
    items: Array<ListItem>,
    itemClass: string,
    prepend: ReactNode | undefined,
    append: ReactNode | undefined,
}

function GroupedByLetter(props: GroupedByLetterProps) {
    const {
        items,
        itemClass,
        className,
        append,
        prepend,
    } = props;
    const listRef = useRef(null);

    const groupedByLetter: Array<[string, ListItem[]]> = toPairs(groupBy(
        (x) => getIndexLetter(x.title[0]),
        items,
    ));

    const jumpTo = (letter: string) => () => document.getElementById(`letter-${letter}`)
        ?.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
        });

    const letters = groupedByLetter.map(([key]) => key)
        .sort();

    const sortedByLetter: Array<[string, ListItem[]]> = sortBy(
        ([firstLetter]) => firstLetter,
        groupedByLetter,
    );

    const rolodex = letters.map((letter) => (
        <li>
            <button type="button" onClick={jumpTo(letter)}>{letter}</button>
        </li>
    ));

    const render = (([letter, groupedItems]: [string, ListItem[]]) => {
        const names = groupedItems.map((itemProps) => {
            const {
                prepend: itemPrepend,
                children,
                route,
                id,
                className: customClassName,
            } = itemProps;

            if (route) {
                const href = route.startsWith('#') ? route : `#${route}`;

                return (
                    <li
                        className={classNames(itemClass, customClassName)}
                        key={`${itemClass}-${id}`}
                    >
                        {itemPrepend}
                        <a className="row" href={href}>
                            {children}
                        </a>
                    </li>
                );
            }
            return (
                <li
                    className={classNames(itemClass, customClassName)}
                    key={`${itemClass}-${id}`}
                >
                    {itemPrepend}
                    <div className="row">
                        {children}
                    </div>
                </li>
            );
        });

        return (
            <React.Fragment key={`frag-${letter}`}>
                <li
                    className="letterHeading"
                    key={`letter-${letter}`}
                    id={`letter-${letter}`}
                >{letter}
                </li>

                <>{names}</>
            </React.Fragment>
        );
    });

    const emptyList = sortedByLetter.length === 0
        ? <NoResults />
        : null;

    return (
        <div className="table-container">
            <>{prepend}</>
            <ul className={classNames('groupedList', { 'no-results': emptyList }, className)} ref={listRef}>
                {emptyList ?? (
                    <ViewportList viewportRef={listRef} items={sortedByLetter}>{render}</ViewportList>
                )}
            </ul>
            <ul className="rolodex">{rolodex}</ul>
            <>{append}</>
        </div>
    );
}

interface UngroupedListProps {
    className: string,
    items: Array<ListItem>,
    itemClass: string,
    prepend: ReactNode | undefined,
    append: ReactNode | undefined,
}

function UngroupedList(props: UngroupedListProps) {
    const {
        className,
        itemClass,
        items,
        prepend,
        append,
    } = props;
    const listRef = useRef(null);

    const render = ({
        className: customClassName,
        children,
        route,
        id,
        prepend: itemPrepend,
    }: ListItem) => {
        if (route) {
            return (
                <li className={classNames(itemClass, customClassName)} key={`${itemClass}-${id}`}>
                    {itemPrepend}
                    <Link className="row" to={route}>
                        {children}
                    </Link>
                </li>
            );
        }
        return (
            <li className={classNames(itemClass, customClassName)} key={`${itemClass}-${id}`}>
                {itemPrepend}
                <div className="row">
                    {children}
                </div>
            </li>
        );
    };

    const emptyList = items.length === 0
        ? <NoResults />
        : null;

    return (
        <div className="table-container">
            <>{prepend}</>
            <ul
                ref={listRef}
                className={classNames('groupedList', { 'no-results': emptyList }, className)}
            >
                {emptyList ?? (
                    <ViewportList viewportRef={listRef} items={items}>{render}</ViewportList>
                )}
            </ul>
            <ul className="rolodex" />
            <>{append}</>
        </div>
    );
}

export default function List(props: Props) {
    const {
        className,
        itemClass,
        items,
        groupByFirstLetter,
        prepend,
        append,
    } = props;

    if (groupByFirstLetter) {
        return (
            <GroupedByLetter
                className={className}
                items={items}
                itemClass={itemClass}
                prepend={prepend}
                append={append}
            />
        );
    }

    return (
        <UngroupedList
            className={className}
            items={items}
            itemClass={itemClass}
            prepend={prepend}
            append={append}
        />
    );
}
List.defaultProps = {
    groupByFirstLetter: false,
    prepend: undefined,
    append: undefined,
};
