import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { ClientContact } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function upsertClientContact(dispatch: Dispatch<Action>, getState: () => State) {
    return (contact: ClientContact) => {
        const { clientContacts } = getState();

        if (clientContacts === Unset || clientContacts === Loading) {
            return;
        }

        dispatch(actionCreators.setClientContacts([
            ...(clientContacts as Array<ClientContact>).filter(
                (item) => item.id !== contact.id,
            ),
            contact,
        ]));
    };
}
