import {
    Assignment, AssignmentShare, Client, EquipmentAsset, Inspection,
} from '../types';
import Services from '../services';
import { createInspection } from '../types/constructors';
import { tryGetOpenAssignmentForClient } from './assignment-status';
import tryAddClientAssignment from './try-add-client-assignment';
import { UNKNOWN } from '../constants';

export default async function tryAddEquipmentInspection(
    asset: EquipmentAsset,
    clients: Array<Client>,
    assignments: Array<Assignment>,
    inspections: Array<Inspection>,
    apiService: Services,
): Promise<[Assignment, Inspection, AssignmentShare | null]> {
    const currentUser = apiService.getCurrentUser();

    if (!currentUser) {
        throw new Error('Current user could not be identified.');
    }

    const client = clients.find((item) => item.id === (asset.clientId ?? -1));
    if (!client) {
        throw new Error('Client could not be identified.');
    }

    // For the moment, we'll try to identify the most immediately pending
    // assignment for the given asset's client.  If available, we use that.
    const nextAssignment = tryGetOpenAssignmentForClient(asset.clientId, assignments);

    if (nextAssignment) {
        const toCreate = createInspection(
            currentUser.id ?? UNKNOWN,
            asset.id ?? UNKNOWN,
            nextAssignment.id ?? UNKNOWN,
        );
        return apiService.createInspection(toCreate)
            .then((inspection) => [nextAssignment, inspection, null]);
    }

    // Then again, if we don't have access to one, we'll need to make our own...
    const [assignment, assignmentShare] = await tryAddClientAssignment(client, apiService);

    const inspectionToCreate = createInspection(
        currentUser.id ?? UNKNOWN,
        asset.id ?? UNKNOWN,
        assignment.id ?? UNKNOWN,
    );

    return apiService.createInspection(inspectionToCreate)
        .then((inspection) => [assignment, inspection, assignmentShare]);
}
