import { assocPath } from 'ramda';
import {
    Action,
    ActionType,
    AddPhotosToRepoAction,
    AddPhotoToRepoAction,
    AddRecentClientAction,
    AppendBackgroundLoadLogsAction,
    DeletePhotoAction,
    SetAssignmentsAction,
    SetAssignmentSharesAction,
    SetAssignmentsViewStateDateEndAction,
    SetAssignmentsViewStateDateStartAction,
    SetAssignmentsViewStateFilterAction,
    SetAssignmentsViewStateSearchAction,
    SetAssignmentsViewStateSelectionAction,
    SetBackgroundLoadAction,
    SetCategoriesAction,
    SetClientContactsAction,
    SetClientsAction,
    SetClientsViewStateFilterAction,
    SetClientsViewStateSearchAction,
    SetCurrentUserAction,
    SetEmployeesAction,
    SetEmployeesViewStateSearchAction,
    SetEmployeesViewStateSelectionAction,
    SetEquipmentAction,
    SetFleetViewStateAssetSelectionAction,
    SetFleetViewStatePageTypeAction,
    SetFleetViewStateSearchAction,
    SetFleetViewStateSelectionAction,
    SetInitializedAction,
    SetInspectionsAction,
    SetLocationsAction,
    SetMakesAction,
    SetPhotoLoadingAction,
    State,
} from './index';

export default function reducer(previousState: State, action: Action): State {
    switch (action.actionType) {
        case ActionType.appendBackgroundLoadLogs: {
            const { value } = action as AppendBackgroundLoadLogsAction;

            return {
                ...previousState,
                backgroundLoadLogs: [...previousState.backgroundLoadLogs, value],
            };
        }
        case ActionType.setMakes: {
            const makes = (action as SetMakesAction).value;

            return {
                ...previousState,
                makes,
            };
        }
        case ActionType.setPhotoLoading: {
            const id = (action as SetPhotoLoadingAction).value;
            const { photosRepo: { cache, pending } } = previousState;

            return {
                ...previousState,
                photosRepo: {
                    cache,
                    pending: pending
                        .filter((item) => item !== id)
                        .concat([id]),
                },
            };
        }
        case ActionType.addPhotoToRepo: {
            const { id, src } = (action as AddPhotoToRepoAction).value;
            const { photosRepo: { cache, pending } } = previousState;

            const updatedCache = {
                ...cache,
                [id]: src,
            };
            const updatedPending = pending.filter((item) => item !== id);

            return {
                ...previousState,
                photosRepo: {
                    cache: updatedCache,
                    pending: updatedPending,
                },
            };
        }
        case ActionType.addPhotosToRepo: {
            const { value } = (action as AddPhotosToRepoAction);
            const { photosRepo: { cache, pending } } = previousState;

            const updatedCache = {
                ...cache,
                ...value,
            };
            const updatedPending = pending.filter(
                (item) => !Object.keys(value).some((key) => key === `${item}`),
            );

            return {
                ...previousState,
                photosRepo: {
                    cache: updatedCache,
                    pending: updatedPending,
                },
            };
        }
        case ActionType.deletePhotoFromCache: {
            const id = (action as DeletePhotoAction).value;
            const { photosRepo: { cache, pending } } = previousState;
            const { [id]: unused, ...remainingPhotoCache } = cache;

            return {
                ...previousState,
                photosRepo: {
                    pending,
                    cache: remainingPhotoCache,
                },
            };
        }
        case ActionType.setAssignments:
            return {
                ...previousState,
                assignments: (action as SetAssignmentsAction).value,
            };
        case ActionType.setAssignmentShares:
            return {
                ...previousState,
                assignmentShares: (action as SetAssignmentSharesAction).value,
            };
        case ActionType.setClientContacts:
            return {
                ...previousState,
                clientContacts: (action as SetClientContactsAction).value,
            };
        case ActionType.setCategories:
            return {
                ...previousState,
                categories: (action as SetCategoriesAction).value,
            };
        case ActionType.setClients:
            return {
                ...previousState,
                clients: (action as SetClientsAction).value,
            };
        case ActionType.setCurrentUser:
            return {
                ...previousState,
                currentUser: (action as SetCurrentUserAction).value,
            };
        case ActionType.setEmployees:
            return {
                ...previousState,
                employees: (action as SetEmployeesAction).value,
            };
        case ActionType.setEquipment:
            return {
                ...previousState,
                equipment: (action as SetEquipmentAction).value,
            };
        case ActionType.setInspections:
            return {
                ...previousState,
                inspections: (action as SetInspectionsAction).value,
            };
        case ActionType.setLocations:
            return {
                ...previousState,
                locations: (action as SetLocationsAction).value,
            };
        case ActionType.addRecentClient: {
            const { value } = action as AddRecentClientAction;
            const { recentClients } = previousState;

            recentClients.delete(value);
            recentClients.add(value);

            return { ...previousState, recentClients };
        }
        case ActionType.clearRecentClients:
            return {
                ...previousState,
                recentClients: new Set(),
            };
        case ActionType.setInitialized:
            return {
                ...previousState,
                initialized: (action as SetInitializedAction).value,
            };
        case ActionType.setClientsViewStateSearch:
            return assocPath(
                ['viewState', 'clients', 'search'],
                (action as SetClientsViewStateSearchAction).value,
                previousState,
            );
        case ActionType.setClientsViewStateFilter:
            return assocPath(
                ['viewState', 'clients', 'filter'],
                (action as SetClientsViewStateFilterAction).value,
                previousState,
            );
        case ActionType.setBackgroundLoad: {
            const { loadType, loadState } = (action as SetBackgroundLoadAction).value;

            return {
                ...previousState,
                backgroundLoad: {
                    ...previousState.backgroundLoad,
                    [loadType]: loadState,
                },
            };
        }
        case ActionType.setClientsViewStateSelection:
            return assocPath(
                ['viewState', 'clients', 'selection'],
                (action as SetClientsViewStateFilterAction).value,
                previousState,
            );
        case ActionType.setEmployeesViewStateSearch:
            return assocPath(
                ['viewState', 'employees', 'search'],
                (action as SetEmployeesViewStateSearchAction).value,
                previousState,
            );
        case ActionType.setEmployeesViewStateSelection:
            return assocPath(
                ['viewState', 'employees', 'selection'],
                (action as SetEmployeesViewStateSelectionAction).value,
                previousState,
            );
        case ActionType.setFleetViewStateSearch:
            return assocPath(
                ['viewState', 'fleet', 'search'],
                (action as SetFleetViewStateSearchAction).value,
                previousState,
            );
        case ActionType.setFleetViewStateSelection:
            return assocPath(
                ['viewState', 'fleet', 'selection'],
                (action as SetFleetViewStateSelectionAction).value,
                previousState,
            );
        case ActionType.setFleetViewStateAssetSelection:
            return assocPath(
                ['viewState', 'fleet', 'selectedAsset'],
                (action as SetFleetViewStateAssetSelectionAction).value,
                previousState,
            );
        case ActionType.setFleetPageType:
            return assocPath(
                ['viewState', 'fleet', 'pageType'],
                (action as SetFleetViewStatePageTypeAction).value,
                previousState,
            );
        case ActionType.setAssignmentsViewStateSearch:
            return assocPath(
                ['viewState', 'assignments', 'search'],
                (action as SetAssignmentsViewStateSearchAction).value,
                previousState,
            );
        case ActionType.setAssignmentsViewStateFilter:
            return assocPath(
                ['viewState', 'assignments', 'statusFilter'],
                (action as SetAssignmentsViewStateFilterAction).value,
                previousState,
            );
        case ActionType.setAssignmentsViewStateDateStart:
            return assocPath(
                ['viewState', 'assignments', 'dateStart'],
                (action as SetAssignmentsViewStateDateStartAction).value,
                previousState,
            );
        case ActionType.setAssignmentsViewStateDateEnd:
            return assocPath(
                ['viewState', 'assignments', 'dateEnd'],
                (action as SetAssignmentsViewStateDateEndAction).value,
                previousState,
            );
        case ActionType.setAssignmentsViewStateSelection:
            return assocPath(
                ['viewState', 'assignments', 'selection'],
                (action as SetAssignmentsViewStateSelectionAction).value,
                previousState,
            );
        default:
            return previousState;
    }
}
