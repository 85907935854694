import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { UserSetting } from '../../../types';
import { Loading } from '../../../constants';

export default function upsertSettings(dispatch: Dispatch<Action>, getState: () => State) {
    return (toUpdate: { [key in keyof typeof UserSetting]: any }) => {
        const { apiServices, currentUser } = getState();

        if (typeof currentUser === 'object' && currentUser.id) {
            const merged = {
                ...currentUser.userSettings,
                ...toUpdate,
            };

            dispatch(actionCreators.setCurrentUser(Loading));

            apiServices
                .setUserSettings(currentUser.id, JSON.stringify(merged))
                .then(() => {
                    dispatch(actionCreators.setCurrentUser({
                        ...currentUser,
                        userSettings: merged,
                    }));
                })
                .catch(() => {
                    dispatch(actionCreators.setCurrentUser(currentUser));
                });
        }
    };
}
