import { TrackTireInspection, TrackTireType } from '../types';

export default function identifyInspectionSubjectType(subject: TrackTireInspection): TrackTireType {
    const coercedSubject = subject as any;

    if (!Number.isNaN(parseInt(coercedSubject.tireCondition, 10))) {
        return TrackTireType.Tire;
    }
    if (!Number.isNaN(parseInt(coercedSubject.trackCondition, 10))) {
        return TrackTireType.Track;
    }
    throw new Error('Unknown inspection subject type; expected TireInspection or TrackInspection');
}
