import React from 'react';

import Dashboard from '../views/Dashboard';
import { AppComponentProps } from './route-wrapper';
import Loading from '../views/Loading';
import {
    Assignment, Client, EquipmentAsset, Geolocation, Inspection, UserLogin,
} from '../types';

export default function DashboardConnector(props: AppComponentProps) {
    const { getState, loading } = props;
    const state = getState();

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.currentUser
    ) {
        return <Loading />;
    }

    return (
        <Dashboard
            recentClients={state.recentClients}
            clients={state.clients as Array<Client>}
            assignments={state.assignments as Array<Assignment>}
            locations={state.locations as Array<Geolocation>}
            equipment={state.equipment as Array<EquipmentAsset>}
            inspections={state.inspections as Array<Inspection>}
            currentUser={state.currentUser as UserLogin}
            loading={loading}
        />
    );
}
