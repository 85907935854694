/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

interface StaticImageWithOverlayProps {
    className?: string | undefined,
    src: string | null,
    alt: string | null,
}

export default function StaticImageWithOverlay(props: StaticImageWithOverlayProps) {
    const { className, src, alt } = props;

    const [overlayStatus, setOverlayStatus] = useState(false);

    function toggleOverlay() {
        setOverlayStatus(!overlayStatus);
    }

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOverlayStatus(false);
            }
        };

        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('keydown', handler);
        };
    });

    return (
        <>
            <button className="image-button" type="button" onClick={toggleOverlay}>
                <img className={classNames(className)} src={src ?? ''} alt={alt ?? ''} />
            </button>
            <div className={`${overlayStatus ? 'active' : ''} image-overlay`}>
                <button className="close" type="button" onClick={toggleOverlay}>Close</button>

                <div className="image-overlay--int">
                    <img src={src ?? ''} alt={alt ?? ''} />
                </div>
            </div>
        </>
    );
}

StaticImageWithOverlay.defaultProps = { className: undefined };
