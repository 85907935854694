import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter } from 'react-router-dom';
import { Subject } from 'rxjs';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Services from './services';
import {
    API_BASE_URI,
    VERSION,
} from './constants';
import WithSnackbar from './controls/Snackbar';
import ShareServices from './services/share/index';
import InspectionReport from './views/InspectionReport';
import { AppMessage, AppMessages } from './types';

// eslint-disable-next-line no-console
console.log(`Application version ${VERSION}, using API base uri: ${API_BASE_URI}`);

const rootNode = document.getElementById('root');
const appEvents = new Subject<AppMessage>();

const slug = new URL(window.location.href).searchParams.get('slug');

if (slug) {
    const apiServices = new ShareServices(slug);

    ReactDOM.render((
        <WithSnackbar>
            <InspectionReport services={apiServices} />
        </WithSnackbar>
    ), rootNode);
} else {
    const apiServices = Services.tryGetAuthenticatedService();

    ReactDOM.render(
        <React.StrictMode>
            <HashRouter>
                <WithSnackbar>
                    <App appEvents={appEvents} apiServices={apiServices} />
                </WithSnackbar>
            </HashRouter>
        </React.StrictMode>,
        rootNode,
    );
}

appEvents.subscribe((data) => {
    // eslint-disable-next-line no-console
    console.log('App message received', data);

    if (data.message === AppMessages.ServiceWorkerShouldUnregister) {
        serviceWorkerRegistration.unregister();
        window.location.reload();
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    // @ts-ignore
    version: '1.0.0',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (registration) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onUpdate: (registration) => {},
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
