import React, { useContext, useEffect, useState } from 'react';
import { uniq } from 'ramda';
import ShareServices from '../../services/share';
import {
    Assignment,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
} from '../../types';
import Loading from '../Loading';
import { AlertType, Snackbar, SnackbarContext } from '../../controls/Snackbar';
import { AppWrapper } from '../../controls/index';
import Page from './Page';
import { UNKNOWN, Unset } from '../../constants';

interface Props {
    services: ShareServices,
}

export default function InspectionReport(props: Props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { services } = props;

    const [loading, setLoading] = useState<null | boolean>(null);
    const [categories, setCategories] = useState<Array<Category> | null>(null);
    const [client, setClient] = useState<Client | null>(null);
    const [inspector, setInspector] = useState<Employee | null>(null);
    const [assignment, setAssignment] = useState<Assignment | null>(null);
    const [equipment, setEquipment] = useState<Array<EquipmentAsset> | null>(null);
    const [inspections, setInspections] = useState<Array<Inspection> | null>(null);
    const [locations, setLocations] = useState<Array<Geolocation> | null>(null);
    const [photos, setPhotos] = useState<Record<number, Blob>>({});

    const {
        append: appendSnackbarItem,
        dismiss: dismissSnackbarItem,
        items: snackbarItems,
    } = useContext(SnackbarContext);

    useEffect(() => {
        if (loading === null) {
            setLoading(true);

            const equipmentFetch = services.getEquipment();
            const locationsLoad = equipmentFetch.then((items) => {
                const uniqueLocations = uniq(
                    items
                        .map((asset) => asset.currentLocationId)
                        .filter((id) => id),
                );

                return Promise.all(
                    uniqueLocations.map((id) => services.getLocation(id ?? UNKNOWN)),
                );
            });
            const inspectionsFetch = services.getInspections();

            const photosFetch = Promise.all([inspectionsFetch, equipmentFetch])
                .then(([inspectionItems, equipmentItems]: [Array<Inspection>, Array<EquipmentAsset>]) => {
                    const equipmentPhotos = equipmentItems.map((asset) => asset.primaryImageId);
                    const inspectionPhotos = inspectionItems.reduce((memo: Array<string>, inspection) => [
                        ...memo,
                        ...inspection.subjects.reduce(
                            (memo2, subject) => [
                                ...memo2,
                                ...subject.images,
                            ].filter((x) => x),
                            [] as Array<string>,
                        ),
                    ], []);

                    const photoLoads = [...equipmentPhotos, ...inspectionPhotos]
                        .filter((x) => x)
                        .map(
                            (id) => services
                                .getPhoto(id ?? UNKNOWN)
                                .then((photo) => ({ id, photo }))
                                .catch(() => ({ id, photo: Unset })),
                        );

                    return Promise
                        .all(photoLoads)
                        .then((tuples) => tuples.reduce(
                            (memo, { id, photo }) => ({
                                ...memo,
                                [id ?? -1]: photo,
                            }),
                            {},
                        ));
                });

            Promise.all([
                ShareServices.getCategories().then(setCategories),
                services.getClient().then(setClient),
                services.getInspector()
                    .then(setInspector)
                    .catch(() => {
                        // TODO: Decide how to proceed with this; currently, it's possible for
                        // a missing inspector assignment to get through the system.
                    }),
                services.getAssignment().then(setAssignment),
                equipmentFetch.then(setEquipment),
                locationsLoad.then(setLocations),
                inspectionsFetch.then(setInspections),
                photosFetch.then(setPhotos),
            ])
                .then(() => setLoading(false))
                .catch(() => {
                    appendSnackbarItem(
                        'An error occurred while loading the report',
                        AlertType.Error,
                        true,
                    );
                });
        }
    }, [appendSnackbarItem, loading, services]);

    if (loading ?? true) {
        return (
            <AppWrapper>
                <Snackbar items={snackbarItems} dismiss={dismissSnackbarItem} />
                <Loading />
            </AppWrapper>
        );
    }

    if (equipment === null
        || categories === null
        || inspections === null
        || client === null
        || locations === null
        || assignment === null
    ) {
        return <div>An unexpected error has occurred.</div>;
    }

    return (
        <AppWrapper className="inspectionReportApp">
            <Snackbar items={snackbarItems} dismiss={dismissSnackbarItem} />
            <Page
                equipment={equipment}
                categories={categories}
                inspections={inspections}
                client={client}
                locations={locations}
                assignment={assignment}
                inspector={inspector}
                photos={photos}
            />
        </AppWrapper>
    );
}
