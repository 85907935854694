import { Dispatch } from 'react';

import { Action, State } from '../index';
import { BackgroundLoadState, BackgroundLoadType } from '../../../types';
import { MS_PER_MINUTE } from '../../../constants';
import { AlertType } from '../../../controls/Snackbar';
import backgroundLoad from './background-load';

export default function attemptScheduledBackgroundLoad(dispatch: Dispatch<Action>, getState: () => State, onError: (text: string, alertType: AlertType) => void) {
    return () => {
        const { backgroundLoad: backgroundLoadState, currentUser } = getState();

        // Bypass if we don't have access to user or its constituent settings
        if (typeof currentUser === 'symbol') {
            return;
        }

        if (
            // Bypass if already running or previously failed
            backgroundLoadState[BackgroundLoadType.Recent] === BackgroundLoadState.Running
            || backgroundLoadState[BackgroundLoadType.Recent] === BackgroundLoadState.Errored
            || backgroundLoadState[BackgroundLoadType.Full] === BackgroundLoadState.Running
            || backgroundLoadState[BackgroundLoadType.Full] === BackgroundLoadState.Errored

            // Bypass if we haven't yet performed the initial load
            || backgroundLoadState[BackgroundLoadType.Full] === BackgroundLoadState.NeverRun
        ) {
            return;
        }

        const mostRecentLoad = Math.max(
            backgroundLoadState[BackgroundLoadType.Full],
            backgroundLoadState[BackgroundLoadType.Recent],
        );

        const fullLoadEnabled = Boolean(currentUser.userSettings.fullUpdatesPollingPeriod);
        const backgroundLoadEnabled = Boolean(currentUser.userSettings.recentUpdatesPollingPeriod);

        const fullLoadOverdue = new Date().getTime() - backgroundLoadState[BackgroundLoadType.Full] > currentUser.userSettings.fullUpdatesPollingPeriod * MS_PER_MINUTE;
        const backgroundLoadOverdue = new Date().getTime() - mostRecentLoad > currentUser.userSettings.recentUpdatesPollingPeriod * MS_PER_MINUTE;

        if (fullLoadEnabled && fullLoadOverdue) {
            // Preferentially perform full load if ready
            backgroundLoad(dispatch, getState, onError)(BackgroundLoadType.Full);
        } else if (backgroundLoadEnabled && backgroundLoadOverdue) {
            // Alternatively, we might also be scheduled for a partial load
            backgroundLoad(dispatch, getState, onError)(BackgroundLoadType.Recent);
        }
    };
}
