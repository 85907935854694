import React from 'react';
import { Category, Severity } from '../types';
import { SeverityAndCount } from '../utils/query-severity-and-count';

interface Props {
    category: Category | null,
    severityAndCount: SeverityAndCount<any> | null,
}

export default function CategoryItem(props: Props) {
    const { category, severityAndCount } = props;

    return (
        <li className={category?.slug}>
            <span className="name">{category?.pluralName}</span>
            <div className="image" />
            <span
                className={`severity count ${Severity[severityAndCount?.severity ?? Severity.Ignore]}`}
            >{severityAndCount?.count ?? 0}
            </span>
        </li>
    );
}
