import React, { ReactNode } from 'react';

interface Props {
    condition: boolean,
    children: ReactNode,
}

export default function RenderIf(props: Props) {
    const { condition, children } = props;

    if (condition) {
        return <>{children}</>;
    }
    return null;
}
