/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

interface EditableImageWithOverlayProps {
    onDelete: () => Promise<void>,
    className?: string | undefined,
    src: string | null,
    alt: string | null,
}

export default function EditableImageWithOverlay(props: EditableImageWithOverlayProps) {
    const {
        className,
        src,
        onDelete,
        alt,
    } = props;

    const [overlayStatus, setOverlayStatus] = useState(false);

    function toggleOverlay() {
        setOverlayStatus(!overlayStatus);
    }

    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setOverlayStatus(false);
            }
        };

        document.addEventListener('keydown', handler);

        return () => {
            document.removeEventListener('keydown', handler);
        };
    });

    const handleDeletion = useCallback(() => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure?')) {
            onDelete()
                .then(() => setOverlayStatus(false))
                .catch(() => {
                    // Really can't do much at this point...
                });
        }
    }, [onDelete]);

    return (
        <>
            <button className="image" type="button" onClick={toggleOverlay}>
                <img className={classNames(className)} src={src ?? ''} alt={alt ?? ''} />
            </button>
            <div className={`${overlayStatus ? 'active' : ''} image-overlay`}>
                <button className="close" type="button" onClick={toggleOverlay}>Close</button>

                <div className="image-overlay--int">
                    <img src={src ?? ''} alt={alt ?? ''} />
                    <button className="red-button delete-button" type="button" onClick={handleDeletion}>Delete Photo</button>
                </div>
            </div>
        </>
    );
}

EditableImageWithOverlay.defaultProps = { className: undefined };
