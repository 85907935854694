import React, { useCallback, useContext, useEffect } from 'react';
import {
    Route, RouteComponentProps, Switch, useHistory,
} from 'react-router-dom';
import { useEnhancedReducer } from 'enhanced-reducer';
import { Subject } from 'rxjs';
import { ExtractRouteParams } from 'react-router';

import './scss/index.scss';

import Services from './services';
import Login from './views/Login';

import { AppWrapper } from './controls';
import { AlertType, Snackbar, SnackbarContext } from './controls/Snackbar';
import NotYetImplemented from './controls/NotYetImplemented';

import { actionCreators, getInitialState, State } from './state/application';
import initialLoad from './state/application/thunks/initial-load';
import { AppMessage } from './types';
import reducer from './state/application/reducer';
import handleLocationEvent from './state/application/thunks/handle-location-event';

import NewAssignmentConnector from './routes/Assignments/new-assignment-connector';
import AllAssignmentsConnector from './routes/Assignments/all-assignments-connector';
import InspectionDetailConnector from './routes/inspection-detail-connector';
import EditAssignmentConnector from './routes/Assignments/edit-assignment-connector';
import AssignmentDetailConnector from './routes/Assignments/assignment-connector';
import EditClientConnector from './routes/Clients/edit-client-connector';
import EditEquipmentConnector from './routes/Equipment/edit-equipment-connector';
import EmployeeDetailConnector from './routes/Employees/employee-detail-connector';
import AllEmployeesConnector from './routes/Employees/all-employees-connector';
import EquipmentDetailConnector from './routes/Equipment/equipment-detail-connector';
import ClientFleetConnector from './routes/Clients/client-fleet-connector';
import LocationFleetConnector from './routes/Clients/location-fleet-connector';
import EditClientLocationConnector from './routes/Clients/edit-client-location-connector';
import NewClientConnector from './routes/Clients/new-client-connector';
import ClientDetailConnector from './routes/Clients/client-detail-connector';
import ClientsConnector from './routes/Clients/clients-connector';
import SettingsConnector from './routes/settings-connector';
import DashboardConnector from './routes/dashboard-connector';
import { AppComponent, RouteHandler } from './routes/route-wrapper';
import { ADMIN, MS_BETWEEN_BACKGROUND_LOAD_ATTEMPTS } from './constants';
import attemptScheduledBackgroundLoad from './state/application/thunks/attempt-scheduled-background-load';

interface Props {
    apiServices: Services,
    appEvents: Subject<AppMessage>,
}

function App({ apiServices, appEvents }: Props) {
    const [
        state,
        dispatch,
        getState,
    ] = useEnhancedReducer(reducer, getInitialState(apiServices, useHistory(), appEvents));

    const {
        append: appendSnackbarItem,
        dismiss: dismissSnackbarItem,
        items: snackbarItems,
    } = useContext(SnackbarContext);

    // Bind to location changes to intercept selection state on top-level entities:
    useEffect(
        () => (state as State).appHistory.listen(handleLocationEvent(dispatch)),
        [dispatch, state],
    );

    // Also, run the selection handler once just in case the initial load is a matching page:
    useEffect(() => {
        handleLocationEvent(dispatch)(getState().appHistory.location);
    }, [dispatch, getState]);

    const handleError = useCallback((error: string) => {
        appendSnackbarItem(error, AlertType.Error);
    }, [appendSnackbarItem]);
    const handleWarning = (error: string) => {
        appendSnackbarItem(error, AlertType.Warning);
    };
    const handleInformation = (error: string) => {
        appendSnackbarItem(error, AlertType.Information);
    };

    // Periodically perform background loads to keep data synched
    useEffect(() => {
        const interval = setInterval(() => {
            attemptScheduledBackgroundLoad(dispatch, getState, handleError)();
        }, MS_BETWEEN_BACKGROUND_LOAD_ATTEMPTS);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, getState, handleError]);

    const currentUser = apiServices.getCurrentUser();

    if (currentUser === null) {
        return (
            <AppWrapper>
                <Snackbar items={snackbarItems} dismiss={dismissSnackbarItem} />
                <Login
                    services={state.apiServices}
                    onLogin={(user) => dispatch(actionCreators.setCurrentUser(user))}
                    onError={handleError}
                />
            </AppWrapper>
        );
    }

    initialLoad(dispatch, getState)(currentUser);

    const getRoute = (route: string, component: AppComponent) => {
        const handler = (routeProps: RouteComponentProps<ExtractRouteParams<string, string>>) => (
            <RouteHandler
                match={routeProps.match}
                dispatch={dispatch}
                getState={getState}
                component={component}
                handleError={handleError}
                handleWarning={handleWarning}
                handleInformation={handleInformation}
            />
        );

        return (
            <Route path={route} render={handler} />
        );
    };

    const isAdmin = currentUser.role === ADMIN;
    const routes = [
        { show: isAdmin, route: '/assignments/new', component: NewAssignmentConnector },
        { show: isAdmin, route: '/assignments', component: AllAssignmentsConnector },
        { show: true, route: '/clients/:clientId/assignments/:assignmentId/inspections/:inspectionId', component: InspectionDetailConnector },
        { show: true, route: '/clients/:clientId/assignments/new', component: NewAssignmentConnector },
        { show: true, route: '/clients/:clientId/assignments/:assignmentId/edit', component: EditAssignmentConnector },
        { show: true, route: '/clients/:clientId/assignments/:assignmentId', component: AssignmentDetailConnector },
        { show: true, route: '/clients/:clientId/edit', component: EditClientConnector },
        { show: true, route: '/clients/:clientId/locations/:locationId/equipment/:equipmentId/edit', component: EditEquipmentConnector },
        { show: isAdmin, route: '/employees/:employeeId', component: EmployeeDetailConnector },
        { show: isAdmin, route: '/employees', component: AllEmployeesConnector },
        { show: true, route: '/clients/:clientId/equipment/:equipmentId/edit', component: EditEquipmentConnector },
        { show: true, route: '/clients/:clientId/equipment/:equipmentId', component: EquipmentDetailConnector },
        { show: true, route: '/clients/:clientId/fleet/:categoryId?', component: ClientFleetConnector },
        { show: true, route: '/clients/:clientId/locations/:locationId/equipment', component: LocationFleetConnector },
        { show: true, route: '/clients/:clientId/locations/:locationId/edit', component: EditClientLocationConnector },
        { show: true, route: '/clients/new', component: NewClientConnector },
        { show: true, route: '/clients/:clientId', component: ClientDetailConnector },
        { show: true, route: '/clients', component: ClientsConnector },
        { show: true, route: '/settings', component: SettingsConnector },
        { show: true, route: '/', component: DashboardConnector },
        { show: true, route: '/clients/:clientId/locations', component: NotYetImplemented },
    ]
        .filter(({ show }) => show)
        .map(({ route, component }) => getRoute(route, component));

    return (
        <AppWrapper>
            <Snackbar items={snackbarItems} dismiss={dismissSnackbarItem} />
            <>
                <Switch>
                    {routes}
                </Switch>
            </>
        </AppWrapper>
    );
}

export default App;
