import React, {
    ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
    ascend, descend, groupBy, partition, sortWith, toPairs,
} from 'ramda';
import { parseOneAddress, ParsedMailbox } from 'email-addresses';
import classNames from 'classnames';

import { ExtractRouteParams, match as routerMatch } from 'react-router';
import {
    Assignment, AssignmentShare,
    Category,
    Client, ClientContact,
    Employee,
    EquipmentAsset,
    Inspection,
    InspectionStatus,
    Severity, ShareTarget,
} from '../../types';
import {
    Actions, AppHeader, DetailPanel, NamedInput, NotesInput,
} from '../../controls';
import * as constructors from '../../types/constructors';
import Loading from '../Loading';
import formatDueDate from '../../utils/format-due-date';
import tryFindParentCategory from '../../utils/try-find-parent-category';
import CategoryItem from '../../controls/category-item';
import getInspectionStatusOptions from '../../utils/get-inspection-status-options';
import Services from '../../services';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';
import { debouncedUpdate } from '../../utils/update-assignment';
import { DATE_FORMAT, UNKNOWN } from '../../constants';
import List, { ListItem } from '../../controls/List';
import RenderIf from '../../controls/RenderIf';
import { State } from '../../state/application';
import tryIdentifyParentRoute from '../../utils/try-identify-parent-route';
import { makeUuid } from '../../types/constructors';
import generateShareEmail from '../../utils/generate-share-email';
import AddContactDialog from './AddContactDialog';
import EditContactDialog from './EditContactDialog';
import isConsideredOpen from '../../utils/is-considered-open';

const EDIT = 'Edit';

type ErrorHandler = (message: string) => void;

interface Props {
    getApplicationState: () => State,
    apiServices: Services,
    loading: {
        employees: boolean,
        assignments: boolean,
        clients: boolean,
        inspections: boolean,
        equipment: boolean,
        categories: boolean,
        assignmentShares: boolean,
        clientContacts: boolean,
    },
    clientContacts: Array<ClientContact>,
    assignmentShares: Array<AssignmentShare>,
    categories: Array<Category>,
    inspections: Array<Inspection>,
    assignments: Array<Assignment>,
    clients: Array<Client>,
    equipment: Array<EquipmentAsset>,
    employees: Array<Employee>,
    onContactAdded: (clientContact: ClientContact) => void,
    onContactUpdated: (clientContact: ClientContact) => void,
    onContactRemoved: (contactId: string) => void,
    onShareAdded: (assignmentShare: AssignmentShare) => void,
    onShareRemoved: (assignmentShareId: string) => void,
    onShareUpdated: (assignmentShare: AssignmentShare) => void,
    onInspectionUpdating: (inspection: Inspection) => void,
    onAssignmentUpdated: (assignment: Assignment) => void,
    onAssignmentDeleted: (assignmentId: string) => void,
    onError: ErrorHandler,
    match: routerMatch<ExtractRouteParams<string, string>>,
}

export default function AssignmentDetail(props: Props) {
    const {
        match,
        getApplicationState,
        employees,
        assignments,
        clients,
        assignmentShares,
        clientContacts,
        loading,
        inspections,
        equipment,
        categories,
        apiServices,
        onAssignmentUpdated,
        onInspectionUpdating,
        onAssignmentDeleted,
        onContactAdded,
        onContactUpdated,
        onContactRemoved,
        onShareAdded,
        onShareRemoved,
        onShareUpdated,
        onError,
    } = props;

    const [dirty, setDirty] = useState(false);
    const [saving, setSaving] = useState(false);
    const [initialized, setInitialized] = useState<string | null>(null);
    const [notes, setNotes] = useState<string | null>(null);
    const [status, setStatus] = useState<InspectionStatus>(InspectionStatus.Open);
    const [dueDate, setDueDate] = useState<string | null>(null);
    const [employeeId, setEmployeeId] = useState<string | null>(null);
    const [adminId, setAdminId] = useState<string | null>(null);
    const [showingContactDialog, setShowingContactDialog] = useState(false);
    const [editingShareTarget, setEditingShareTarget] = useState<ShareTarget | null>(null);

    useEffect(() => {
        if (initialized !== match.params.assignmentId
            && !loading.assignments
            && !loading.employees
            && !loading.inspections
            && !loading.clients
            && !loading.equipment
            && !loading.categories
            && !loading.clientContacts
            && !loading.assignmentShares
        ) {
            const assignment = assignments.find((item) => `${item.id}` === match.params.assignmentId);

            if (!assignment || !assignment.id) {
                onError('An unexpected error has occurred.');
                getApplicationState().appHistory.goBack();
                return;
            }

            setInitialized(match?.params?.assignmentId ?? '');
            setNotes(assignment.notes || '');
            setStatus(assignment.status);
            setDueDate(assignment.dueDate ? dayjs(assignment.dueDate).format(DATE_FORMAT) : '');
            setEmployeeId(assignment.employeeId);
            setAdminId(assignment.reviewerId);
            setShowingContactDialog(false);
        }
    }, [assignments, dueDate, getApplicationState, initialized, loading, match.params.assignmentId, onError]);

    useEffect(() => {
        if (dirty && !saving) {
            setSaving(true);
            setDirty(false);

            const assignment = assignments.find((item) => `${item.id}` === match.params.assignmentId);

            if (!assignment) {
                throw new Error();
            }

            debouncedUpdate(
                {
                    ...assignment,
                    employeeId,
                    status,
                    notes,
                    dueDate: dueDate ? dayjs(dueDate).toJSON() : '',
                },
                apiServices,
                (updated) => {
                    onAssignmentUpdated(updated);

                    setSaving(false);
                },
                (err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    onError('Failed to save changes.');

                    setSaving(false);
                },
            );
        }
    }, [
        onError,
        apiServices,
        assignments,
        dirty,
        dueDate,
        employeeId,
        match.params.assignmentId,
        notes,
        onAssignmentUpdated,
        saving,
        status,
    ]);

    const hideEditContactDialog = useCallback(() => {
        setEditingShareTarget(null);
    }, []);
    const showContactDialog = useCallback(() => {
        setShowingContactDialog(true);
    }, []);
    const hideContactDialog = useCallback(() => {
        setShowingContactDialog(false);
    }, []);
    const handleStatusUpdated = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        const value: InspectionStatus = parseInt(event.target.value, 10);

        setStatus(value);
        setDirty(true);
    }, []);
    const handleNotesUpdated = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value);
        setDirty(true);
    }, []);
    const handleAssignmentDeletion = useCallback(() => {
        const assignmentId = match?.params?.assignmentId || UNKNOWN;

        // eslint-disable-next-line no-alert
        if (assignmentId && window.confirm('Are you sure?')) {
            apiServices.deleteAssignment(assignmentId)
                .then(() => {
                    const upRoute = tryIdentifyParentRoute(getApplicationState())?.route;

                    if (upRoute) {
                        getApplicationState().appHistory.replace(upRoute);
                    } else {
                        getApplicationState().appHistory.replace('/assignments');
                    }

                    onAssignmentDeleted(assignmentId);
                });
        }
    }, [
        apiServices,
        getApplicationState,
        match.params.assignmentId,
        onAssignmentDeleted,
    ]);
    const handleIncludeInReportToggled = useCallback((inspection: Inspection) => {
        onInspectionUpdating({
            ...inspection,
            includeInReport: !inspection.includeInReport,
        });
    }, [onInspectionUpdating]);

    if (loading.clients
        || loading.assignments
        || loading.inspections
        || loading.equipment
        || loading.categories
        || loading.employees
        || loading.assignmentShares
        || loading.clientContacts
    ) {
        return <Loading />;
    }

    const client = clients.find((item) => `${item.id}` === match.params.clientId);
    const assignment = assignments.find((item) => `${item.id}` === match.params.assignmentId);

    if (!client || !assignment) {
        throw new Error();
    }

    const targetedInspections = inspections
        .filter((item) => item.assignmentId === assignment.id);
    const targetedEquipment = targetedInspections
        .map((insp) => equipment.find((asset) => asset.id === insp.equipmentId));

    const assignmentDate = formatDueDate(dueDate);
    const assignmentName = `Assignment ${assignmentDate}`;
    const inspectionCells: Array<ListItem> = targetedInspections
        .sort((a, b) => new Date(a.createdOn ?? 0).getTime() - new Date(b.createdOn ?? 0).getTime())
        .map((inspection) => {
            const inspector = employees.find((emp) => emp.id === inspection.employeeId);
            const asset = equipment.find((item) => item.id === inspection.equipmentId);
            const route = `/clients/${client.id}/assignments/${assignment.id}/inspections/${inspection.id}`;

            const auditor = inspector?.name ?? 'Unknown';
            const title = `${formatDueDate(inspection.createdOn)} by ${auditor}`;

            const viewLink = isConsideredOpen(inspection.status)
                ? null
                : (
                    <span className="link">
                        <Link to={route}>View</Link>
                    </span>
                );
            const editLink = isConsideredOpen(inspection.status)
                ? (
                    <span className="link">
                        <Link to={route}>Edit</Link>
                    </span>
                )
                : null;

            // TODO: add include in report functionality.

            return {
                id: inspection?.id ?? UNKNOWN,
                title,
                children: (
                    <>
                        <Link className="name" to={route}>{title}</Link>
                        <span className="equipmentName">{asset?.name}</span>
                        <span className="includeInReport">
                            <NamedInput
                                name="isComplete"
                                type="checkbox"
                                label=""
                                value={inspection.includeInReport}
                                onChange={() => handleIncludeInReportToggled(inspection)}
                            />
                        </span>
                        {viewLink}
                        {editLink}
                    </>
                ),
            };
        });
    const subjects: Array<[string, Array<EquipmentAsset>]> = toPairs(groupBy(
        (asset) => `${asset?.categoryId}`,
        targetedEquipment,
    ));
    const subjectCells = subjects
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(([categoryId, assets]) => {
            const category = tryFindParentCategory(categoryId, categories);
            const severityAndCount = category
                ? querySeverityAndCount.byCategory(
                    category,
                    client,
                    equipment,
                    inspections,
                    categories,
                    assignments,
                )
                : {
                    severity: Severity.Ignore,
                    count: 0,
                    items: [],
                };

            return (
                <Link to={`/clients/${client?.id}/fleet/${category?.id}`}>
                    <CategoryItem category={category} severityAndCount={severityAndCount} />
                </Link>
            );
        });
    const notesContent = notes !== null
        ? (
            <NotesInput
                className="large-label"
                name="notes"
                label="Notes"
                value={notes}
                onChange={handleNotesUpdated}
            />
        )
        : <button onClick={() => setNotes('')} className="plus" type="button">Add Notes</button>;
    const assignmentOptions = getInspectionStatusOptions();

    const dueDateField = <dd>{assignmentDate}</dd>;
    const editModeToggle = (
        <button
            className="edit"
            onClick={() => getApplicationState().appHistory.push(`/clients/${client.id}/assignments/${assignment.id}/edit`)}
            title={EDIT}
            type="button"
        >{EDIT}
        </button>
    );

    const inspectorName = employees
        .find((item) => item.id === employeeId)?.name;
    const inspectorField = (
        <dd>
            <Link to={`/employees/${employeeId}`}>{inspectorName}</Link>
        </dd>
    );
    const administratorName = employees
        .find((item) => item.id === adminId)?.name;
    const administratorField = (
        <dd>
            <Link to={`/employees/${adminId}`}>{administratorName}</Link>
        </dd>
    );
    const errorIndicator = assignment.status === InspectionStatus.Errored
        ? <div className="errored" />
        : null;

    const relatedContactsPreSort: Array<ShareTarget> = [
        ...clientContacts.filter((contact) => contact.clientId === client?.id),
        ...assignmentShares.filter((share) => share.assignmentId === assignment?.id),
    ]
        // Ensure we get consistent sequence, just in case they somehow have managed to create duplicates
        // of either contacts and/or shares:
        .sort((a, b) => (a.id ?? UNKNOWN).localeCompare(b.id ?? UNKNOWN))
        .reduce((memo: Array<ShareTarget>, contact: ClientContact) => {
            const target = parseOneAddress(contact.emailAddress ?? '') as ParsedMailbox;
            const { name, address } = target;
            const isCurrentItemShare = 'slug' in contact;
            const hasShared = Boolean((contact as any).sentOn);

            const currentItemShareId = isCurrentItemShare ? contact.id : null;
            const currentItemContactId = isCurrentItemShare ? null : contact.id;

            const [alreadyKnown, remainder] = partition((item) => item.emailAddress === address, memo);

            if (alreadyKnown.length) {
                const previous = alreadyKnown[0];

                // Between what we have now and what we already know, we should be able to
                // piece together both contact and share IDs
                const shareId = previous.shareId ?? currentItemShareId ?? null;
                const contactId = previous.contactId ?? currentItemContactId ?? null;

                // Collate both share and contact details together
                return [...remainder, {
                    ...previous,
                    shareId,
                    contactId,
                    hasShared: previous.hasShared || hasShared,
                } as ShareTarget];
            }
            return [...memo, {
                contactId: currentItemContactId,
                shareId: currentItemShareId,
                emailAddress: address,
                name,
                hasShared,
            } as ShareTarget];
        }, []);
    const relatedContacts = sortWith<ShareTarget>([
        descend((item) => item.emailAddress === client.email),
        descend((item) => Boolean(item.contactId)),
        ascend((item) => item.emailAddress),
    ])(relatedContactsPreSort);

    const shareSlug = assignmentShares.find(
        (item) => item.assignmentId === assignment.id && item.slug,
    )?.slug;
    const sharePreviewTarget = shareSlug && `${window.location.pathname}?slug=${shareSlug}`;

    const shareItems: Array<ListItem> = relatedContacts
        .map((contact: ShareTarget) => {
            const targetShare = assignmentShares.find((item) => item.id === contact.shareId);
            const canUnshare = client.email !== contact.emailAddress;
            const isTemp = !contact.contactId;

            const unshareHandler = (event: MouseEvent) => {
                event.preventDefault();
                apiServices.deleteAssignmentShare(contact.shareId ?? UNKNOWN)
                    .then(() => onShareRemoved(contact.shareId ?? UNKNOWN))
                    .catch(() => onError('Failed to remove target'));

                return false;
            };
            const slug = targetShare?.slug ?? makeUuid();
            let shareHandler;

            if (!contact.shareId) {
                // Need to provision share from this contact
                shareHandler = () => {
                    apiServices.createAssignmentShare({
                        ...constructors.createNewAssignmentShare(
                            assignment.clientId,
                            assignment.id ?? UNKNOWN,
                        ),
                        slug,
                        emailAddress: contact.emailAddress,
                        sentOn: new Date().toJSON(),
                    })
                        .then(onShareAdded);

                    return true;
                };
            } else if (!contact.hasShared) {
                // Sharing with a pre-existing share record without a share history

                shareHandler = (event: MouseEvent) => {
                    if (!targetShare) {
                        event.preventDefault();
                        onError('Failed to identify matching share record; please refresh or retry.');

                        return false;
                    }
                    apiServices.updateAssignmentShare({
                        ...targetShare,
                        sentOn: new Date().toJSON(),
                    })
                        .then(onShareUpdated);

                    return true;
                };
            }

            const mailLink = generateShareEmail(contact.name, contact.emailAddress, slug);

            const shareLink = (
                <a
                    target="_blank"
                    href={mailLink}
                    className={contact.hasShared ? 'reshare' : 'share'}
                    onClick={shareHandler as any}
                    rel="noreferrer"
                >{contact.hasShared ? 'Share Again' : 'Share'}
                </a>
            );
            const unshareButton = canUnshare
                ? (
                    <button
                        type="button"
                        className="unshare"
                        onClick={unshareHandler as any}
                    >Unshare
                    </button>
                )
                : null;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const editContactHandler = () => setEditingShareTarget(contact);

            return {
                id: contact.shareId ?? contact.contactId ?? UNKNOWN,
                title: contact.emailAddress,
                className: classNames({
                    hasShared: contact.hasShared,
                    isPrimaryContact: contact.emailAddress === client.email,
                }),
                children: (
                    <>
                        <div className="addressParts">
                            <button
                                type="button"
                                className="name"
                                onClick={editContactHandler}
                            >{contact?.name}
                            </button>
                            <button
                                type="button"
                                className="email"
                                onClick={editContactHandler}
                            >&lt;{contact?.emailAddress}&gt;
                            </button>
                            <div className={classNames({ isTemp })} />
                        </div>
                        <div className="share-buttons">
                            <RenderIf condition={contact.hasShared}>
                                {unshareButton}
                            </RenderIf>
                            {shareLink}
                        </div>
                    </>
                ),
            };
        });

    return (
        <div className="assignmentDetail">
            <main>
                <AppHeader getApplicationState={getApplicationState}>
                    <></>
                </AppHeader>

                <div className="heading">
                    <h1>{assignmentName}</h1>
                    {errorIndicator}
                </div>

                <div className="half-pane">
                    <DetailPanel>
                        <dl>
                            <dt>Client</dt>
                            <dd><Link to={`/clients/${client.id}`}>{client.name}</Link></dd>

                            <dt>Reviewer</dt>
                            {administratorField}

                            <dt>Inspector</dt>
                            {inspectorField}

                            <dt>Due</dt>
                            {dueDateField}
                        </dl>
                        {editModeToggle}
                    </DetailPanel>

                    <div className="notes">
                        {notesContent}
                    </div>
                    <div className="status">
                        <h2>Status</h2>
                        <select
                            className="assignmentStatus"
                            name="assignmentStatus"
                            onChange={handleStatusUpdated}
                            value={status}
                        >
                            {assignmentOptions}
                        </select>
                    </div>
                </div>

                <div className="summary">
                    <h2>Summary</h2>
                    <RenderIf condition={subjectCells.length > 0}>
                        <ul className="equipmentIcons">
                            {subjectCells}
                        </ul>
                    </RenderIf>

                    <RenderIf condition={subjectCells.length === 0}>
                        <div className="empty">No Equipment</div>
                    </RenderIf>
                </div>

                <div className="inspectionsTable">
                    <h2>Inspections</h2>
                    <List
                        className="inspectionsList"
                        itemClass="inspection"
                        items={inspectionCells}
                    />
                </div>

                <div className="share">
                    <div className="shareHeadingSubtitle">
                        <h2>Share Assignment Report</h2>
                        <RenderIf condition={Boolean(sharePreviewTarget)}>
                            <a
                                rel="noreferrer"
                                href={sharePreviewTarget as string}
                                target="_blank"
                            >Preview Report
                            </a>
                        </RenderIf>
                    </div>
                    <List
                        className="assignmentSharesList"
                        itemClass="share"
                        items={shareItems}
                        append={(
                            <Actions>
                                <button
                                    type="button"
                                    className="add-action"
                                    onClick={showContactDialog}
                                >Add a Contact
                                </button>
                            </Actions>
                        )}
                    />
                </div>

                <div className="actions">
                    <button
                        onClick={handleAssignmentDeletion}
                        className="risky"
                        type="button"
                    >Delete Assignment
                    </button>
                </div>
            </main>
            <RenderIf condition={showingContactDialog}>
                <AddContactDialog
                    onCancel={hideContactDialog}
                    onShareAdded={onShareAdded}
                    onContactAdded={onContactAdded}
                    assignmentId={assignment?.id ?? UNKNOWN}
                    clientId={assignment?.clientId ?? UNKNOWN}
                    apiServices={apiServices}
                />
            </RenderIf>
            <RenderIf condition={Boolean(editingShareTarget)}>
                <EditContactDialog
                    onClose={hideEditContactDialog}
                    apiServices={apiServices}
                    onContactUpdated={onContactUpdated}
                    onContactAdded={onContactAdded}
                    onContactRemoved={onContactRemoved}
                    onShareUpdated={onShareUpdated}
                    onShareRemoved={onShareRemoved}
                    shareTarget={editingShareTarget as ShareTarget}
                    isDefaultClientContact={editingShareTarget?.emailAddress === client.email}
                    onError={onError}
                    contacts={clientContacts}
                    shares={assignmentShares}
                />
            </RenderIf>
        </div>
    );
}
