import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { all } from 'ramda';
import {
    Assignment, Client,
    EquipmentAsset,
    Inspection,
} from '../types';
import isConsideredOpen from './is-considered-open';
import { UNKNOWN } from '../constants';

const MIN_DATE = '1900-01-01';

export function isInProgress(assignment: Assignment, inspections: Array<Inspection>): boolean {
    return inspections
        .filter((insp) => insp.assignmentId === assignment.id)
        .some((insp) => !isConsideredOpen(insp.status));
}

export function isDueThisWeek(assignment: Assignment): boolean {
    dayjs.extend(isoWeek);

    const assignmentDate = dayjs(assignment.dueDate);

    return assignmentDate.year() === dayjs().year()
        && assignmentDate.isoWeek() === dayjs().isoWeek();
}

function byAssignmentDueDate(lhs: Assignment | undefined, rhs: Assignment | undefined) {
    const dateLeft = dayjs(lhs?.dueDate ?? MIN_DATE);
    const dateRight = dayjs(rhs?.dueDate ?? MIN_DATE);

    return dateLeft.valueOf() - dateRight.valueOf();
}

export function tryGetOpenAssignmentForClient(
    clientId: string,
    assignments: Array<Assignment>,
): Assignment | null {
    const [nextOpenAssignment] = assignments
        .filter((item) => isConsideredOpen(item.status) && item.clientId === clientId)
        .sort(byAssignmentDueDate);

    return nextOpenAssignment ?? null;
}

export function tryGetInReviewAssignmentForClient(
    clientId: string,
    assignments: Array<Assignment>,
): Assignment | null {
    const [inReviewAssignment] = assignments
        .filter((item) => item.status === 1 && item.clientId === clientId)
        .sort(byAssignmentDueDate);

    return inReviewAssignment ?? null;
}

export default function canAddClientAssignment(client: Client, assignments: Array<Assignment>) {
    return !tryGetOpenAssignmentForClient(client.id ?? UNKNOWN, assignments);
}

export function tryGetOpenAssignmentForAsset(
    asset: EquipmentAsset,
    assignments: Array<Assignment>,
    inspections: Array<Inspection>,
): Assignment | null {
    const equipmentInspections = inspections
        .filter((item) => item.equipmentId === asset.id);
    const [matchingAssignment] = equipmentInspections
        .filter((item) => isConsideredOpen(item.status))
        .map((item) => assignments.find(
            (assignment) => assignment.id === item.assignmentId,
        ))
        .filter((item) => isConsideredOpen(item?.status))
        .sort(byAssignmentDueDate);

    if (matchingAssignment) {
        return matchingAssignment;
    }

    const [nextOpenAssignment] = assignments
        .filter((assignment) => !equipmentInspections.some((item) => item.assignmentId === assignment.id))
        .filter((item) => isConsideredOpen(item.status) && item.clientId === asset.clientId)
        .sort(byAssignmentDueDate);

    return nextOpenAssignment ?? null;
}

export function isAssignmentReadyForSubmission(
    assignment: Assignment,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
): boolean {
    const knownClientEquipment = equipment.filter((item) => item.clientId === assignment.clientId);

    return all(
        (item) => !tryGetOpenAssignmentForAsset(item, [assignment], inspections),
        knownClientEquipment,
    );
}
