import React, { ChangeEvent } from 'react';
import { useEnhancedReducer } from 'enhanced-reducer';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import Loading from '../Loading';
import {
    Assignment, Client, EquipmentAsset, Inspection, TrackTireInspection, UserLogin,
} from '../../types';
import inspectionEditReducer from '../../state/inspection-edit/reducers';

import Services from '../../services';
import { actionCreators, getInitialState } from '../../state/inspection-edit';
import getInitializeStateHandler
    from '../../state/inspection-edit/thunks/get-initialize-state-handler';
import getReplaceSelectedSubjectHandler
    from '../../state/inspection-edit/thunks/get-replace-selected-subject-handler';
import getDeleteSubjectHandler from '../../state/inspection-edit/thunks/get-delete-subject-handler';
import { debouncedUpdate } from '../../utils/update-inspection';
import getInspectionStatusUpdateHandler
    from '../../state/inspection-edit/thunks/get-inspection-status-update-handler';
import tryDeleteInspection from '../../utils/try-delete-inspection';
import Page from './Page';
import { ADMIN } from '../../constants';
import { State } from '../../state/application';
import isConsideredOpen from '../../utils/is-considered-open';

interface Props {
    photosCache: Record<number, string | symbol>,
    currentUser: UserLogin,
    clients: Array<Client>,
    assignments: Array<Assignment>,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    apiServices: Services,
    getApplicationState: () => State,
    handleError: (message: string) => void,
    onInspectionUpdated: (inspection: Inspection) => void,
    onInspectionDeleted: (inspectionId: string) => void,
    onImageDeleted: (imageId: string) => void,
    onPhotoRequested: (imageId: string) => void,
    onPhotosAdded: (photos: Record<number, string>) => void,
    loading: {
        clients: boolean,
        equipment: boolean,
        inspections: boolean,
        assignments: boolean,
    },
    match: routerMatch<ExtractRouteParams<string, string>>,
}

const isSubjectPending = (subj: TrackTireInspection) => !subj.completionStatus;

export default function InspectionDetail(props: Props) {
    const {
        photosCache,
        currentUser,
        clients,
        equipment,
        assignments,
        inspections,
        apiServices,
        getApplicationState,
        loading,
        onInspectionUpdated,
        onInspectionDeleted,
        onImageDeleted,
        onPhotosAdded,
        onPhotoRequested,
        handleError,
        match: { params },
    } = props;

    const isLoading = loading.clients
        || loading.equipment
        || loading.inspections
        || loading.clients
        || loading.assignments;

    const [state, dispatch, getState] = useEnhancedReducer(inspectionEditReducer, getInitialState());

    if (isLoading) {
        return <Loading />;
    }

    const client = clients.find((item) => `${item.id}` === params.clientId);
    const inspection = inspections.find((item) => `${item.id}` === params.inspectionId);
    const asset = equipment.find((item) => item.id === inspection?.equipmentId);
    const assignment = assignments.find((item) => `${item.id}` === params.assignmentId);

    const isReadOnly = !isConsideredOpen(inspection?.status) && currentUser.role !== ADMIN;
    const isReadyToSubmit = !state.subjects.some(isSubjectPending);

    const initializeState = getInitializeStateHandler(dispatch, apiServices, onPhotoRequested);
    const replaceSelectedSubject = getReplaceSelectedSubjectHandler(dispatch, getState, onPhotosAdded);
    const deleteSubject = getDeleteSubjectHandler(dispatch, getState);
    const updateStatus = getInspectionStatusUpdateHandler(
        dispatch,
        getState,
        handleError,
        apiServices,
        inspection,
        client,
        onInspectionUpdated,
        getApplicationState().appHistory,
    );

    if (!state.initialized && !isLoading) {
        if (!inspection) {
            handleError('Failed to load inspection.');
            return null;
        }
        if (!asset) {
            handleError('Failed to load equipment asset.');
            return null;
        }
        initializeState(inspection, asset);

        if (asset.primaryImageId) {
            onPhotoRequested(asset.primaryImageId);
        }
        if (asset.vinImageId) {
            onPhotoRequested(asset.vinImageId);
        }
    }

    if (!client || !asset || !assignment || !inspection) {
        return null;
    }

    if (state.pendingSave) {
        dispatch(actionCreators.setPendingSave(false));

        debouncedUpdate(
            inspection,
            state.status,
            state.condition,
            state.hours,
            state.includeInReport,
            state.notes,
            state.subjects,
            apiServices,
            handleError,
            onInspectionUpdated,
        );
    }

    const updateCondition = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(event.target.value, 10);

        if (Number.isNaN(value)) {
            throw new Error(`Unknown value ${event.target.value}`);
        }

        dispatch(actionCreators.setCondition(value));
        dispatch(actionCreators.setPendingSave(true));
    };
    const handleHoursChanged = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(actionCreators.setHours(parseFloat(event.target.value)));
        dispatch(actionCreators.setPendingSave(true));
    };
    const handleNotesChanged = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(actionCreators.setNotes(event.target.value));
        dispatch(actionCreators.setPendingSave(true));
    };
    const handleIncludeInReportChanged = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(actionCreators.setIncludeInReport(event.target.checked));
        dispatch(actionCreators.setPendingSave(true));
    };
    const handleInspectionDeletion = () => tryDeleteInspection(
        inspection,
        apiServices,
        onInspectionDeleted,
        handleError,
        getApplicationState().appHistory,
        client,
    );

    return (
        <Page
            photosCache={photosCache}
            currentUser={currentUser}
            onError={handleError}
            onImageDeleted={onImageDeleted}
            client={client}
            isReadOnly={isReadOnly}
            isReadyToSubmit={isReadyToSubmit}
            state={state}
            dispatch={dispatch}
            asset={asset}
            apiServices={apiServices}
            replaceSelectedSubject={replaceSelectedSubject}
            deleteSubject={deleteSubject}
            getApplicationState={getApplicationState}
            updateStatus={updateStatus}
            assignment={assignment}
            handleIncludeInReportChanged={handleIncludeInReportChanged}
            updateCondition={updateCondition}
            handleHoursChanged={handleHoursChanged}
            handleNotesChanged={handleNotesChanged}
            handleInspectionDeletion={handleInspectionDeletion}
        />
    );
}
