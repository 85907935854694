import React from 'react';

import {
    Assignment,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
} from '../../types';
import Header from './Header';
import InspectionOverview from './InspectionOverview';
import Footer from './Footer';
import InspectedEquipmentList from './InspectedEquipmentList';

interface Props {
    categories: Array<Category>,
    client: Client,
    inspector: Employee | null,
    assignment: Assignment,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    locations: Array<Geolocation>,
    photos: Record<number, Blob>,
}

export default function Page(props: Props) {
    const {
        locations,
        inspections,
        equipment,
        client,
        categories,
        assignment,
        inspector,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        photos,
    } = props;

    const clientLocation = locations.find((item) => item.id === client.locationId) ?? null;

    return (
        <section className="inspectionReport">
            <div className="inspectionReport--int">
                <Header
                    assignment={assignment}
                    client={client}
                    inspector={inspector}
                    clientLocation={clientLocation}
                />
                <InspectionOverview
                    inspections={inspections}
                    equipment={equipment}
                />
                <InspectedEquipmentList
                    locations={locations}
                    equipment={equipment}
                    inspections={inspections}
                    categories={categories}
                    photos={photos}
                />
            </div>
            <Footer />
        </section>
    );
}
