import { AxlePosition, SubjectPosition } from '../types';

export default function formatSubjectPosition(position: SubjectPosition, subjectCount: number): string {
    const {
        axlePosition,
        alignment,
    } = position;

    let axlePositionText;

    if (axlePosition === AxlePosition.Inside) {
        axlePositionText = 'Primary';
    } else if (axlePosition === AxlePosition.Middle) {
        axlePositionText = 'Dual';
    } else if (axlePosition === AxlePosition.Outside) {
        if (subjectCount === 1) {
            axlePositionText = 'Primary';
        } else if (subjectCount === 2) {
            axlePositionText = 'Dual';
        } else {
            axlePositionText = 'Triple';
        }
    }

    const alignmentText = alignment as string;

    return `${alignmentText} ${axlePositionText}`;
}
