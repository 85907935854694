import React from 'react';
import { Link } from 'react-router-dom';
import parseLocationDisplayDetails, { LocationDisplayType } from '../utils/parse-location-display-details';
import { Client, Geolocation } from '../types';
import formatStreetAddressForAppleMaps from '../utils/format-street-address-for-apple-maps';
import formatLatLngForAppleMaps from '../utils/format-lat-lng-for-apple-maps';

interface Props {
    client: Client,
    location: Geolocation | undefined,
    readonly?: boolean,
}

export default function LocationDetails(props: Props) {
    const { client, location, readonly } = props;

    const locationDetails = parseLocationDisplayDetails(location ?? null);
    const longitudeDir = Math.sign(location?.lng ?? -1) ? 'E' : 'W';
    const latitudeDir = Math.sign(location?.lat ?? -1) ? 'N' : 'S';

    const isPrimaryLocation = client.locationId === location?.id;

    /* Address formatting scheme attempts to do the following:
      1.  Break street1/street2 and city/state/zip into two separate lines (br-delimited)
      2.  Within each line, comma-delimit as necessary
      3.  Any missing elements or rows will be omitted, and unnecessary delimiters omitted as well
     */
    const addressFirstRow = [location?.street1, location?.street2]
        .filter((x) => x)
        .join(', ');
    const addressSecondRow = [location?.city, location?.state, location?.postalCode]
        .filter((x) => x)
        .join(', ');

    const addressRows = addressFirstRow && addressSecondRow
        ? [addressFirstRow, <br />, addressSecondRow]
        : [addressFirstRow, addressSecondRow].filter((x) => x);

    switch (locationDetails) {
        case LocationDisplayType.Geo: {
            const editLink = isPrimaryLocation || readonly
                ? null
                : (
                    <Link
                        className="location-edit"
                        to={`/clients/${client.id ?? -1}/locations/${location?.id}/edit`}
                    >Edit
                    </Link>
                );

            return (
                <>
                    <dt>Address</dt>
                    <dd>
                        <a href={formatLatLngForAppleMaps(location)} target="_blank" rel="noreferrer">
                            <span>
                                {Math.abs(location?.lat ?? -1)?.toFixed(4)}&deg;&nbsp;
                                {latitudeDir}
                            </span>
                            <br />
                            <span>
                                {Math.abs(location?.lng ?? -1)?.toFixed(4)}&deg;&nbsp;
                                {longitudeDir}
                            </span>
                        </a>
                    </dd>
                    {editLink}
                </>
            );
        }
        case LocationDisplayType.Address: {
            const editLink = isPrimaryLocation || readonly
                ? null
                : (
                    <Link
                        className="location-edit"
                        to={`/clients/${client.id ?? -1}/locations/${location?.id}/edit`}
                    >Edit
                    </Link>
                );

            return (
                <>
                    <dt>Address</dt>
                    <dd>
                        <a href={formatStreetAddressForAppleMaps(location)} target="_blank" rel="noreferrer">
                            {addressRows}
                        </a>
                        {editLink}
                    </dd>
                </>
            );
        }
        default: {
            const editLink = isPrimaryLocation || readonly
                ? null
                : (
                    <Link
                        className="location-edit"
                        to={`/clients/${client.id ?? -1}/locations/${location?.id}/edit`}
                    >Edit
                    </Link>
                );

            return (
                <>
                    <dt>Address</dt>
                    <dd>
                        No address or geolocation currently set.
                    </dd>
                    {editLink}
                </>
            );
        }
    }
}
LocationDetails.defaultProps = { readonly: false };
