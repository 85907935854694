import React from 'react';
import { uniq } from 'ramda';
import { Action, actionCreators, State } from '../index';
import { TrackTireInspection } from '../../../types';

type Dispatcher = React.Dispatch<Action>;

export default function getDeleteSubjectHandler(dispatch: Dispatcher, getState: () => State) {
    return (toRemove: TrackTireInspection) => {
        const state = getState();
        const remainingSubjects = state.subjects.filter(
            (subject) => subject !== toRemove,
        );

        // It may be necessary to recalculate axle numbers if, by deleting a subject,
        // we've nixed an entire axle.
        const uniqueAxleNumbers = uniq(
            remainingSubjects.map((sub) => sub.axle),
        ).sort();

        const subjectsWithAdjustedAxles = remainingSubjects.map((sub) => {
            const revisedAxleNumber = uniqueAxleNumbers.indexOf(sub.axle) + 1;

            return {
                ...sub,
                axle: `Axle ${revisedAxleNumber}`,
            };
        });

        dispatch(actionCreators.setSelectedSubject(null));
        dispatch(actionCreators.setSubjects(subjectsWithAdjustedAxles));
        dispatch(actionCreators.setPendingSave(true));
    };
}
