import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Unset } from '../../../constants';
import { convertImageBlobToDataUrl } from '../../../utils/get-image-src';

export default function loadPhoto(dispatch: Dispatch<Action>, getState: () => State) {
    return (id: string, onFailure: (message: string) => void) => {
        const alreadyTracked = getState().photosRepo;

        if (alreadyTracked.cache[id] !== undefined || alreadyTracked.pending.includes(id)) {
            // We're already on the case, do nothing
            return;
        }

        dispatch(actionCreators.setPhotoLoading(id));

        getState().apiServices.getPhoto(id)
            .then(convertImageBlobToDataUrl)
            .then((dataUrl) => {
                dispatch(actionCreators.addPhotoToRepo(id, dataUrl));
            })
            .catch(() => {
                dispatch(actionCreators.addPhotoToRepo(id, Unset));
                onFailure(`Unable to load image ${id}`);
            });
    };
}
