import dayjs from 'dayjs';
import React from 'react';
import { DATE_FORMAT } from '../../constants';
import { RenderIf } from '../../controls';
import { EmployeeAssignment } from '../../utils/get-assigned-employee-for-client';
import { Client } from '../../types';

interface Props {
    errorFlag: JSX.Element | null,
    primaryLocation: any,
    client: Client,
}

interface AssignedProps extends Props {
    count: number,
    className: string,
    assignedTo: EmployeeAssignment | null,
    dueDate: dayjs.Dayjs,
}

function AssignedTemplate(props: AssignedProps) {
    const {
        assignedTo, dueDate, client, errorFlag, primaryLocation, count, className,
    } = props;

    return (
        <>
            <div className="row-section">
                <span className="name">
                    <span>{client.name}</span>
                    {errorFlag}
                </span>
                <span className="location">{primaryLocation?.city ?? ''}</span>
                <div className={`${className} mobile-only`}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </div>

            <div className="row-section">
                <span className="assignedTo">
                    <span>Assigned to </span>
                    <span className="employeeName">{assignedTo?.employee.name}</span>
                </span>
                <span className="dueDate">
                    <RenderIf condition={dueDate.isValid()}>
                        <>Due {dueDate.format(DATE_FORMAT)}</>
                    </RenderIf>
                </span>

                <div className={className}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </div>
        </>
    );
}

function NarrowTemplate(props: Props) {
    const { client, errorFlag, primaryLocation } = props;

    return (
        <>
            <span className="name">
                <span>{client.name}</span>
                {errorFlag}
            </span>

            <span className="location">{primaryLocation?.city ?? ''}</span>
        </>
    );
}

export { AssignedTemplate, NarrowTemplate };
