import React, {
    ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import Services from '../../services';
import { AssignmentShare, ClientContact, ShareTarget } from '../../types';
import { ESC_KEY, UNKNOWN } from '../../constants';
import { NamedInput, RenderIf } from '../../controls';
import * as constructors from '../../types/constructors';

interface Props {
    apiServices: Services,
    shareTarget: ShareTarget,
    onClose: () => void,
    onError: (message: string) => void,
    onContactAdded: (contact: ClientContact) => void,
    onContactUpdated: (contact: ClientContact) => void,
    onContactRemoved: (contactId: string) => void,
    onShareUpdated: (share: AssignmentShare) => void,
    onShareRemoved: (shareId: string) => void,
    isDefaultClientContact: boolean,
    contacts: Array<ClientContact>,
    shares: Array<AssignmentShare>,
}

export default function EditContactDialog(props: Props) {
    const {
        apiServices,
        onClose,
        onContactAdded,
        onContactUpdated,
        onContactRemoved,
        onShareRemoved,
        onShareUpdated,
        onError,
        shareTarget,
        isDefaultClientContact,
        contacts,
        shares,
    } = props;

    const isContact = Boolean(shareTarget.contactId);

    const [initialized, setInitialized] = useState<ShareTarget | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isPermanentContact, setIsPermanentContact] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<any>) => {
        if (event.key === ESC_KEY) {
            onClose();
        }
    }, [onClose]);

    useEffect(() => {
        if (initialized !== shareTarget) {
            setInitialized(shareTarget);

            setName(shareTarget.name ?? '');
            setEmail(shareTarget.emailAddress);
            setIsPermanentContact(shareTarget.contactId !== null);
        }
    }, [initialized, shareTarget]);

    const handleDeletion = useCallback(() => {
        if (isSaving) {
            return;
        }

        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure?')) {
            setIsSaving(true);

            const contact = contacts.find((item) => item.id === shareTarget.contactId);
            const share = contacts.find((item) => item.id === shareTarget.shareId);

            if (!contact?.id || !share?.id) {
                // We shouldn't be able to get this far without valid share and contact.
                onError('This operation cannot be performed at this time.');
                setIsSaving(false);
            } else {
                const shareDeletion = apiServices.deleteAssignmentShare(`${share.id}`);
                const contactDeletion = shareDeletion.then(() => apiServices.deleteClientContact(`${contact.id}`));

                shareDeletion.then(() => onShareRemoved(share.id ?? UNKNOWN));

                contactDeletion
                    .then(() => onContactRemoved(contact.id ?? UNKNOWN))
                    .then(onClose)
                    .catch(() => {
                        onError('An error occurred while deleting the contact.');
                    })
                    .then(() => {
                        setIsSaving(false);
                    });
            }
        }
    }, [
        apiServices,
        contacts,
        isSaving,
        onClose,
        onContactRemoved,
        onError,
        onShareRemoved,
        shareTarget.contactId,
        shareTarget.shareId,
    ]);

    const handleSubmission = useCallback((event: FormEvent) => {
        event.preventDefault();

        if (isSaving) {
            return;
        }

        setIsSaving(true);

        const nameAndEmail = name
            ? `"${name}" <${email}>`
            : email;

        if (!isContact) {
            if (!isPermanentContact) {
                // Update share record
                const share = shares.find((item) => item.id === shareTarget.shareId);

                if (!share) {
                    onError('This record cannot be updated at this time.');
                } else {
                    apiServices.updateAssignmentShare({
                        ...share,
                        emailAddress: nameAndEmail,
                    })
                        .then(onShareUpdated)
                        .then(() => onClose())
                        .catch(() => {
                            onError('An error occurred while saving.');
                        })
                        .then(() => setIsSaving(false));
                }
            } else {
                // Promote share record to permanent contact
                const share = shares.find((item) => item.id === shareTarget.shareId);

                if (!share) {
                    onError('This record cannot be updated at this time.');
                } else {
                    const contactCreation = apiServices.createClientContact({
                        ...constructors.createNewClientContact(share.clientId),
                        emailAddress: nameAndEmail,
                    });

                    contactCreation.then(onContactAdded);

                    // Update share record
                    contactCreation
                        .then(() => apiServices.updateAssignmentShare({
                            ...share,
                            emailAddress: nameAndEmail,
                        }))
                        .then(onShareUpdated)
                        .then(() => onClose())
                        .catch(() => {
                            onError('An error occurred while saving.');
                        })
                        .then(() => setIsSaving(false));
                }
            }
        } else {
            const share = shares.find((item) => item.id === shareTarget.shareId);
            const contact = contacts.find((item) => item.id === shareTarget.contactId);

            if (!share || !contact) {
                onError('This record cannot be updated at this time.');
            } else {
                // Update contact record
                const contactUpdate = apiServices.updateClientContact({
                    ...contact,
                    emailAddress: nameAndEmail,
                });

                contactUpdate.then(onContactUpdated);

                // Update share record
                contactUpdate
                    .then(() => apiServices.updateAssignmentShare({
                        ...share,
                        emailAddress: nameAndEmail,
                    }))
                    .then(onShareUpdated)
                    .then(() => onClose())
                    .catch(() => {
                        onError('An error occurred while saving.');
                    })
                    .then(() => setIsSaving(false));
            }
        }
    }, [
        apiServices,
        contacts,
        email,
        isContact,
        isPermanentContact,
        isSaving,
        name,
        onClose,
        onContactAdded,
        onContactUpdated,
        onError,
        onShareUpdated,
        shareTarget.contactId,
        shareTarget.shareId,
        shares,
    ]);

    useEffect(() => {
        inputRef.current?.focus();
        // @ts-ignore
        window.addEventListener('keydown', handleKeyDown, true);

        return () => {
            // @ts-ignore
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [handleKeyDown]);

    return (
        <section
            role="dialog"
            className="dialog reviewSubmission"
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="clickRegion" onClick={onClose} onKeyDown={handleKeyDown} />
            <main>
                <form onSubmit={handleSubmission}>
                    <h2>Edit a Contact</h2>
                    <NamedInput
                        name="Name"
                        value={name}
                        label="Name"
                        type="text"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                    />
                    <NamedInput
                        name="EmailAddress"
                        value={email}
                        label="Email *"
                        type="email"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
                    />
                    <RenderIf condition={!isContact}>
                        <NamedInput
                            name="IsPermanentContact"
                            value={isPermanentContact}
                            label="Permanent Contact"
                            type="checkbox"
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => setIsPermanentContact(evt.target.checked)}
                        />
                    </RenderIf>

                    <div className="buttons">
                        <button type="submit" disabled={!email || isSaving}>Save</button>
                        <button type="button" onClick={onClose} disabled={isSaving} className="cancel">Cancel</button>
                    </div>

                    <RenderIf condition={isContact && !isDefaultClientContact}>
                        <footer>
                            <button
                                onClick={handleDeletion}
                                type="button"
                                className="risky"
                                disabled={isSaving}
                            >Delete Contact
                            </button>
                        </footer>
                    </RenderIf>
                </form>
            </main>
        </section>
    );
}
