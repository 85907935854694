/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement } from 'react';
import { groupBy, toPairs } from 'ramda';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { NoResultsSafeList } from '../controls';
import {
    Assignment,
    Client,
    EquipmentAsset,
    Geolocation,
    Inspection,
    InspectionStatus,
    Severity,
    UserLogin,
} from '../types';
import Loading from './Loading';
import { isDueThisWeek } from '../utils/assignment-status';
import * as querySeverityAndCount from '../utils/query-severity-and-count';
import getPendingAssignmentsCount from '../utils/get-pending-assignments-count';
import List, { ListItem } from '../controls/List';
import { DATE_FORMAT, UNKNOWN } from '../constants';

interface Props {
    currentUser: UserLogin,
    recentClients: Set<string>,
    locations: Array<Geolocation>,
    assignments: Array<Assignment>,
    inspections: Array<Inspection>,
    clients: Array<Client>,
    equipment: Array<EquipmentAsset>,
    loading: {
        assignments: boolean,
        locations: boolean,
        clients: boolean,
        inspections: boolean,
        equipment: boolean,
        recentClients: boolean,
    }
}

function renderClientInspections(
    client: Client,
    assignments: Array<Assignment>,
    inspections: Array<Inspection>,
    equipment: Array<EquipmentAsset>,
    locations: Array<Geolocation>,
) {
    const { count, severity } = querySeverityAndCount.byClient(
        client,
        assignments,
        equipment,
        inspections,
    );
    const primaryClientLocation = locations.find((loc) => client?.locationId === loc.id);

    return (
        <li key={client.id}>
            <Link className="row" to={`/clients/${client?.id || -1}`}>
                <span className="clientName">
                    {client?.name}
                </span>
                <span className="clientLocation">{primaryClientLocation?.city}</span>
                <div />
                <div />
                <div className={classNames('severity', Severity[severity])}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </Link>
        </li>
    );
}

export default ({
    currentUser,
    locations,
    assignments,
    inspections,
    clients,
    equipment,
    loading,
    recentClients,
}: Props) => {
    const timeOfDay = Date.now() % (3600 * 24 * 1000);
    let timeOfDayText;

    if (loading.clients
        || loading.assignments
        || loading.inspections
        || loading.equipment
        || loading.locations
        || loading.recentClients
    ) {
        return <Loading />;
    }

    if (timeOfDay < 3600 * 12 * 1000) {
        timeOfDayText = 'morning';
    } else {
        timeOfDayText = 'afternoon';
    }
    const message = `Good ${timeOfDayText}!`;
    const clientNames = Array.from(recentClients)
        .reverse()
        .map((clientId) => {
            const matchingClient = clients.find((client) => client.id === clientId);
            const name = matchingClient?.name ?? '???';

            return (
                <li>
                    <Link key={clientId} to={`/clients/${clientId}`}>
                        {name}
                    </Link>
                </li>
            );
        });

    const {
        assignedByClient,
        myAssignments,
        unassigned,
    } = getPendingAssignmentsCount(inspections, assignments, currentUser);
    const assignmentsHeaderText = `You have ${myAssignments.length} assignments to complete`;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const toClientInspectionsRow = ([idTxt, assignmentItems]: [string, Array<Assignment>]) => {
        const client = clients.find(({ id }) => `${id}` === idTxt);

        if (!client) {
            throw new Error('Unreachable');
        }

        return renderClientInspections(
            client,
            myAssignments,
            inspections,
            equipment,
            locations,
        );
    };

    const assignedInProgressRows: Array<ListItem> = Object.keys(assignedByClient)
        .map((idTxt) => {
            const client = clients.find(({ id }) => `${id}` === idTxt);
            const matchingAssignments: Array<Assignment> = assignedByClient[idTxt];

            if (!client) {
                throw new Error('should not happen.');
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { count, severity, items: affectedInspections } = querySeverityAndCount.byClient(
                client,
                assignments,
                equipment,
                inspections,
            );
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const primaryClientLocation = locations.find((loc) => client?.locationId === loc.id);
            const errorIndicator = matchingAssignments.some((asg) => asg.status === InspectionStatus.Errored)
                ? <div className="errored" />
                : null;
            const inspectionDates = affectedInspections
                .filter((insp) => insp.createdOn)
                .map((insp) => dayjs(insp.createdOn))
                .sort((a, b) => a.valueOf() - b.valueOf());

            let inspectionsText = null;

            if (inspectionDates.length) {
                const minDate = inspectionDates[0];
                const maxDate = inspectionDates[inspectionDates.length - 1];

                inspectionsText = `Inspections ${minDate.format(DATE_FORMAT)} - ${maxDate.format(DATE_FORMAT)}`;
            }

            return {
                id: client.id ?? UNKNOWN,
                children: (
                    <>
                        <span className="name">
                            <span>{client.name}</span>
                            {errorIndicator}
                        </span>
                        <span className="clientLocation">{primaryClientLocation?.city}</span>
                        {/* <span className="dueDate">{dayjs(matchingAssignments[0].dueDate).format(DATE_FORMAT)}</span> */}
                        {/* <span className="inspections">{inspectionsText}</span> */}
                        <div className={classNames('severity', Severity[severity])}>
                            <span className="inspectionsCount">{count}</span>
                        </div>
                    </>
                ),
                route: `/clients/${client?.id || -1}`,
                title: client.name,
            };
        });

    const assignedAsPairs: Array<[string, Array<Assignment>]> = toPairs(assignedByClient);
    const dueThisWeekItems: Array<ReactElement> = assignedAsPairs
        .filter(([, assignmentItems]: [string, Array<Assignment>]) => assignmentItems.some(
            (asg) => isDueThisWeek(asg),
        ))
        .map((assignmentsByClientId) => toClientInspectionsRow(assignmentsByClientId));

    const unassignedByClient = groupBy(
        (item) => item.clientId,
        unassigned,
    );

    const unassignedItems: Array<ReactElement> = toPairs(unassignedByClient)
        .map(([clientIdStr]) => {
            const client = clients.find(({ id }) => `${id}` === clientIdStr);

            if (!client) {
                throw new Error('Unreachable');
            }

            return renderClientInspections(
                client,
                assignments,
                inspections,
                equipment,
                locations,
            );
        });

    return (
        <div className="dashboard">
            <header>
                <strong>{message}</strong>
                <div className="message">{assignmentsHeaderText}</div>
            </header>
            <div className="recentClients">
                <h2>Recent Clients</h2>
                <Link className="viewAll" to="/clients">View all</Link>

                <NoResultsSafeList passedClasses="" listResults={clientNames} />
            </div>
            <div className="myAssignments assignments">
                <h2>My Assignments</h2>

                <div className="inProgress">
                    <h3>In progress</h3>
                    <List
                        className="whitebars my-assignments"
                        items={assignedInProgressRows}
                        itemClass=""
                    />
                </div>

                <div className="dueThisWeek">
                    <h3>Due this week</h3>
                    <div className="table-container groupedList">
                        <NoResultsSafeList passedClasses="whitebars" listResults={dueThisWeekItems} />
                    </div>
                </div>

                <div className="unassigned assignments">
                    <h3>Unassigned</h3>
                    <div className="table-container groupedList">
                        <NoResultsSafeList passedClasses="whitebars" listResults={unassignedItems} />
                    </div>
                </div>
            </div>
        </div>
    );
};
