import React, {
    ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import dayjs from 'dayjs';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { ExtractRouteParams, match as routerMatch } from 'react-router';

import {
    Assignment, AssignmentShare, Client, Employee, InspectionStatus, UserLogin,
} from '../types';
import { AppHeader } from '../controls';
import Loading from './Loading';
import { ADMIN, DATE_FORMAT, UNKNOWN } from '../constants';
import Services from '../services';
import canAddClientAssignment from '../utils/assignment-status';
import { State } from '../state/application';
import { createNewAssignmentShare } from '../types/constructors';

interface Props {
    onCreated: (assignment: Assignment) => void,
    onCancel: () => void,
    onError: (message: string) => void,
    onWarning: (message: string) => void,
    onShareAdded: (share: AssignmentShare) => void,
    apiServices: Services,
    getApplicationState: () => State,
    clients: Array<Client>,
    employees: Array<Employee>,
    assignments: Array<Assignment>,
    currentUser: UserLogin,
    loading: {
        employees: boolean,
        clients: boolean,
        assignments: boolean,
    },
    match: routerMatch<ExtractRouteParams<string, string>>,
}

const formatDate = (value: Date | undefined): string => dayjs(value).format(DATE_FORMAT);
const isValid = ({ client, dueDate }: { client: string | null, dueDate: string | null }) => client !== null && dueDate !== null;

export default function EditAssignment(props: Props) {
    const {
        apiServices,
        onCreated,
        loading,
        onCancel,
        onError,
        onWarning,
        onShareAdded,
        getApplicationState,
        clients,
        assignments,
        match,
        currentUser,
        employees,
    } = props;

    const [initialized, setInitialized] = useState(false);
    const [admin, setAdmin] = useState<string | null>(null);
    const [client, setClient] = useState<string | null>(null);
    const [inspector, setInspector] = useState<string | null>(null);
    const [dueDate, setDueDate] = useState<string>(formatDate(undefined));

    const handleClientSelected = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setClient(event.target.value);

        const clientItem = clients.find((item) => item.id === (event.target.value ?? UNKNOWN));

        if (clientItem && !canAddClientAssignment(clientItem, assignments)) {
            onWarning('An open assignment already exists for this client.');
        }
    }, [assignments, clients, onWarning]);
    const handleAdminSelected = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setAdmin(event.target.value);
    }, []);
    const handleInspectorSelected = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setInspector(event.target.value);
    }, []);
    const handleDueDateChanged = useCallback((day: Date) => {
        setDueDate(formatDate(day));
    }, []);

    const matchingAssignment = match.params.assignmentId
        ? assignments.find((asg) => `${asg.id}` === match.params.assignmentId)
        : null;

    const handleCreation = useCallback(() => {
        if (isValid({ client, dueDate })) {
            apiServices.createAssignment({
                dueDate: new Date(dueDate).toJSON(),
                clientId: client ?? UNKNOWN,
                employeeId: inspector ?? null,
                reviewerId: admin ?? null,
                notes: null,
                completionDate: null,
                id: undefined,
                status: InspectionStatus.Open,
            })
                .then((created) => {
                    const clientItem = clients.find((item) => item.id === (client ?? -1));
                    let subtask: Promise<AssignmentShare | null> = Promise.resolve(null);

                    if (client && clientItem?.email) {
                        const emailAddress = clientItem.name
                            ? `"${clientItem.name}" <${clientItem.email}>`
                            : clientItem.email;

                        subtask = apiServices.createAssignmentShare({
                            ...createNewAssignmentShare(client, created.id ?? UNKNOWN),
                            emailAddress,
                        });
                    }

                    onCreated(created);
                    getApplicationState().appHistory.replace(`/clients/${client}/assignments/${created.id}`);

                    return subtask.then((share) => {
                        if (share) {
                            onShareAdded(share);
                        }
                    });
                })
                .catch(() => onError('An error occurred while creating the assignment.'));
        }
    }, [
        admin,
        apiServices,
        client,
        clients,
        dueDate,
        getApplicationState,
        inspector,
        onCreated,
        onError,
        onShareAdded,
    ]);
    const handleUpdate = useCallback(() => {
        if (matchingAssignment && isValid({ client, dueDate })) {
            apiServices.updateAssignment({
                ...matchingAssignment,
                employeeId: inspector ?? null,
                reviewerId: admin ?? null,
                completionDate: null,
            })
                .then((updated) => {
                    onCreated(updated);
                    getApplicationState().appHistory.replace(`/clients/${client}/assignments/${updated.id}`);
                });
        }
    }, [
        admin,
        apiServices,
        client,
        dueDate,
        getApplicationState,
        inspector,
        matchingAssignment,
        onCreated,
    ]);

    useEffect(() => {
        if (!initialized) {
            if (match.params.assignmentId !== undefined) {
                setAdmin(matchingAssignment?.reviewerId ?? null);
                setInspector(matchingAssignment?.employeeId ?? null);
                setClient(matchingAssignment?.clientId ?? null);
                setDueDate(formatDate(new Date(matchingAssignment?.dueDate ?? 0)));
            } else {
                // Creating new assignment
                if (currentUser.role === ADMIN) {
                    setAdmin(currentUser.id ?? UNKNOWN);
                } else {
                    setInspector(currentUser.id ?? UNKNOWN);
                }
                if (match.params.clientId) {
                    setClient(match.params.clientId);
                }
            }

            setInitialized(true);
        }
    }, [assignments, currentUser, initialized, match, matchingAssignment]);

    const matchingClient = clients.find((item) => `${item.id}` === match.params.clientId);

    if (loading.clients || loading.assignments || loading.employees) {
        return <Loading />;
    }

    const clientOptions = clients
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => <option value={item.id}>{item.name}</option>);
    const userOptions = employees
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => <option value={item.id}>{item.name}</option>);
    const adminOptions = employees
        .filter((emp) => emp.role === ADMIN)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => <option value={item.id}>{item.name}</option>);

    let pageTitle;
    let createButtonText;
    let saveHandler;
    let hasSubmissionRestriction = false;

    if (match.params.assignmentId) {
        if (matchingAssignment?.dueDate) {
            pageTitle = dayjs(matchingAssignment?.dueDate).format(DATE_FORMAT);
        } else {
            pageTitle = 'No due date set';
        }
        createButtonText = 'Update Assignment';
        saveHandler = handleUpdate;
    } else {
        const targetClientOption = clients.find((item) => item.id === (client ?? -1));

        hasSubmissionRestriction = targetClientOption
            ? !canAddClientAssignment(targetClientOption, assignments)
            : true;
        pageTitle = 'New Assignment';
        createButtonText = 'Create Assignment';
        saveHandler = handleCreation;
    }

    return (
        <form className="newAssignment">
            <AppHeader title={matchingClient?.name} getApplicationState={getApplicationState} hideBackButton>
                <></>
            </AppHeader>

            <h1>{pageTitle}</h1>

            <label htmlFor="client">
                Client
                <select
                    name="client"
                    id="client"
                    value={client ?? -1}
                    onChange={handleClientSelected}
                >
                    <option value="">(Please select a client)</option>
                    <>{clientOptions}</>
                </select>
            </label>

            <label htmlFor="admin">
                Reviewer
                <select
                    onChange={handleAdminSelected}
                    name="admin"
                    id="admin"
                    value={admin ?? ''}
                >
                    <option value="">(Please select a reviewer)</option>
                    <>{adminOptions}</>
                </select>
            </label>

            <label htmlFor="inspector">
                Inspector
                <select
                    onChange={handleInspectorSelected}
                    name="inspector"
                    id="inspector"
                    value={inspector ?? ''}
                >
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <option value="" />
                    <>{userOptions}</>
                </select>
            </label>

            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="dueDate">
                Due
                <DayPickerInput
                    formatDate={formatDate}
                    value={dueDate ?? ''}
                    onDayChange={handleDueDateChanged}
                    placeholder={DATE_FORMAT}
                    format={DATE_FORMAT}
                    inputProps={{ readOnly: true }}
                />
            </label>

            <div className="actions">
                <button
                    type="button"
                    disabled={!isValid({ client, dueDate }) || hasSubmissionRestriction}
                    onClick={saveHandler}
                >{createButtonText}
                </button>
                <button
                    className="risky"
                    type="button"
                    onClick={onCancel}
                >Cancel
                </button>
            </div>
        </form>
    );
}
