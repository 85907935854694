import React from 'react';
import classNames from 'classnames';

interface DetailPanelProps {
    className?: string | undefined,
    children?: React.ReactChildren | React.ReactNode | null,
}

export default function DetailPanel(props: DetailPanelProps) {
    const { className, children } = props;
    const classes = classNames('details', className);

    return <section className={classes}>{children}</section>;
}
DetailPanel.defaultProps = { className: undefined, children: null };
