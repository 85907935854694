import React, { useMemo } from 'react';
import upsertClient from '../../state/application/thunks/upsert-client';
import upsertLocation from '../../state/application/thunks/upsert-location';
import EditClient from '../../views/EditClient';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment,
    Client,
    ClientFilter,
    ClientsPageFilters,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
} from '../../types';
import SplitView from '../../controls/SplitView';
import LoadingView from '../../views/Loading';
import Clients from '../../views/Clients/Clients';
import { actionCreators } from '../../state/application';

export default function EditClientConnector(props: AppComponentProps) {
    const {
        match,
        loading,
        getState,
        handleError,
        dispatch,
    } = props;
    const state = getState();

    const clientsPageFilters: ClientsPageFilters = useMemo(() => ({
        ...state.viewState.clients,
        onFilterChanged: (filter: ClientFilter) => dispatch(
            actionCreators.setClientsViewStateFilter(filter),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setClientsViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.clients]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.employees
    ) {
        return <LoadingView />;
    }

    return (
        <>
            <SplitView
                className="clientsList--edit"
                leftPane={(
                    <Clients
                        getApplicationState={getState}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        employees={state.employees as Array<Employee>}
                        loading={loading}
                        pageFilters={clientsPageFilters}
                        selectedClientId={`${state.viewState.clients.selection}` ?? undefined}
                    />
                )}
                rightPane={(
                    <EditClient
                        apiServices={state.apiServices}
                        match={match}
                        getApplicationState={getState}
                        locations={state.locations as Array<Geolocation>}
                        clients={state.clients as Array<Client>}
                        onError={handleError}
                        onClientUpdated={upsertClient(dispatch, getState)}
                        onLocationUpdated={upsertLocation(dispatch, getState)}
                    />
                )}
            />
        </>
    );
}
