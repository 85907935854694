import React, { useMemo } from 'react';
import Clients from '../../views/Clients/Clients';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment,
    Client,
    ClientFilter,
    ClientsPageFilters,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
} from '../../types';
import { actionCreators } from '../../state/application';
import LoadingView from '../../views/Loading';

export default function ClientsConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
    } = props;
    const state = getState();

    const clientsPageFilters: ClientsPageFilters = useMemo(() => ({
        ...state.viewState.clients,
        onFilterChanged: (filter: ClientFilter) => dispatch(
            actionCreators.setClientsViewStateFilter(filter),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setClientsViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.clients]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.employees
    ) {
        return <LoadingView />;
    }

    return (
        <>
            <Clients
                getApplicationState={getState}
                clients={state.clients as Array<Client>}
                assignments={state.assignments as Array<Assignment>}
                locations={state.locations as Array<Geolocation>}
                equipment={state.equipment as Array<EquipmentAsset>}
                inspections={state.inspections as Array<Inspection>}
                employees={state.employees as Array<Employee>}
                loading={loading}
                pageFilters={clientsPageFilters}
            />
        </>
    );
}
