import { State } from '../state/application';
import { Assignment, FleetPageType, TopLevelPage } from '../types';
import { ADMIN, Loading, Unset } from '../constants';

const IN_INSPECTION = /^\/clients\/[0-9a-f-]+\/assignments\/[0-9a-f-]+\/inspections\/[0-9a-f-]+$/;
const IN_FLEET_CATEGORY = /^\/clients\/([0-9a-f-]+)\/fleet\/[0-9a-f-]+$/;
const IN_FLEET_CLIENT = /^\/clients\/([0-9a-f-]+)\/fleet$/;
const IN_FLEET_LOCATION = /^\/clients\/([0-9a-f-]+)\/locations\/[0-9a-f-]+\/equipment$/;
const IN_ASSIGNMENT = /^\/clients\/([0-9a-f-]+)\/assignments\/[0-9a-f-]+$/;
const IN_CLIENT = /^\/clients\/[0-9a-f-]+$/;
const IN_EMPLOYEE = /^\/employees\/[0-9a-f-]+$/;
const IN_EQUIPMENT_DETAIL = /^\/clients\/([0-9a-f-]+)\/equipment\/[0-9a-f-]+$/;
const IN_ADD_EQUIPMENT = /^\/clients\/([0-9a-f-]+)\/.*equipment\/new\b/;

export interface CfiNavRoute {
    entityType: TopLevelPage,
    fleetPageType: FleetPageType | null,
    route: string,
}

export function getCurrentRouteForTopLevel(entity: TopLevelPage, state: State): CfiNavRoute {
    let desiredRoute;
    let fleetPageType = null;
    const currentPath = state.appHistory.location.pathname;
    const isAdmin = typeof state.currentUser !== 'symbol' && state.currentUser.role === ADMIN;

    if (entity === TopLevelPage.Employees) {
        if (state.viewState.employees.selection) {
            desiredRoute = `/employees/${state.viewState.employees.selection}`;
        } else {
            desiredRoute = '/employees';
        }
    } else if (entity === TopLevelPage.Assignments) {
        if (state.viewState.assignments.selection) {
            if (state.assignments !== Unset && state.assignments !== Loading) {
                const targetAssignment = (state.assignments as Array<Assignment>)
                    .find((asg) => asg.id === state.viewState.assignments.selection);

                if (targetAssignment) {
                    desiredRoute = `/clients/${targetAssignment?.clientId}/assignments/${targetAssignment?.id}`;
                } else if (isAdmin) {
                    desiredRoute = '/assignments';
                } else {
                    desiredRoute = '/clients';
                }
            } else {
                // Do something else here?  This is a weird edge-case...
                desiredRoute = '/clients';
            }
        } else {
            desiredRoute = '/assignments';
        }
    } else if (entity === TopLevelPage.Dashboard) {
        desiredRoute = '/';
    } else if (entity === TopLevelPage.Settings) {
        desiredRoute = '/settings';
    } else if (entity === TopLevelPage.Clients) {
        if (state.viewState.clients.selection) {
            if (state.viewState.fleet.selectedAsset !== null) {
                const targetRoute = `/clients/${state.viewState.clients.selection}/equipment/${state.viewState.fleet.selectedAsset}`;

                if (targetRoute !== currentPath) {
                    desiredRoute = targetRoute;
                }
            }

            if (!desiredRoute) {
                if (state.viewState.fleet.pageType !== null) {
                    fleetPageType = state.viewState.fleet.pageType;

                    switch (fleetPageType) {
                        case FleetPageType.Location:
                            desiredRoute = `/clients/${state.viewState.clients.selection}/locations/${state.viewState.fleet.selection}/equipment`;
                            break;
                        case FleetPageType.Category:
                        default:
                            if (state.viewState.fleet.selection) {
                                desiredRoute = `/clients/${state.viewState.clients.selection}/fleet/${state.viewState.fleet.selection}`;
                            } else {
                                desiredRoute = `/clients/${state.viewState.clients.selection}/fleet`;
                            }
                            break;
                    }
                } else {
                    desiredRoute = `/clients/${state.viewState.clients.selection}`;
                }
            }
        } else {
            desiredRoute = '/clients';
        }
    } else {
        throw new Error('Not yet implemented');
    }

    if (desiredRoute === state.appHistory.location.pathname) {
        switch (entity) {
            case TopLevelPage.Employees:
                return {
                    entityType: entity,
                    fleetPageType: null,
                    route: '/employees',
                };
            case TopLevelPage.Clients:
                return {
                    entityType: entity,
                    fleetPageType: null,
                    route: '/clients',
                };
            case TopLevelPage.Settings:
                return {
                    entityType: entity,
                    fleetPageType: null,
                    route: '/settings',
                };
            case TopLevelPage.Assignments:
                return {
                    entityType: entity,
                    fleetPageType: null,
                    route: '/assignments',
                };
            case TopLevelPage.Dashboard:
            default:
                return {
                    entityType: TopLevelPage.Dashboard,
                    fleetPageType: null,
                    route: '/',
                };
        }
    }

    return {
        entityType: entity,
        fleetPageType,
        route: desiredRoute,
    };
}

export default function tryIdentifyParentRoute(state: State): CfiNavRoute | null {
    const { pathname } = state.appHistory.location;

    if (pathname.match(IN_EMPLOYEE)) {
        return {
            entityType: TopLevelPage.Employees,
            fleetPageType: null,
            route: '/employees',
        };
    }

    if (pathname.match(IN_ASSIGNMENT)) {
        return {
            entityType: TopLevelPage.Assignments,
            fleetPageType: null,
            route: '/assignments',
        };
    }

    // Clients single
    if (pathname.match(IN_CLIENT)) {
        return {
            entityType: TopLevelPage.Clients,
            fleetPageType: null,
            route: '/clients',
        };
    }

    // Fleet listing (all equipment, by category, by location)
    const fleetListing = [IN_FLEET_CATEGORY, IN_FLEET_CLIENT, IN_FLEET_LOCATION]
        .map((pat) => pathname.match(pat))
        .find((x) => x);

    if (fleetListing) {
        const [, targetClient] = fleetListing;

        return {
            entityType: TopLevelPage.Clients,
            fleetPageType: null,
            route: `/clients/${targetClient}`,
        };
    }

    // Equipment single (return to fleet listing if applicable, or client single otherwise)
    // Inspection (return to fleet listing if applicable, or client single otherwise)
    if ([IN_INSPECTION, IN_EQUIPMENT_DETAIL, IN_ADD_EQUIPMENT].some((pat) => pat.test(pathname))) {
        return getCurrentRouteForTopLevel(TopLevelPage.Clients, state);
    }

    return null;
}
