import React from 'react';
import { Link } from 'react-router-dom';
import { groupBy, sortBy, toPairs } from 'ramda';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import { AppHeader, InputWrapper, NamedInput } from '../../controls';
import {
    Assignment,
    AssignmentsPageFilters,
    Client,
    EquipmentAsset,
    Geolocation,
    Inspection,
    InspectionStatus,
    LoadingState,
    StatusFilter,
} from '../../types';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';
import List, { ListItem } from '../../controls/List';
import Loading from '../Loading';
import { DATE_FORMAT, UNKNOWN } from '../../constants';
import { State } from '../../state/application';
import isConsideredOpen from '../../utils/is-considered-open';
import { WideTemplate, NarrowTemplate } from './AssignmentTemplates';
import Search from '../../controls/Search';

interface Props {
    getApplicationState: () => State,
    loading: LoadingState,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    locations: Array<Geolocation>,
    assignments: Array<Assignment>,
    clients: Array<Client>,
    pageFilters: AssignmentsPageFilters,
    match: routerMatch<ExtractRouteParams<string, string>>,
    paneSide? : string | boolean,
}

export default function Assignments(props: Props) {
    const {
        getApplicationState,
        assignments,
        locations,
        clients,
        equipment,
        loading,
        inspections,
        match,
        pageFilters,
        paneSide,
    } = props;

    const onDateStartChanged = (value: Date) => {
        pageFilters.onDateStartChanged(value);
    };
    const onDateEndChanged = (value: Date) => {
        pageFilters.onDateEndChanged(value);
    };

    if (
        loading.clients
        || loading.assignments
        || loading.inspections
        || loading.equipment
        || loading.locations
    ) {
        return <Loading />;
    }

    const formatDate = (value: Date | undefined): string => dayjs(value).format(DATE_FORMAT);

    const pendingAssignments = assignments
        .filter((assignment) => {
            if (pageFilters.search) {
                const client = clients.find((item) => item.id === assignment.clientId);

                return client?.name.toLowerCase().includes(pageFilters.search.toLowerCase());
            }
            return true;
        })
        .filter((assignment) => {
            if (pageFilters.dateStart) {
                if (assignment.dueDate) {
                    return dayjs(assignment.dueDate) >= dayjs(pageFilters.dateStart);
                }
                // Allow unset dates to pass through filter
                return !assignment.dueDate;
            }
            // If no filter in place, let it through
            return true;
        })
        .filter((assignment) => {
            if (pageFilters.dateEnd) {
                if (assignment.dueDate) {
                    return dayjs(assignment.dueDate) <= dayjs(pageFilters.dateEnd);
                }
                // Allow unset dates to pass through filter
                return !assignment.dueDate;
            }
            // If no filter in place, let it through
            return true;
        })
        .filter((assignment) => {
            switch (pageFilters.statusFilter) {
                case StatusFilter.Assigned:
                    return assignment.employeeId && isConsideredOpen(assignment.status);
                case StatusFilter.ForReview:
                    return assignment.status === InspectionStatus.SubmittedForReview;
                case StatusFilter.Unassigned:
                    return !assignment.employeeId && isConsideredOpen(assignment.status);
                case StatusFilter.Closed:
                    return [InspectionStatus.Closed].includes(assignment.status);
                default:
                    throw new Error();
            }
        });

    const assignmentsByClient: Array<[string, Array<Assignment>]> = toPairs(groupBy(
        (item) => `${item.clientId}`,
        pendingAssignments,
    ));

    const assignmentItems: Array<ListItem> = assignmentsByClient
        .reduce((accum: Array<ListItem>, [clientId, clientAssignments]) => {
            const client = clients.find((item) => `${item.id}` === clientId);

            const sortedAssignments = sortBy((item) => item?.dueDate ?? '', clientAssignments);

            return accum.concat(sortedAssignments.map((assignment) => {
                const isSelected = match?.params.assignmentId === `${assignment.id}`;
                const { severity, count, items } = querySeverityAndCount.byAssignment(
                    assignment,
                    equipment,
                    inspections,
                );
                const sortedDates = items
                    .filter((item) => item.assignmentId === assignment.id && item.createdOn)
                    .map((item) => item.createdOn ?? '')
                    .sort();
                const rangeStart = sortedDates[0];
                const rangeEnd = sortedDates[sortedDates.length - 1];
                const dateRange = rangeStart && rangeEnd
                    ? `${dayjs(rangeStart).format(DATE_FORMAT)} - ${dayjs(rangeEnd).format(DATE_FORMAT)}`
                    : '(no dates)';

                const location = locations.find((item) => item.id === client?.locationId);
                const assignmentDueDate = assignment.dueDate
                    ? dayjs(assignment.dueDate).format(DATE_FORMAT)
                    : 'N/A';
                const title = client?.name ?? '';
                const errorIndicator = assignment.status === InspectionStatus.Errored
                    ? <span className="errored">!</span>
                    : null;

                return {
                    id: assignment?.id ?? UNKNOWN,
                    title,
                    className: classNames({ isSelected }),
                    route: `/clients/${client?.id}/assignments/${assignment?.id}`,
                    children: (
                        paneSide
                            ? (
                                <NarrowTemplate
                                    title={title}
                                    errorIndicator={errorIndicator}
                                    location={location}
                                    severity={severity}
                                    count={count}
                                />
                            )
                            : (
                                <WideTemplate
                                    title={title}
                                    errorIndicator={errorIndicator}
                                    location={location}
                                    assignmentDueDate={assignmentDueDate}
                                    dateRange={dateRange}
                                    severity={severity}
                                    count={count}
                                />
                            )
                    ),
                };
            }));
        }, []);

    return (
        <main className="assignmentsList">
            <AppHeader getApplicationState={getApplicationState} hideBackButton>
                <Link className="icn-plus" to="/assignments/new">Add Assignment</Link>
            </AppHeader>

            <header>
                <h1>Assignments</h1>

                <InputWrapper className="filters">
                    <NamedInput
                        name="assigned"
                        type="radio"
                        label="Assigned"
                        value={pageFilters.statusFilter === StatusFilter.Assigned}
                        onChange={() => pageFilters.onStatusFilterChanged(StatusFilter.Assigned)}
                    />
                    <NamedInput
                        name="unassigned"
                        type="radio"
                        label="Unassigned"
                        value={pageFilters.statusFilter === StatusFilter.Unassigned}
                        onChange={() => pageFilters.onStatusFilterChanged(StatusFilter.Unassigned)}
                    />
                    <NamedInput
                        name="forReview"
                        type="radio"
                        label="For Review"
                        value={pageFilters.statusFilter === StatusFilter.ForReview}
                        onChange={() => pageFilters.onStatusFilterChanged(StatusFilter.ForReview)}
                    />
                    <NamedInput
                        name="closed"
                        type="radio"
                        label="Closed"
                        value={pageFilters.statusFilter === StatusFilter.Closed}
                        onChange={() => pageFilters.onStatusFilterChanged(StatusFilter.Closed)}
                    />
                </InputWrapper>
            </header>

            <Search
                value={pageFilters.search}
                searchUpdate={pageFilters.onSearchChanged}
            />

            <div className="dateFilter">
                <div className="label">Filter by date range</div>

                <div className="filter-input">
                    <DayPickerInput
                        formatDate={formatDate}
                        value={pageFilters.dateStart ?? ''}
                        onDayChange={onDateStartChanged}
                        placeholder={DATE_FORMAT}
                        format={DATE_FORMAT}
                        inputProps={{ readOnly: true }}
                    />
                    {pageFilters.dateStart && <button className="clear" type="button" onClick={() => pageFilters.onDateStartChanged(null)}>X</button>}
                </div>

                <div className="filter-input">
                    <DayPickerInput
                        formatDate={formatDate}
                        value={pageFilters.dateEnd ?? ''}
                        onDayChange={onDateEndChanged}
                        placeholder={DATE_FORMAT}
                        format={DATE_FORMAT}
                        inputProps={{ readOnly: true }}
                    />
                    { pageFilters.dateEnd && <button className="clear" type="button" onClick={() => pageFilters.onDateEndChanged(null)}>X</button>}
                </div>
            </div>

            <List
                groupByFirstLetter
                className="assignments whitebars"
                itemClass="assignment"
                items={assignmentItems}
            />
        </main>
    );
}

Assignments.defaultProps = { paneSide: false };
