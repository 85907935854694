import {
    Assignment, Category, Client, EquipmentAsset, Inspection, Severity,
} from '../types';
import { getSeverityByInspectionStatus } from './lookups';
import findUnboundEquipmentForClient from './find-unbound-equipment-for-client';
import { tryGetOpenAssignmentForClient } from './assignment-status';
import isConsideredOpen from './is-considered-open';
import { UNKNOWN } from '../constants';

export interface SeverityAndCount<T> {
    severity: Severity,
    count: number,
    items: Array<T>,
}

export function byClient(
    client: Client,
    assignments: Array<Assignment>,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
): SeverityAndCount<Inspection> {
    const clientId = client.id ?? UNKNOWN;

    // Owned by client and associated to an existing assignment
    const clientAssignments = assignments
        .filter((item) => item.clientId === clientId)
        .filter((asg) => isConsideredOpen(asg.status));

    // Consider all of the relevant open inspections
    const assignmentInspections = inspections
        .filter((insp) => isConsideredOpen(insp.status))
        .filter((insp) => clientAssignments.some((asg) => insp.assignmentId === asg.id));

    // Identify any equipment for which we haven't created inspections
    const unboundEquipment = findUnboundEquipmentForClient(
        clientId,
        assignments,
        equipment,
        inspections,
    );

    const severities = [
        unboundEquipment.length ? Severity.Warning : Severity.Nominal,
        ...assignmentInspections.map(
            (insp) => getSeverityByInspectionStatus(insp.status),
        ),
    ];
    const maxSeverity = Math.max(...[Severity.Nominal, ...severities]) as Severity;

    // In the event we don't have anything of interest:
    return {
        count: assignmentInspections.length + unboundEquipment.length,
        severity: maxSeverity,
        items: assignmentInspections,
    };
}

export function byCategory(
    category: Category,
    client: Client,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    categories: Array<Category>,
    assignments: Array<Assignment>,
): SeverityAndCount<EquipmentAsset> {
    // Collate client's equipment, capturing equipment that is both an exact
    // category match as well as those belonging to child categories
    const relevantCategories = categories
        .filter(
            (item) => [item.id, item.parentId].includes(category.id ?? UNKNOWN),
        )
        .map((cat) => cat.id);
    const matchingEquipment = equipment
        .filter((item) => item.clientId === (client.id ?? -1))
        .filter((asset) => relevantCategories.includes(asset.categoryId ?? UNKNOWN));

    // Owned by client and associated to an existing assignment
    const clientId = client.id ?? -1;
    const clientAssignments = assignments
        .filter((item) => item.clientId === clientId)
        .filter((asg) => isConsideredOpen(asg.status));

    // Severity is determined by status.  From our pool of related inspections, find the worst:
    const matchingInspections = inspections
        .filter((insp) => clientAssignments.some((asg) => insp.assignmentId === asg.id))
        .filter((insp) => matchingEquipment.some((item) => item.id === insp.equipmentId));

    // Identify any equipment for which we haven't created inspections
    const unboundEquipment = findUnboundEquipmentForClient(
        client.id ?? UNKNOWN,
        assignments,
        equipment,
        inspections,
    )
        .filter((asset) => matchingEquipment.some(
            (mat) => mat.id === asset.id,
        ));

    const severities = [
        unboundEquipment.length ? Severity.Warning : Severity.Nominal,
        ...matchingInspections.map(
            (x) => getSeverityByInspectionStatus(x.status),
        ),
    ];
    const worstSeverity = Math.max(...[Severity.Nominal, ...severities]) as Severity;

    return {
        count: matchingEquipment.length,
        severity: worstSeverity,
        items: matchingEquipment,
    };
}

export function byAssignment(
    assignment: Assignment,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
): SeverityAndCount<Inspection> {
    const assignmentInspections = inspections
        .filter((insp) => isConsideredOpen(insp.status))
        .filter((insp) => insp.assignmentId === assignment.id);

    // Identify any equipment for which we haven't created inspections
    const unboundEquipment = findUnboundEquipmentForClient(
        assignment.clientId,
        [assignment],
        equipment,
        inspections,
    );

    const severities = [
        unboundEquipment.length ? Severity.Warning : Severity.Nominal,
        ...assignmentInspections.map(
            (insp) => getSeverityByInspectionStatus(insp.status),
        ),
    ];
    const maxSeverity = Math.max(...[Severity.Nominal, ...severities]) as Severity;

    // In the event we don't have anything of interest:
    return {
        count: assignmentInspections.length + unboundEquipment.length,
        severity: maxSeverity,
        items: assignmentInspections,
    };
}

export function byAsset(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    asset: EquipmentAsset,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    client: Client,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    assignments: Array<Assignment>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inspections: Array<Inspection>,
): SeverityAndCount<Inspection> {
    const openAssignment = tryGetOpenAssignmentForClient(client.id ?? UNKNOWN, assignments);
    const matchingInspections = inspections.filter(
        (insp) => insp.equipmentId === asset.id && isConsideredOpen(insp.status),
    );
    const missingInspectionIndication = openAssignment && matchingInspections.length === 0
        ? Severity.Warning
        : Severity.Nominal;
    const severities = matchingInspections.map(
        (x) => getSeverityByInspectionStatus(x.status),
    );
    const worstSeverity = Math.max(...[missingInspectionIndication, ...severities]) as Severity;

    return {
        /**
         * Always just a single asset in this context
         */
        count: matchingInspections.length,
        severity: worstSeverity,
        items: matchingInspections,
    };
}

/**
 * This method is intended for consumption by the embedded map.
 * @param location
 * @param client
 * @param equipment
 * @param assignments
 * @param inspections
 */
export function byLocation<T>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    location: Geolocation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    client: Client,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    equipment: Array<EquipmentAsset>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    assignments: Array<Assignment>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    inspections: Array<Inspection>,
): SeverityAndCount<T> | null {
    throw new Error('Not yet implemented');
}
