import dayjs from 'dayjs';
import { Assignment, Client, Employee } from '../types';
import isConsideredOpen from './is-considered-open';

export interface EmployeeAssignment {
    employee: Employee,
    assignment: Assignment,
}

export default function getAssignedEmployeeForClient(
    client: Client,
    assignments: Array<Assignment>,
    employees: Array<Employee>,
): EmployeeAssignment | null {
    const openAssignmentsBelongingToEmployees = assignments
        .filter((asg) => asg.clientId === client.id && isConsideredOpen(asg.status))
        .sort((lhs, rhs) => dayjs(lhs.dueDate).diff(dayjs(rhs.dueDate)));

    if (openAssignmentsBelongingToEmployees.length) {
        const assignment = openAssignmentsBelongingToEmployees[0];
        const employee = employees.find(
            (emp) => emp.id === assignment.employeeId,
        );

        if (!employee) {
            // TODO: This should result in some kind of error condition; for now, shunt to null for speed of development
            return null;
        }
        return {
            employee,
            assignment,
        };
    }
    return null;
}
