import { Geolocation } from '../types';
import { APPLE_MAPS_BASE_URI } from '../constants';

export default function formatStreetAddressForAppleMaps(location: Geolocation | undefined) {
    const addressParts = [
        location?.street1,
        location?.street2,
        location?.city,
        location?.state,
        location?.postalCode,
    ]
        .filter((x) => x)
        .join(', ');

    return `${APPLE_MAPS_BASE_URI}?address=${encodeURIComponent(addressParts)}`;
}
