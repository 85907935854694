import debounce from 'debounce';
import { Assignment } from '../types';
import Services from '../services';

const DEBOUNCE_INTERVAL = 200;
export default function submitAssignmentUpdate(
    assignment: Assignment | undefined,
    apiServices: Services,
    onSuccess: (assignment: Assignment) => void,
    onError?: (err: Error) => void,
) {
    if (!assignment) {
        if (onError) {
            onError(Error('Unexpected error saving assignment.'));
        }
        return;
    }

    apiServices.updateAssignment(assignment)
        .then((saved) => {
            onSuccess(saved);
        })
        .catch((err) => {
            if (onError) {
                onError(err);
            }
        });
}

export const debouncedUpdate = debounce(submitAssignmentUpdate, DEBOUNCE_INTERVAL);
