import {
    AxleAlignment, InspectionStatus, SubjectCondition, TrackTireInspection,
} from '../../types';
import { CopySpecs } from '../../views/InspectionDetail/TrackTireDetail';

export interface State {
    pendingSave: boolean,
    initialized: boolean,
    status: InspectionStatus,
    condition: SubjectCondition,
    hours: number | null,
    notes: string | null,
    includeInReport: boolean,
    subjects: Array<TrackTireInspection>,
    selectedSubject: TrackTireInspection | null,
    specs: CopySpecs | null,
}

export interface Action {
    actionType: ActionType,
}

export enum ActionType {
    SetPendingSave,
    SetInitialized,
    SetStatus,
    SetCondition,
    SetHours,
    SetNotes,
    SetIncludeInReport,
    SetSubjects,
    AddSubjectForAxleAndSide,
    SetSelectedSubject,
    SetSpecs,
    AddNewAxle,
}

export interface SetInitializedAction extends Action {}
export interface SetPendingSaveAction extends Action {
    value: boolean,
}
export interface SetStatusAction extends Action {
    value: InspectionStatus,
}
export interface SetConditionAction extends Action {
    value: SubjectCondition,
}
export interface SetHoursAction extends Action {
    value: number | null,
}
export interface SetNotesAction extends Action {
    value: string | null,
}
export interface SetIncludeInReportAction extends Action {
    value: boolean,
}
export interface SetSubjectsAction extends Action {
    value: Array<TrackTireInspection>,
}
export interface AddSubjectToAxleAndSideAction extends Action {
    axle: string,
    side: AxleAlignment,
    equipmentId: string,
}
export interface SetSelectedSubjectAction extends Action {
    value: TrackTireInspection | null,
}
export interface SetSpecsAction extends Action {
    value: CopySpecs | null,
}
export interface AddNewAxleAction extends Action {
    equipmentId: string,
}

export function getInitialState(): State {
    return {
        initialized: false,
        pendingSave: false,
        condition: SubjectCondition.Unknown,
        hours: null,
        includeInReport: false,
        notes: null,
        selectedSubject: null,
        specs: null,
        status: InspectionStatus.Open,
        subjects: [],
    };
}

export const actionCreators = {
    setStatus(value: InspectionStatus): SetStatusAction {
        return {
            actionType: ActionType.SetStatus,
            value,
        };
    },
    setCondition(value: SubjectCondition): SetConditionAction {
        return {
            actionType: ActionType.SetCondition,
            value,
        };
    },
    setHours(value: number | null): SetHoursAction {
        return {
            actionType: ActionType.SetHours,
            value,
        };
    },
    setNotes(value: string | null): SetNotesAction {
        return {
            actionType: ActionType.SetNotes,
            value,
        };
    },
    setIncludeInReport(value: boolean): SetIncludeInReportAction {
        return {
            actionType: ActionType.SetIncludeInReport,
            value,
        };
    },
    setSubjects(value: Array<TrackTireInspection>): SetSubjectsAction {
        return {
            actionType: ActionType.SetSubjects,
            value,
        };
    },
    addSubjectForAxleAndSide(
        axle: string,
        side: AxleAlignment,
        equipmentId: string,
    ): AddSubjectToAxleAndSideAction {
        return {
            actionType: ActionType.AddSubjectForAxleAndSide,
            axle,
            side,
            equipmentId,
        };
    },
    setSelectedSubject(subject: TrackTireInspection | null): SetSelectedSubjectAction {
        return {
            actionType: ActionType.SetSelectedSubject,
            value: subject,
        };
    },
    setSpecs(specs: CopySpecs | null): SetSpecsAction {
        return {
            actionType: ActionType.SetSpecs,
            value: specs,
        };
    },
    setInitialized(): SetInitializedAction {
        return { actionType: ActionType.SetInitialized };
    },
    setPendingSave(pending: boolean): SetPendingSaveAction {
        return {
            actionType: ActionType.SetPendingSave,
            value: pending,
        };
    },
    addNewAxle(assetId: string): AddNewAxleAction {
        return {
            actionType: ActionType.AddNewAxle,
            equipmentId: assetId,
        };
    },
};
