import React, { useMemo } from 'react';
import SplitView from '../../controls/SplitView';
import resetEmployeePassword from '../../state/application/thunks/reset-employee-password';
import Employees from '../../views/Employees';
import EmployeeDetail from '../../views/Employees/EmployeeDetail';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    PageFilters,
} from '../../types';
import { actionCreators } from '../../state/application';
import Loading from '../../views/Loading';

export default function EmployeeDetailConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleError,
    } = props;
    const state = getState();

    const pageFilters: PageFilters = useMemo(() => ({
        ...state.viewState.employees,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setEmployeesViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.employees]);

    if (loading.clients
        || loading.assignments
        || loading.equipment
        || loading.inspections
        || loading.employees
        || loading.locations
    ) {
        return <Loading />;
    }

    return (
        <>
            <SplitView
                className="employeesList"
                leftPane={(
                    <Employees
                        loading={loading}
                        getApplicationState={getState}
                        employees={state.employees as Array<Employee>}
                        assignments={state.assignments as Array<Assignment>}
                        match={match}
                        pageFilters={pageFilters}
                    />
                )}
                rightPane={(
                    <EmployeeDetail
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        employees={state.employees as Array<Employee>}
                        locations={state.locations as Array<Geolocation>}
                        onError={handleError}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        resetPassword={resetEmployeePassword(dispatch, getState)}
                    />
                )}
            />
        </>
    );
}
