import React from 'react';
import RenderIf from './RenderIf';
import tryIdentifyParentRoute from '../utils/try-identify-parent-route';
import { State } from '../state/application';

interface Props {
    getApplicationState: () => State,
    children: React.ReactNode,
    title?: string | undefined,
    hideBackButton?: boolean,
}

export default function AppHeader(props: Props) {
    const {
        children, title, getApplicationState, hideBackButton,
    } = props;

    const appEl = document.querySelector('.App');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function AppScrollTop() {
        // @ts-ignore
        appEl.scrollTo({ top: -10, behavior: 'smooth' });
    }

    const parentRoute = tryIdentifyParentRoute(getApplicationState())?.route;
    const history = getApplicationState().appHistory;

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <section
                className="app-header"
                // @ts-ignore
                onClick={AppScrollTop}
            >
                <RenderIf condition={!hideBackButton && Boolean(parentRoute)}>
                    <button className="icn-back" type="button" onClick={() => history.push(parentRoute ?? '')}>
                        Back
                    </button>
                </RenderIf>
                <RenderIf condition={Boolean(children)}>{children}</RenderIf>

                <RenderIf condition={Boolean(title)}>
                    <div className="title">
                        <span>{title}</span>
                    </div>
                </RenderIf>

            </section>
        </>
    );
}
AppHeader.defaultProps = { title: undefined, hideBackButton: false };
