import { partition } from 'ramda';
import { AxlePosition, TrackTireInspection } from '../../../types';
import { createEmptyTireInspection } from '../../../types/constructors';
import shiftSubjectsInward from '../../../utils/shift-subjects-inward';
import { AddSubjectToAxleAndSideAction, State } from '../index';

export default function addSubjectToAxleAndSide(
    state: State,
    action: AddSubjectToAxleAndSideAction,
) {
    const [matchingSideSubjects, otherSubjects] = partition<TrackTireInspection>(
        (subject) => subject.axle === action.axle
            && subject.position.alignment === action.side,
    )(state.subjects);

    const newSubject = {
        ...createEmptyTireInspection(),
        equipmentId: action.equipmentId,
        axle: action.axle,
        position: {
            alignment: action.side,
            axlePosition: AxlePosition.Outside,
        },
    };
    const shiftedSubjects = matchingSideSubjects.map(shiftSubjectsInward(matchingSideSubjects));
    const subjects = [
        newSubject,
        ...shiftedSubjects,
        ...otherSubjects,
    ];

    return {
        ...state,
        pendingSave: true,
        subjects,
    };
}
