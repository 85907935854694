import React from 'react';
import {
    Category, EquipmentAsset, Geolocation, Inspection,
} from '../../types';
import InspectedAsset from './InspectedAsset';

interface InspectionCategoryProps {
    categories: Array<Category>,
    category: Category,
    inspections: Array<Inspection>,
    equipment: Array<EquipmentAsset>,
    locations: Array<Geolocation>,
    photos: Record<string, Blob>,
}

export default function InspectionCategory(props: InspectionCategoryProps) {
    const {
        categories,
        category,
        inspections,
        equipment,
        locations,
        photos,
    } = props;

    const inspectionItems = inspections
        .filter((item) => item.includeInReport)
        .filter((item) => {
            const asset = equipment.find((sub) => sub.id === item.equipmentId);

            return asset?.categoryId === category.id;
        })
        .map((item) => {
            const asset = equipment.find((sub) => sub.id === item.equipmentId) as EquipmentAsset;
            const assetPhoto = asset.primaryImageId ? photos[asset.primaryImageId] : null;

            return (
                <InspectedAsset
                    locations={locations}
                    asset={asset}
                    inspection={item}
                    assetPhoto={assetPhoto}
                    photos={photos}
                />
            );
        });

    if (!inspectionItems.length) {
        return null;
    }

    const categoryBreadcrumbs = [category.pluralName];
    let currCategory = category;

    function getParentCategory(newId: string) {
        const lookup = categories.find((cat) => cat.id === newId);

        if (lookup === undefined) {
            throw new TypeError('The value was promised to always be there!');
        }

        return lookup;
    }

    function walkBreadcrumbs() {
        if (currCategory.parentId) {
            currCategory = getParentCategory(currCategory.parentId);
            categoryBreadcrumbs.unshift(currCategory.pluralName);
            walkBreadcrumbs();
        }
    }

    walkBreadcrumbs();

    return (
        <>
            <div className="header">{categoryBreadcrumbs.join(' > ')}</div>
            {inspectionItems}
        </>
    );
}
