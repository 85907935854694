import {
    Assignment,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    Inspection,
    Geolocation, ApiErrorType, ApiError,
} from '../../types';
import { API_BASE_URI } from '../../constants';
import inspectionsFromDtoEntities from '../../utils/inspections-from-dto-entities';

export default class ShareServices {
    slug: string;

    constructor(slug: string) {
        this.slug = slug;
    }

    static getCategories(): Promise<Array<Category>> {
        return fetch(
            `${API_BASE_URI}/categories`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getClient(): Promise<Client> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/client`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getInspector(): Promise<Employee> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/inspector`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getAssignment(): Promise<Assignment> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/assignment`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getEquipment(): Promise<Array<EquipmentAsset>> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/equipment`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getInspections(): Promise<Array<Inspection>> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/inspections`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .then(inspectionsFromDtoEntities)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getPhoto(id: string): Promise<Blob> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/photos/${id}`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Failed to retrieve image by ID');
                }
                return resp.blob();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getLocation(id: string): Promise<Geolocation> {
        return fetch(
            `${API_BASE_URI}/reports/${this.slug}/locations/${id}`,
            { method: 'GET' },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }
}
