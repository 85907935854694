import { AxlePosition, TrackTireInspection } from '../types';

export default function shiftSubjectsInward(matchingSideSubjects: TrackTireInspection[]) {
    return (subject: TrackTireInspection) => {
        if (matchingSideSubjects.length === 1) {
            return {
                ...subject,
                position: {
                    ...subject.position,
                    axlePosition: AxlePosition.Inside,
                },
            };
        }
        if (matchingSideSubjects.length === 2
            && subject.position.axlePosition === AxlePosition.Outside) {
            return {
                ...subject,
                position: {
                    ...subject.position,
                    axlePosition: AxlePosition.Middle,
                },
            };
        }
        return subject;
    };
}
