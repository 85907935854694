/* eslint-disable prefer-object-spread */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { ReactNode } from 'react';
import classNames from 'classnames';

interface Props {
    className: string,
    leftPane: ReactNode,
    rightPane: ReactNode,
}

interface PaneProps {
    children: ReactNode,
    className?: any,
    paneSide: string,
}

const Pane = (props: PaneProps) => {
    const { paneSide, className } = props;

    const PaneChildren = props.children && React.Children.map(props.children, (child: ReactNode) => {
    	const newChild = Object.assign({}, child);

        // @ts-ignore
        newChild.props = { ...child.props, paneSide, className: `${child.props.className} ${className}` };
    	return <>{newChild}</>;
    });

    return (
        <div className={props.className}>
            {PaneChildren}
        </div>
    );
};

export default function SplitView(props: Props) {
    const { leftPane, rightPane, className } = props;

    return (
        <div className={classNames(className, 'hasDetail')}>
            <Pane className="leftSide" paneSide="left">
                {leftPane}
            </Pane>
            <Pane className="rightSide" paneSide="right">
                {rightPane}
            </Pane>
        </div>
    );
}

Pane.defaultProps = { className: '' };
