import React, { useMemo } from 'react';
import SplitView from '../../controls/SplitView';
import deleteAssignment from '../../state/application/thunks/delete-assignment';

import upsertAssignment from '../../state/application/thunks/upsert-assignment';
import upsertAssignmentShare from '../../state/application/thunks/upsert-assignment-share';
import upsertClientContact from '../../state/application/thunks/upsert-client-contact';
import Assignments from '../../views/Assignments';
import AssignmentDetail from '../../views/Assignments/AssignmentDetail';
import deleteAssignmentShare from '../../state/application/thunks/delete-assignment-share';
import deleteClientContact from '../../state/application/thunks/delete-client-contact';
import { AppComponentProps } from '../route-wrapper';
import { actionCreators } from '../../state/application';
import {
    Assignment,
    AssignmentShare,
    Category,
    Client,
    ClientContact,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    StatusFilter,
} from '../../types';
import Loading from '../../views/Loading';
import upsertInspection from '../../state/application/thunks/upsert-inspection';

export default function AssignmentDetailConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleError,
    } = props;
    const state = getState();

    const assignmentsPageFilters = useMemo(() => ({
        ...state.viewState.assignments,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setAssignmentsViewStateSearch(search),
        ),
        onDateStartChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateStart(date),
        ),
        onDateEndChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateEnd(date),
        ),
        onStatusFilterChanged: (filter: StatusFilter) => dispatch(
            actionCreators.setAssignmentsViewStateFilter(filter),
        ),
    }), [dispatch, state.viewState.assignments]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.categories
        || loading.assignmentShares
        || loading.clientContacts
        || loading.employees

        || loading.currentUser
    ) {
        return <Loading />;
    }

    const handleInspectionUpdate = (inspection: Inspection) => {
        state.apiServices.updateInspection(inspection)
            .then(upsertInspection(dispatch, getState));
    };

    return (
        <>
            <SplitView
                className="assignmentsList"
                leftPane={(
                    <Assignments
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        pageFilters={assignmentsPageFilters}
                    />
                )}
                rightPane={(
                    <AssignmentDetail
                        onError={handleError}
                        categories={state.categories as Array<Category>}
                        assignmentShares={state.assignmentShares as Array<AssignmentShare>}
                        clientContacts={state.clientContacts as Array<ClientContact>}
                        employees={state.employees as Array<Employee>}
                        apiServices={state.apiServices}
                        onInspectionUpdating={handleInspectionUpdate}
                        onAssignmentUpdated={upsertAssignment(dispatch, getState)}
                        onAssignmentDeleted={deleteAssignment(dispatch, getState)}
                        onContactAdded={upsertClientContact(dispatch, getState)}
                        onContactUpdated={upsertClientContact(dispatch, getState)}
                        onContactRemoved={deleteClientContact(dispatch, getState)}
                        onShareAdded={upsertAssignmentShare(dispatch, getState)}
                        onShareRemoved={deleteAssignmentShare(dispatch, getState)}
                        onShareUpdated={upsertAssignmentShare(dispatch, getState)}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        inspections={state.inspections as Array<Inspection>}
                        loading={loading}
                        getApplicationState={getState}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        match={match}
                    />
                )}
            />
        </>
    );
}
