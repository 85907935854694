import { AxleAlignment, AxlePosition, TrackTireInspection } from '../types';

export default function generateAxleSortOrdinal(subject: TrackTireInspection) {
    const { alignment, axlePosition } = subject.position;
    const sideFactor = alignment === AxleAlignment.Left as string
        ? -1
        : 1;

    const mm = subject.axle?.match(/^Axle (\d+)$/) || [];
    const axleNumber = parseInt(mm[1] || '0', 10);

    const axleAdjustment = axleNumber * 1000;

    let value;
    switch (axlePosition) {
        case AxlePosition.Outside as string:
            value = 100;
            break;
        case AxlePosition.Middle as string:
            value = 10;
            break;
        case AxlePosition.Inside as string:
            value = 1;
            break;
        default:
            value = 0;
            break;
    }

    return axleAdjustment + sideFactor * value;
}
