import classNames from 'classnames';
import React from 'react';
import {
    SubjectCondition,
    TireInspection,
    TrackInspection,
    TrackTireInspection,
    TrackTireType,
} from '../../types';
import identifyInspectionSubjectType from '../../utils/identify-inspection-subject-type';
import formatSubjectPosition from '../../utils/format-subject-position';
import { RenderIf } from '../../controls';
import Photo from '../../controls/Photo';

interface SubjectProps {
    subject: TrackTireInspection,
    subjectCount: number,
    photos: Record<string, Blob>,
}

export default function Subject(props: SubjectProps) {
    const { subject, photos, subjectCount } = props;
    const subjectType = identifyInspectionSubjectType(subject);

    const subjectAsTire = subject as TireInspection;
    const subjectAsTrack = subject as TrackInspection;

    const photosList = subject.images.map((imageId) => {
        if (photos[imageId]) {
            return <Photo blob={photos[imageId]} />;
        }
        return null;
    });

    return (
        <div className={classNames('subject', {
            tire: subjectType === TrackTireType.Tire,
            track: subjectType === TrackTireType.Track,
        })}
        >
            <h3>{formatSubjectPosition(subject.position, subjectCount)} {TrackTireType[subjectType]}</h3>
            <dl className="subjectAttributes">
                <dt>Brand:</dt>
                <dd>{subject.brand}</dd>

                <dt>Size:</dt>
                <dd>{subject.size}</dd>

                <dt>Year:</dt>
                <dd>{subject.year}</dd>
            </dl>

            <RenderIf condition={subjectType === TrackTireType.Tire}>
                <dl className="subjectInspectionResults">
                    { /* At this time, we have no means of knowing how tread depth translates to condition */ }
                    <dt>Tread Depth:</dt>
                    <dd>{subject.treadDepth}</dd>

                    <dt>
                        <div className={classNames('severity', SubjectCondition[subjectAsTire.inflationCondition])} />
                        Inflation:
                    </dt>
                    <dd>{subjectAsTire.inflationConditionNotes}</dd>

                    <dt>
                        <div className={classNames('severity', SubjectCondition[subjectAsTire.tireCondition])} />
                        Tire Condition:
                    </dt>
                    <dd>{subjectAsTire.tireConditionNotes}</dd>

                    <dt>
                        <div className={classNames('severity', SubjectCondition[subjectAsTire.wheelCondition])} />
                        Wheel Condition:
                    </dt>
                    <dd>{subjectAsTire.wheelConditionNotes}</dd>
                </dl>
            </RenderIf>
            <RenderIf condition={subjectType === TrackTireType.Track}>
                <dl className="subjectInspectionResults">
                    { /* At this time, we have no means of knowing how tread depth translates to condition */ }
                    <dt>Tread Depth:</dt>
                    <dd>{subject.treadDepth}</dd>

                    { /* At this time, we have no means of knowing how alignment translates to condition */ }
                    <dt>Alignment:</dt>
                    <dd>{subjectAsTrack.alignment}</dd>

                    <dt>
                        <div className={classNames('severity', SubjectCondition[subjectAsTrack.trackCondition])} />
                        Track Condition:
                    </dt>
                    <dd>{subjectAsTrack.trackConditionNotes}</dd>

                    <dt>
                        <div className={classNames('severity', SubjectCondition[subjectAsTrack.undercarriageCondition])} />
                        Undercarriage Condition:
                    </dt>
                    <dd>{subjectAsTrack.undercarriageConditionNotes}</dd>
                </dl>
            </RenderIf>

            <ul className="photos">
                {photosList}
            </ul>
        </div>
    );
}
