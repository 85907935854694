import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Unset } from '../../../constants';

export default function unload(dispatch: Dispatch<Action>, getState: () => State) {
    return () => {
        getState().apiServices.logout();

        dispatch(actionCreators.setInitialized(Unset));
        dispatch(actionCreators.setCurrentUser(Unset));
    };
}
