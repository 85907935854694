import React, { useMemo } from 'react';
import ClientFleet from '../../views/Clients/ClientFleet';
import loadPhoto from '../../state/application/thunks/load-photo';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment,
    Category,
    Client,
    EquipmentAsset,
    FleetPageFilters,
    FleetPageType,
    Geolocation,
    Inspection,
} from '../../types';
import { actionCreators } from '../../state/application';
import Loading from '../../views/Loading';

export default function LocationFleetConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleWarning,
    } = props;
    const state = getState();

    const fleetPageFilters: FleetPageFilters = useMemo(() => ({
        ...state.viewState.fleet,
        onPageTypeChanged: (pageType: FleetPageType) => dispatch(
            actionCreators.setFleetViewStatePageType(pageType),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setFleetViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.fleet]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.categories
    ) {
        return <Loading />;
    }

    return (
        <>
            <ClientFleet
                photosCache={state.photosRepo.cache}
                loadPhoto={(id: string) => loadPhoto(dispatch, getState)(id, handleWarning)}
                getApplicationState={getState}
                clients={state.clients as Array<Client>}
                assignments={state.assignments as Array<Assignment>}
                locations={state.locations as Array<Geolocation>}
                equipment={state.equipment as Array<EquipmentAsset>}
                inspections={state.inspections as Array<Inspection>}
                categories={state.categories as Array<Category>}
                loading={loading}
                match={match}
                pageFilters={fleetPageFilters}
            />
        </>
    );
}
