import React, { Dispatch, useMemo } from 'react';
import { match, Route } from 'react-router-dom';

import { ExtractRouteParams } from 'react-router';
import { Scroller, AppFooter } from '../controls';
import performFooterNavigation from '../state/application/thunks/perform-footer-navigation';
import { Action, State } from '../state/application';
import { LoadingState, Logger } from '../types';
import { Loading, Unset } from '../constants';

export interface Routable {
    getState: () => State,
    dispatch: Dispatch<Action>,
    match: match<ExtractRouteParams<string, string>>,
    handleError: Logger,
    handleWarning: Logger,
    handleInformation: Logger,
}

export interface AppComponentProps extends Routable {
    loading: LoadingState,
}

export interface RouteHandlerProps extends Routable {
    component: AppComponent,
}

export type AppComponent = React.FC<AppComponentProps>;

interface RouteProps {
    route: string,
    dispatch: Dispatch<Action>,
    getState: () => State,
    condition?: boolean | undefined,
    component: AppComponent,
    handleError: Logger,
    handleWarning: Logger,
    handleInformation: Logger,
}

export function RouteHandler(props: RouteHandlerProps) {
    const {
        dispatch,
        getState,
        handleWarning,
        handleError,
        handleInformation,
        match: routeMatch,
        component: InnerComponent,
    } = props;
    const state = getState();

    const currentUserIsLoading = state.currentUser === Loading || state.currentUser === Unset;
    const loading = useMemo(() => {
        const loadingState: LoadingState = {
            recentClients: false,
            makes: state.makes === Loading || state.makes === Unset,
            employees: state.employees === Loading || state.employees === Unset,
            equipment: state.equipment === Loading || state.equipment === Unset,
            clients: state.clients === Loading || state.clients === Unset,
            assignments: state.assignments === Loading || state.assignments === Unset,
            locations: state.locations === Loading || state.locations === Unset,
            inspections: state.inspections === Loading || state.inspections === Unset,
            categories: state.categories === Loading || state.categories === Unset,
            assignmentShares: state.assignmentShares === Loading || state.assignmentShares === Unset,
            clientContacts: state.clientContacts === Loading || state.clientContacts === Unset,
            currentUser: currentUserIsLoading,
        };

        return loadingState;
    }, [state.makes, state.employees, state.equipment, state.clients, state.assignments, state.locations, state.inspections, state.categories, state.assignmentShares, state.clientContacts, currentUserIsLoading]);

    return (
        <>
            <Scroller history={state.appHistory} />

            <InnerComponent
                getState={getState}
                dispatch={dispatch}
                match={routeMatch}
                loading={loading}
                handleWarning={handleWarning}
                handleError={handleError}
                handleInformation={handleInformation}
            />

            <AppFooter
                performNavigation={performFooterNavigation(dispatch, getState)}
                loading={loading}
                inspections={state.inspections}
                assignments={state.assignments}
                currentUser={state.currentUser}
                location={state.appHistory.location}
            />
        </>
    );
}

export default function RouteWrapper(props: RouteProps) {
    const {
        route,
        dispatch,
        getState,
        condition,
        handleError,
        handleWarning,
        handleInformation,
        component: InnerComponent,
    } = props;

    // If not specified, assume we proceed
    if (!condition ?? true) {
        return null;
    }

    return (
        <Route
            path={route}
            render={({ match: innerMatch }) => (
                <RouteHandler
                    match={innerMatch}
                    component={InnerComponent}
                    dispatch={dispatch}
                    getState={getState}
                    handleError={handleError}
                    handleWarning={handleWarning}
                    handleInformation={handleInformation}
                />
            )}
        />
    );
}
RouteWrapper.defaultProps = { condition: undefined };
