import React from 'react';
import { InspectionStatus } from '../types';
import { INSPECTION_STATUS_DISPLAY_NAMES } from '../constants';

export default function getInspectionStatusOptions() {
    const entries = Object.entries(InspectionStatus)
        .filter(([, value]) => !Number.isNaN(parseInt(value as string, 10)))
        .map(([, value]) => (
            <option key={value} value={value}>
                {INSPECTION_STATUS_DISPLAY_NAMES[value as InspectionStatus]}
            </option>
        ));

    return <>{entries}</>;
}
