import React, { useMemo } from 'react';
import InspectionDetail from '../views/InspectionDetail';
import deleteInspection from '../state/application/thunks/delete-inspection';
import SplitView from '../controls/SplitView';
import ClientFleet from '../views/Clients/ClientFleet';
import loadPhoto from '../state/application/thunks/load-photo';
import { AppComponentProps } from './route-wrapper';
import {
    Assignment, Category,
    Client,
    EquipmentAsset,
    FleetPageFilters,
    Geolocation,
    Inspection,
    InspectionStatus,
    UserLogin,
} from '../types';
import { actionCreators } from '../state/application';
import Loading from '../views/Loading';
import upsertInspection from '../state/application/thunks/upsert-inspection';

export default function InspectionDetailConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleError,
        handleWarning,
        handleInformation,
    } = props;
    const state = getState();

    const fleetPageFilters: FleetPageFilters = useMemo(() => ({
        ...state.viewState.fleet,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setFleetViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.fleet]);

    const onInspectionUpdated = (inspection: Inspection) => {
        const existingInspection = (getState().inspections as Array<Inspection>)
            .find((item) => item.id === inspection.id);
        const existingStatus = existingInspection?.status;

        upsertInspection(dispatch, getState)(inspection);

        if (existingStatus !== inspection.status && inspection.status === InspectionStatus.SubmittedForReview) {
            handleInformation('The inspection has been marked as Completed.');
        }
    };
    const handleImageDeleted = (imageId: string) => dispatch(actionCreators.deletePhotoFromCache(imageId));
    const handlePhotoRequested = (id: string) => loadPhoto(dispatch, getState)(id, handleWarning);
    const handlePhotosAdded = (photos: Record<number, string>) => dispatch(actionCreators.addPhotosToRepo(photos));

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.categories
        || loading.currentUser
    ) {
        return <Loading />;
    }

    return (
        <>
            <SplitView
                className="clientsList--inspectionDetail"
                leftPane={(
                    <ClientFleet
                        photosCache={state.photosRepo.cache}
                        loadPhoto={handlePhotoRequested}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        categories={state.categories as Array<Category>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        pageFilters={fleetPageFilters}
                    />
                )}
                rightPane={(
                    <InspectionDetail
                        photosCache={state.photosRepo.cache}
                        onPhotosAdded={handlePhotosAdded}
                        onPhotoRequested={handlePhotoRequested}
                        onImageDeleted={handleImageDeleted}
                        currentUser={state.currentUser as UserLogin}
                        handleError={handleError}
                        apiServices={state.apiServices}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        loading={loading}
                        match={match}
                        getApplicationState={getState}
                        onInspectionUpdated={onInspectionUpdated}
                        onInspectionDeleted={deleteInspection(dispatch, getState)}
                    />
                )}
            />
        </>
    );
}
