import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Assignment } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function upsertAssignment(dispatch: Dispatch<Action>, getState: () => State) {
    return (assignment: Assignment) => {
        const { assignments } = getState();

        if (assignments === Unset || assignments === Loading) {
            return;
        }

        dispatch(actionCreators.setAssignments([
            ...(assignments as Array<Assignment>).filter(
                (item) => item.id !== assignment.id,
            ),
            assignment,
        ]));
    };
}
