export enum BackgroundLoadType {
    Full,
    Recent,
}

export enum BackgroundLoadState {
    NeverRun,
    Running,
    Errored,
}

export type BackgroundLoad = Record<BackgroundLoadType, BackgroundLoadState | number>;

export enum TopLevelPage {
    Dashboard,
    Clients,
    Assignments,
    Employees,
    Settings,
}

export enum FleetPageType {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Client,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    Category,
    Location,
}

export enum Severity {
    // Not submitted, all inspections completed
    Nominal,

    // One or more pieces of equipment pending
    Warning,

    // One or more pieces of equipment in error
    Critical,

    Ignore,
}

/**
 * This interface represents any entity that can be created on the client-side,
 * but will be assigned an integer-based ID by the server on creation/retrieval.
 */
export interface Identifiable {
    id?: string,
}

export interface Category extends Identifiable {
    slug: string,
    singularName: string,
    pluralName: string,
    shortName: string,
    parentId: string | null,
}

export enum UserSetting {
    recentUpdatesPollingPeriod,
    fullUpdatesPollingPeriod,
}

export interface UserSettings {
    userSettings: { [key in keyof typeof UserSetting]?: any },
}

export interface UserLogin extends Identifiable {
    name: string,
    username: string,
    role: string,
}

export interface User extends UserLogin, UserSettings {
}

export enum LdapLoginType {
    Disabled,
    Enabled,
    Required,
}

export interface Employee extends UserLogin {
    email: string,
    phoneNumber: string,
    ldapUsername: string | null,
    ldapLoginType: LdapLoginType,
}

export interface CreateEmployeeDto extends Employee {
    password: string,
}

export interface CfiEntity {
    createdOn?: string,
}

/**
 * This interface indicates that the entity exists in the context of a single client,
 * and references that client by ID.
 */
export interface OwnedByClient {
    clientId: string,
}

export interface Geolocation extends Identifiable, OwnedByClient {
    lat?: number | undefined | null,
    lng?: number | undefined | null,
    name: string,
    street1?: string,
    street2?: string,
    city?: string,
    state?: string,
    postalCode: string,
    notes?: string,
}

export interface Client extends Identifiable {
    name: string,

    // Identifies the primary location (headquarters) for the client.
    locationId?: string,
    phoneNumber: string | null,
    email: string | null,
}

export interface ClientUpdateRequest extends Identifiable {
    client: Client,
    location: Geolocation,
}

export interface EquipmentAsset extends Identifiable, OwnedByClient {
    name: string,
    categoryId: string | null,

    make: string | null,
    model: string | null,
    vin: string | null,
    internalIdentifier: string | null,
    year: number | null,
    horsepower: number | null,

    /**
     * If known, references the most recently identified geolocation by ID.
     */
    currentLocationId: string | null,
    primaryImageId: string | null,
    vinImageId: string | null,
    axles: Array<TrackTireType>,
}

export enum InspectionStatus {
    Open,
    SubmittedForReview,
    Closed,
    Errored,
}

export interface Assignment extends Identifiable, OwnedByClient {
    employeeId: string | null,
    reviewerId: string | null,
    notes: string | null,
    dueDate: string | null,
    completionDate: string | null,
    status: InspectionStatus,
}

export interface ClientContact extends Identifiable, OwnedByClient {
    emailAddress: string,
}

export interface AssignmentShare extends ClientContact {
    assignmentId: string,
    slug: string,
    sentOn: string | null,
}

export interface Inspection extends Identifiable, CfiEntity {
    assignmentId: string,
    equipmentId: string,
    employeeId: string | null,
    subjects: Array<TrackTireInspection>,
    condition: SubjectCondition,
    hours: number | null,
    notes: string | null,
    includeInReport: boolean,
    status: InspectionStatus,
}

export enum AxleAlignment {
    Left = 'Left',
    Right = 'Right',
}

export enum AxlePosition {
    Inside = 'Inside',
    Middle = 'Middle',
    Outside = 'Outside',
}

export interface SubjectPosition {
    alignment: AxleAlignment,
    axlePosition: AxlePosition,
}

export interface TrackTire {
    axle: string,
    position: SubjectPosition,
    brand: string | null,
    size: string | null,
    year: number | null,
}

export enum TrackTireType {
    Tire = 'Tire',
    Track = 'Track',
}

export interface TrackTireInspection extends TrackTire {
    // Status (Complete (green), Error (red), Closed (grey), In progress (yellow),
    // Complete w/out inspection (green))
    // Closed could be inferred based on context
    completionStatus: boolean,
    equipmentId: string,
    treadDepth: string | null,
    images: Array<string>,
}

export interface TireInspection extends TrackTireInspection {
    wheelPartNumber: string,
    inflationCondition: SubjectCondition,
    inflationConditionNotes: string,
    tireCondition: SubjectCondition,
    tireConditionNotes: string,
    wheelCondition: SubjectCondition,
    wheelConditionNotes: string,
}

export interface TrackInspection extends TrackTireInspection {
    alignment: string,
    trackCondition: SubjectCondition,
    trackConditionNotes: string,
    undercarriageCondition: SubjectCondition,
    undercarriageConditionNotes: string,
}

export enum SubjectCondition {
    Unknown,
    Good,
    Fair,
    Poor,
}

export enum ClientFilter {
    AllClients,
    Assigned,
    Unassigned,
}

export enum StatusFilter {
    Assigned,
    ForReview,
    Unassigned,
    Closed,
}

export interface PageFilterCriteria {
    search: string,
    selection: string | null,
}
export interface PageFilters extends PageFilterCriteria {
    onSearchChanged: (search: string) => void,
}

export interface FleetPageFilterCriteria extends PageFilterCriteria {
    pageType: FleetPageType | null,
    selectedAsset: string | null,
}

export interface FleetPageFilters extends FleetPageFilterCriteria, PageFilters {
}

export interface ClientsPageFilterCriteria extends PageFilterCriteria {
    filter: ClientFilter,
    selectedEquipmentCategory: number | null,
    selectedLocation: number | null,
}

export interface ClientsPageFilters extends ClientsPageFilterCriteria, PageFilters {
    onFilterChanged: (filter: ClientFilter) => void,
}

export interface AssignmentsPageFilterCriteria extends PageFilterCriteria {
    dateStart: Date | null,
    dateEnd: Date | null,
    statusFilter: StatusFilter,
}

export interface AssignmentsPageFilters extends AssignmentsPageFilterCriteria, PageFilters {
    onDateStartChanged: (date: Date | null) => void,
    onDateEndChanged: (date: Date | null) => void,
    onStatusFilterChanged: (filter: StatusFilter) => void,
}

export interface ShareTarget {
    name: string | null,
    emailAddress: string,
    contactId: string | null,
    shareId: string | null,
    hasShared: boolean,
}

export enum ApiErrorType {
    Unknown,
    FetchFailure,
    InvalidLogin,
}
export class ApiError extends Error {
    type: ApiErrorType;

    constructor(message: string, type?: ApiErrorType) {
        super(message);
        this.type = type ?? ApiErrorType.Unknown;
    }
}

export interface AppMessage {
    message: AppMessages,
    payload?: unknown,
}
export enum AppMessages {
    ServiceWorkerSuccess,
    ServiceWorkerUpdate,
    ServiceWorkerShouldUnregister,
}

export interface LoadingState {
    makes: boolean,
    currentUser: boolean,
    recentClients: boolean,
    clients: boolean,
    assignments: boolean,
    clientContacts: boolean,
    assignmentShares: boolean,
    equipment: boolean,
    inspections: boolean,
    locations: boolean,
    categories: boolean,
    employees: boolean,
}

export type Logger = (message: string) => void;

export interface ClientUpdatePayload {
    clientId: string | null,
    lng: number | null,
    city: string | null,
    postalCode: string,
    street1: string | null,
    street2: string | null,
    state: string | null,
    lat: number | null,
    name: string | null,
    email: string | null,
    phoneNumber: string | null,
}

export interface PhotosRepo {
    cache: Record<string, string | symbol>,
    pending: Array<string>,
}
