import React, { useMemo } from 'react';
import Employees from '../../views/Employees';
import { Assignment, Employee, PageFilters } from '../../types';
import { actionCreators } from '../../state/application';
import Loading from '../../views/Loading';
import { AppComponentProps } from '../route-wrapper';

export default function AllEmployeesConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
    } = props;
    const state = getState();

    const employeesPageFilters: PageFilters = useMemo(() => ({
        ...state.viewState.employees,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setEmployeesViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.employees]);

    if (loading.assignments || loading.employees) {
        return <Loading />;
    }

    return (
        <>
            <Employees
                loading={loading}
                getApplicationState={getState}
                employees={state.employees as Array<Employee>}
                assignments={state.assignments as Array<Assignment>}
                pageFilters={employeesPageFilters}
                match={match}
            />
        </>
    );
}
