import React from 'react';

interface AppWrapperProps {
    children: React.ReactChildren | React.ReactNode,
    className?: string | null
}

export default function AppWrapper(props: AppWrapperProps): React.ReactElement {
    const { children, className } = props;

    return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div tabIndex={0} className={`${className} App`}>
            {children}
        </div>
    );
}

AppWrapper.defaultProps = { className: '' };
