import {
    Assignment,
    AssignmentShare,
    AssignmentsPageFilterCriteria,
    AxleAlignment,
    AxlePosition,
    ClientContact,
    ClientFilter,
    ClientsPageFilterCriteria,
    ClientUpdatePayload,
    ClientUpdateRequest,
    Employee,
    FleetPageFilterCriteria,
    FleetPageType,
    Inspection,
    InspectionStatus,
    LdapLoginType,
    PageFilterCriteria,
    StatusFilter,
    SubjectCondition,
    SubjectPosition,
    TireInspection,
    TrackInspection,
} from './index';
import { UNKNOWN } from '../constants';

export function makeUuid() {
    const blobUrl = window.URL.createObjectURL(new Blob([]));

    return blobUrl.substring(blobUrl.length - 36);
}

export function createAssignment(
    clientId: string,
    employeeId: string,
): Assignment {
    return {
        clientId,
        completionDate: null,
        dueDate: new Date().toJSON(),
        employeeId,
        reviewerId: null,
        notes: null,
        status: InspectionStatus.Open,
    };
}

export function createInspection(
    employeeId: string,
    assetId: string,
    assignmentId: string,
): Inspection {
    return {
        condition: SubjectCondition.Unknown,
        hours: null,
        includeInReport: false,
        notes: null,
        status: InspectionStatus.Open,
        subjects: [],
        equipmentId: assetId,
        assignmentId,
        employeeId,
    };
}

export function createSubjectPosition(): SubjectPosition {
    return {
        alignment: AxleAlignment.Left,
        axlePosition: AxlePosition.Outside,
    };
}

export function createEmptyTrackTireInspection(): TireInspection & TrackInspection {
    return {
        completionStatus: false,
        equipmentId: UNKNOWN,
        images: [],
        alignment: '',
        axle: '',
        brand: null,
        position: {
            axlePosition: AxlePosition.Outside,
            alignment: AxleAlignment.Left,
        },
        size: null,
        inflationCondition: SubjectCondition.Unknown,
        inflationConditionNotes: '',
        tireCondition: SubjectCondition.Unknown,
        tireConditionNotes: '',
        trackCondition: SubjectCondition.Unknown,
        trackConditionNotes: '',
        treadDepth: null,
        undercarriageCondition: SubjectCondition.Unknown,
        wheelCondition: SubjectCondition.Unknown,
        undercarriageConditionNotes: '',
        wheelConditionNotes: '',
        wheelPartNumber: '',
        year: null,
    };
}

export function createEmptyTireInspection(): TireInspection {
    return {
        completionStatus: false,
        equipmentId: UNKNOWN,
        axle: '',
        brand: null,
        images: [],
        position: createSubjectPosition(),
        size: null,
        inflationCondition: SubjectCondition.Unknown,
        inflationConditionNotes: '',
        tireCondition: SubjectCondition.Unknown,
        tireConditionNotes: '',
        treadDepth: null,
        wheelCondition: SubjectCondition.Unknown,
        wheelConditionNotes: '',
        wheelPartNumber: '',
        year: null,
    };
}

export function createEmptyEmployee(): Employee {
    return {
        name: '',
        role: 'user',
        username: '',
        email: '',
        phoneNumber: '',
        ldapUsername: null,
        ldapLoginType: LdapLoginType.Disabled,
    };
}

export function getInitialSearchFilter(): PageFilterCriteria {
    return {
        search: '',
        selection: null,
    };
}
export function getInitialClientsPageFilter(): ClientsPageFilterCriteria {
    return {
        ...getInitialSearchFilter(),
        filter: ClientFilter.AllClients,
        selectedEquipmentCategory: null,
        selectedLocation: null,
    };
}

export function getInitialFleetPageFilter(): FleetPageFilterCriteria {
    return {
        ...getInitialSearchFilter(),
        pageType: FleetPageType.Client,
        selectedAsset: null,
    };
}

export function getInitialAssignmentsPageFilter(): AssignmentsPageFilterCriteria {
    return {
        ...getInitialSearchFilter(),
        dateStart: null,
        dateEnd: null,
        statusFilter: StatusFilter.Assigned,
    };
}

export function createNewClientContact(clientId: string): ClientContact {
    return {
        id: undefined,
        clientId,
        emailAddress: '',
    };
}

export function createNewAssignmentShare(clientId: string, assignmentId: string): AssignmentShare {
    return {
        ...createNewClientContact(clientId),
        assignmentId,
        slug: makeUuid(),
        sentOn: null,
    };
}

export function translateClientUpdateRequestFromPayload({ payload, id }: { id: string, payload: string }): ClientUpdateRequest {
    const data: ClientUpdatePayload = JSON.parse(payload);

    return {
        id,
        client: {
            id: data.clientId ?? UNKNOWN,
            name: data.name ?? '',
            email: data.email,
            phoneNumber: data.phoneNumber,
        },
        location: {
            clientId: data.clientId ?? UNKNOWN,
            name: '',
            notes: undefined,
            id: undefined,
            street1: data.street1 ?? undefined,
            street2: data.street2 ?? undefined,
            city: data.city ?? undefined,
            state: data.state ?? undefined,
            postalCode: data.postalCode,
            lat: data.lat,
            lng: data.lng,
        },
    };
}
