import Services from '../services';

export default function tryDeleteEquipment(
    apiServices: Services,
    onEquipmentDeleted: (assetId: string) => void,
    handleError: (message: string) => void,
) {
    return (assetId: string | null) => {
        // eslint-disable-next-line no-alert
        if (assetId && window.confirm('Are you sure you wish to remove this asset?')) {
            return apiServices.deleteEquipment(assetId)
                .then(() => onEquipmentDeleted(assetId))
                .catch((e) => handleError(e));
        }
        return Promise.reject();
    };
}
