import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Inspection } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function deleteInspection(dispatch: Dispatch<Action>, getState: () => State) {
    return (inspectionId: string) => {
        const { inspections } = getState();

        if (inspections === Unset || inspections === Loading) {
            return;
        }

        dispatch(actionCreators.setInspections((inspections as Array<Inspection>).filter(
            (item) => item.id !== inspectionId,
        )));
    };
}
