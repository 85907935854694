import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Client } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function upsertClient(dispatch: Dispatch<Action>, getState: () => State) {
    return (client: Client) => {
        const { clients } = getState();

        if (clients === Unset || clients === Loading) {
            return;
        }

        dispatch(actionCreators.setClients([
            ...(clients as Array<Client>).filter(
                (item) => item.id !== client.id,
            ),
            client,
        ]));
    };
}
