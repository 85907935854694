import React, {
    ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import Services from '../../services';
import { AssignmentShare, ClientContact } from '../../types';
import { ESC_KEY } from '../../constants';
import * as constructors from '../../types/constructors';
import { NamedInput } from '../../controls';

interface Props {
    apiServices: Services,
    assignmentId: string,
    clientId: string,
    onCancel: () => void,
    onContactAdded: (contact: ClientContact) => void,
    onShareAdded: (share: AssignmentShare) => void,
}

export default function AddContactDialog(props: Props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
        apiServices,
        onCancel,
        onContactAdded,
        onShareAdded,
        assignmentId,
        clientId,
    } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isPermanentContact, setIsPermanentContact] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<any>) => {
        if (event.key === ESC_KEY) {
            onCancel();
        }
    }, [onCancel]);

    const handleSubmission = useCallback((event: FormEvent) => {
        event.preventDefault();

        let contactCreation;
        const nameAndEmail = name
            ? `"${name}" <${email}>`
            : email;

        if (isPermanentContact) {
            // Create contact record
            contactCreation = apiServices.createClientContact({
                ...constructors.createNewClientContact(clientId),
                emailAddress: nameAndEmail,
            });

            contactCreation.then(onContactAdded);
        } else {
            contactCreation = Promise.resolve();
        }

        // Provision share
        contactCreation.then(() => apiServices.createAssignmentShare({
            ...constructors.createNewAssignmentShare(
                clientId,
                assignmentId,
            ),
            emailAddress: nameAndEmail,
        }))
            .then(onShareAdded)
            .then(onCancel);
    }, [
        apiServices,
        assignmentId,
        clientId,
        email,
        isPermanentContact,
        name,
        onCancel,
        onContactAdded,
        onShareAdded,
    ]);

    useEffect(() => {
        inputRef.current?.focus();
        // @ts-ignore
        window.addEventListener('keydown', handleKeyDown, true);

        return () => {
            // @ts-ignore
            window.removeEventListener('keydown', handleKeyDown, true);
        };
    }, [handleKeyDown]);

    return (
        <section
            role="dialog"
            className="dialog reviewSubmission"
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="clickRegion" onClick={onCancel} onKeyDown={handleKeyDown} />
            <main>
                <form onSubmit={handleSubmission}>
                    <h2>Add a Contact</h2>
                    <NamedInput
                        name="Name"
                        value={name}
                        label="Name"
                        type="text"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setName(evt.target.value)}
                    />
                    <NamedInput
                        name="EmailAddress"
                        value={email}
                        label="Email *"
                        type="email"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
                    />
                    <NamedInput
                        name="IsPermanentContact"
                        value={isPermanentContact}
                        label="Permanent Contact"
                        type="checkbox"
                        onChange={(evt: ChangeEvent<HTMLInputElement>) => setIsPermanentContact(evt.target.checked)}
                    />

                    <div className="buttons">
                        <button type="submit" disabled={!email}>Add</button>
                        <button type="button" onClick={onCancel} className="cancel">Cancel</button>
                    </div>
                </form>
            </main>
        </section>
    );
}
