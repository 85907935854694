import { sortBy } from 'ramda';
import classNames from 'classnames';
import React from 'react';
import generateAxleSortOrdinal from '../../utils/generate-axle-sort-ordinal';
import {
    AxleAlignment,
    SubjectCondition,
    TireInspection,
    TrackInspection,
    TrackTireInspection,
} from '../../types';
import formatSubjectPosition from '../../utils/format-subject-position';

interface AxleProps {
    isReadOnly: boolean,
    name: string,
    subjects: Array<TrackTireInspection>,
    selectedSubject: TrackTireInspection | null,
    setSelectedSubject: null | ((subject: TrackTireInspection) => void),
    addSubjectOnSide: (side: AxleAlignment) => void,
}

export default function Axle(props: AxleProps) {
    const {
        isReadOnly,
        name,
        subjects,
        selectedSubject,
        setSelectedSubject,
        addSubjectOnSide,
    } = props;

    const sortedSubjects = sortBy(generateAxleSortOrdinal, subjects);
    const leftSubjects = sortedSubjects.filter((subject) => subject.position.alignment === AxleAlignment.Left);
    const rightSubjects = sortedSubjects.filter((subject) => subject.position.alignment === AxleAlignment.Right);

    function outputSubjects(subject: TrackTireInspection, alignmentSubjectCount: number) {
        const positionName = formatSubjectPosition(subject.position, alignmentSubjectCount);

        if (!positionName) {
            throw new Error('Position is required for axle subject');
        }

        const clickHandler = setSelectedSubject
            ? () => setSelectedSubject(subject)
            : undefined;
        const condition = (subject as TrackInspection).trackCondition || (subject as TireInspection).tireCondition;

        return (
            <button
                type="button"
                key={positionName}
                data-position-alignment={subject.position.alignment}
                data-position-axle-position={subject.position.axlePosition}
                title={positionName}
                className={classNames(condition && `condition-${SubjectCondition[condition]}`, {
                    active: subject === selectedSubject || !clickHandler,
                    complete: subject.completionStatus,
                })}
                onClick={clickHandler}
            >
                {positionName}
            </button>
        );
    }

    const leftSubjectElements = leftSubjects.map((subj) => outputSubjects(subj, leftSubjects.length));
    const rightSubjectElements = rightSubjects.map((subj) => outputSubjects(subj, rightSubjects.length));

    const addLeft = leftSubjectElements.length < 3 && !isReadOnly
        ? (
            <button
                key="add-left"
                data-add-side="Left"
                type="button"
                title="Add tire"
                className="addSubject"
                onClick={() => addSubjectOnSide(AxleAlignment.Left)}
            >Add Tire
            </button>
        )
        : null;
    const addRight = rightSubjectElements.length < 3 && !isReadOnly
        ? (
            <button
                key="add-right"
                data-add-side="Right"
                type="button"
                title="Add tire"
                className="addSubject"
                // style={addSubjectStyle}
                onClick={() => addSubjectOnSide(AxleAlignment.Right)}
            >Add Tire
            </button>
        )
        : null;

    const subjectElements = [
        <div className="subject-axles-left">
            { addLeft }
            { leftSubjectElements }
        </div>,
        <span key="subject-axle-name" className="name">{name}</span>,
        <div className="subject-axles-right">
            { rightSubjectElements }
            { addRight }
        </div>,
    ];

    return (
        <div
            data-name={name}
            className="axle"
            key={name}
        >
            <div className="subjects">
                {subjectElements}
            </div>
        </div>
    );
}
