import React from 'react';
import deleteLocation from '../../state/application/thunks/delete-location';
import upsertLocation from '../../state/application/thunks/upsert-location';
import LocationEdit from '../../views/LocationEdit';
import { AppComponentProps } from '../route-wrapper';
import Loading from '../../views/Loading';
import { Client, Geolocation } from '../../types';

export default function EditClientLocationConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        handleError,
        match,
    } = props;
    const state = getState();

    if (loading.clients || loading.locations) {
        return <Loading />;
    }

    return (
        <>
            <LocationEdit
                match={match}
                clients={state.clients as Array<Client>}
                locations={state.locations as Array<Geolocation>}
                loading={loading}
                getApplicationState={getState}
                apiServices={state.apiServices}
                onLocationUpdated={upsertLocation(dispatch, getState)}
                onLocationDeleted={deleteLocation(dispatch, getState)}
                onError={handleError}
            />
        </>
    );
}
