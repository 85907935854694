import React, {
    ChangeEvent, useCallback, useEffect, useRef, useState,
} from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import {
    Assignment,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    InspectionStatus,
    Severity,
    UserLogin,
} from '../../types';
import {
    Actions, AppHeader, DetailPanel, NoResultsSafeList,
} from '../../controls/index';
import LocationDetails from '../../controls/LocationDetails';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';

import Services from '../../services';
import {
    ADMIN, DATE_FORMAT, ESC_KEY, UNKNOWN,
} from '../../constants';
import canAddClientAssignment, {
    isAssignmentReadyForSubmission,
    tryGetInReviewAssignmentForClient,
    tryGetOpenAssignmentForClient,
} from '../../utils/assignment-status';
import List, { ListItem } from '../../controls/List';
import RenderIf from '../../controls/RenderIf';
import { State } from '../../state/application';
import isConsideredOpen from '../../utils/is-considered-open';

interface ReviewDialogProps {
    onCancel: () => void,
    handleSubmission: (assignment: Assignment) => void,
    assignment: Assignment,
}

function ReviewDialog(props: ReviewDialogProps) {
    const { assignment, handleSubmission, onCancel } = props;
    const [reason, setReason] = useState('');
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const handleReviewNotesProvided = useCallback(() => {
        const notes = assignment.notes?.length
            ? `${assignment.notes}\n${reason}`
            : reason;

        handleSubmission({
            ...assignment,
            notes,
            status: InspectionStatus.SubmittedForReview,
        });
    }, [assignment, handleSubmission, reason]);
    const onReasonUpdated = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setReason(event.target.value);
    };
    const disabled = reason.length === 0;

    function handleKeyDown(event: React.KeyboardEvent<any>) {
        if (event.key === ESC_KEY) {
            onCancel();
        }
    }

    return (
        <section
            role="dialog"
            className="dialog reviewSubmission"
        >
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className="clickRegion" onClick={onCancel} />
            <main>
                <strong>Submit for Review</strong>
                <span>Inspections are incomplete, please specify why you are submitting.</span>
                <textarea
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    value={reason}
                    onChange={onReasonUpdated}
                />
                <button disabled={disabled} type="button" onClick={handleReviewNotesProvided}>Submit</button>
            </main>
        </section>
    );
}

interface Props {
    apiServices: Services,
    onAssignmentUpdated: (assignment: Assignment) => void,
    onError: (message: string) => void,
    categories: Array<Category>,
    inspections: Array<Inspection>,
    clients: Array<Client>,
    assignments: Array<Assignment>,
    employees: Array<Employee>,
    equipment: Array<EquipmentAsset>,
    locations: Array<Geolocation>,
    getApplicationState: () => State,
    currentUser: UserLogin,
    match: routerMatch<ExtractRouteParams<string, string>>,
}

const isTopLevelCategory = (cat: Category) => cat.parentId === null;

export default function ClientDetail(props: Props) {
    const {
        inspections,
        categories,
        equipment,
        assignments,
        employees,
        clients,
        getApplicationState,
        locations,
        apiServices,
        onAssignmentUpdated,
        onError,
        currentUser,
        match: { params: { clientId } },
    } = props;
    const [isReviewDialogVisible, setIsReviewDialogVisible] = useState(false);

    const handleSubmission = useCallback((assignment: Assignment) => {
        const assignmentUpdate = apiServices.updateAssignment(assignment);

        const relatedInspectionsUpdates = assignmentUpdate.then(() => Promise.all(
            inspections
                .filter((insp) => insp.assignmentId === assignment.id && isConsideredOpen(insp.status))
                .map((inspection) => apiServices.updateInspection({
                    ...inspection,
                    status: InspectionStatus.SubmittedForReview,
                })),
        ));

        Promise.all([assignmentUpdate, relatedInspectionsUpdates])
            .then(() => {
                onAssignmentUpdated(assignment);
            })
            .catch((e) => onError(e.message));
    }, [apiServices, inspections, onAssignmentUpdated, onError]);

    const client = clients.find((item) => `${item.id}` === clientId);
    const isAdmin = currentUser.role === ADMIN;

    if (!client) {
        throw new Error('Should not happen.');
    }

    const equipmentAssets = equipment.filter((asset) => asset.clientId === client.id);

    const categoriesWithEquipment = categories
        .filter(isTopLevelCategory)
        .map((category) => {
            const matchingEquipment = equipmentAssets.filter(
                (asset) => {
                    const childCategories = categories.filter(
                        (cat) => cat.parentId === category.id,
                    );

                    return asset.categoryId === category.id
                        || childCategories.some((cat) => cat.id === asset.categoryId);
                },
            );

            return { category, equipment: matchingEquipment };
        })
        .filter((grouped) => grouped.equipment.length)
        .map((grouped) => {
            const severityAndCount = querySeverityAndCount.byCategory(
                grouped.category,
                client,
                grouped.equipment,
                inspections,
                categories,
                assignments,
            );

            return (
                <li key={`cat-${grouped.category.id}`} className={grouped.category.slug}>
                    <Link to={`/clients/${client.id}/fleet/${grouped.category.id}`}>
                        <span className="name long-name">{grouped.category.pluralName}</span>
                        <span className="name short-name">{grouped.category.shortName}</span>
                        <div className="image" />
                        <div className={
                            `count severity ${Severity[severityAndCount?.severity ?? Severity.Ignore]}`
                        }
                        >
                            <span>{severityAndCount?.count ?? 0}</span>
                        </div>
                    </Link>
                </li>
            );
        });

    const clientLocation = locations.find((loc) => loc.id === client.locationId);
    const addressDetails = <LocationDetails readonly client={client} location={clientLocation} />;
    const assignmentItems = assignments
        .filter((asg) => asg.clientId === client.id)
        .sort((a, b) => dayjs(a.dueDate).diff(b.dueDate))
        .map((assignment) => {
            const route = `/clients/${client.id}/assignments/${assignment.id}`;
            const severityAndCount = querySeverityAndCount.byAssignment(
                assignment,
                equipment,
                inspections,
            );
            const title = dayjs(assignment.dueDate).format(DATE_FORMAT);
            const errorIndicator = assignment.status === InspectionStatus.Errored ? <div className="errored" /> : null;

            return {
                id: assignment.id ?? UNKNOWN,
                route,
                title,
                children: (
                    <>
                        <div className="name">
                            <span>{title}</span>
                            {errorIndicator}
                        </div>
                        <div className={
                            `count severity ${Severity[severityAndCount?.severity ?? Severity.Ignore]}`
                        }
                        >
                            <span>{severityAndCount?.count ?? 0}</span>
                        </div>
                    </>
                ),
            };
        });
    const locationItems: Array<ListItem> = locations
        .filter(
            (location) => location.id !== client.locationId
                && location.clientId === client.id,
        )
        .map((location) => {
            const route = `/clients/${client.id}/locations/${location.id}/equipment`;
            const title = location.name || '(Unnamed)';

            return {
                id: location.id ?? UNKNOWN,
                route,
                title,
                children: <span className="name">{title}</span>,
            };
        });
    const nextPendingAssignment = tryGetOpenAssignmentForClient(client.id ?? UNKNOWN, assignments);
    const requiresExplanation = nextPendingAssignment && !isAssignmentReadyForSubmission(
        nextPendingAssignment,
        equipment,
        inspections,
    );
    const inReviewAssignment = tryGetInReviewAssignmentForClient(client.id ?? UNKNOWN, assignments);

    let submitButton = null;
    let hasIssue = null;
    let inReview = null;
    let assignedStatus = <dd className="unassigned">Unassigned</dd>;

    if (inReviewAssignment) {
        inReview = (
            <dl>
                <dt>Review Status</dt><dd>In Review</dd>
            </dl>
        );
    }

    if (nextPendingAssignment) {
        const submissionHandler = requiresExplanation
            ? () => setIsReviewDialogVisible(true)
            : () => handleSubmission({
                ...nextPendingAssignment,
                status: InspectionStatus.SubmittedForReview,
            });
        const assignedEmployee = nextPendingAssignment?.employeeId
            ? employees.find((emp) => emp.id === nextPendingAssignment.employeeId)
            : null;

        submitButton = (
            <button
                type="button"
                onClick={submissionHandler}
            >Submit for Review
            </button>
        );

        assignedStatus = (
            <>
                <RenderIf condition={Boolean(assignedEmployee)}>
                    <dd>{assignedEmployee?.name}</dd>
                </RenderIf>
                <RenderIf condition={!assignedEmployee}>
                    <dd className="unassigned">Unassigned</dd>
                </RenderIf>
            </>
        );

        hasIssue = nextPendingAssignment.status === InspectionStatus.Errored
            ? <div className="issue" />
            : null;
    }

    const dialog = isReviewDialogVisible && nextPendingAssignment
        ? (
            <ReviewDialog
                onCancel={() => setIsReviewDialogVisible(false)}
                assignment={nextPendingAssignment}
                handleSubmission={handleSubmission}
            />
        )
        : null;
    const editRoute = isAdmin
        ? `/clients/${client.id}/edit`
        : `/clients/${client.id}/request-edits`;

    return (
        <div className="clientDetail">
            <main>
                <AppHeader getApplicationState={getApplicationState}>
                    {submitButton}
                </AppHeader>

                <h1>{client.name}</h1>
                <DetailPanel>
                    <dl>
                        {addressDetails}

                        {client.phoneNumber && (
                            <>
                                <dt>Phone</dt>
                                <dd className="phoneNumber">
                                    <a href={`tel:${client.phoneNumber}`}>{client.phoneNumber}</a>
                                </dd>
                            </>
                        )}

                        {client.email && (
                            <>
                                <dt>Email</dt>
                                <dd className="email">
                                    <a href={`mailto:${client.email}`}>{client.email}</a>
                                </dd>
                            </>
                        )}

                    </dl>
                    <Link to={editRoute}>Edit</Link>
                </DetailPanel>

                <section className="metadata">
                    <dl>
                        <dt>Assigned to</dt>
                        {assignedStatus}
                    </dl>
                    {hasIssue}
                    {inReview}
                </section>

                <RenderIf condition={isAdmin}>
                    <section>
                        <h2>Assignments</h2>
                        <List
                            className="locations whitebars"
                            itemClass=""
                            items={assignmentItems}
                            prepend={(
                                <>
                                    <RenderIf condition={canAddClientAssignment(client, assignments)}>
                                        <Actions>
                                            <Link
                                                className="add-action"
                                                to={`/clients/${client.id}/assignments/new/edit`}
                                            >Add Assignment
                                            </Link>
                                        </Actions>
                                    </RenderIf>
                                    <RenderIf condition={!canAddClientAssignment(client, assignments)}>
                                        <Actions>
                                            <div className="disabled">
                                                <button type="button" disabled className="add-action">Add Assignment</button>
                                                <span>(limit 1 of active)</span>
                                            </div>
                                        </Actions>
                                    </RenderIf>
                                </>
                            )}
                        />
                    </section>
                </RenderIf>
                <section className="fleet">
                    <h2>Fleet</h2>
                    <div className="links-header alongside">
                        <Link to={`/clients/${client.id}/fleet`}>View all</Link>
                    </div>

                    <NoResultsSafeList passedClasses="equipmentIcons" listResults={categoriesWithEquipment} />

                </section>
                <section>
                    <h2>Locations</h2>
                    <List
                        className="locations whitebars"
                        itemClass=""
                        items={locationItems}
                        append={(
                            <Actions>
                                <Link
                                    className="add-action"
                                    to={`/clients/${client.id}/locations/new/edit`}
                                >Add Location
                                </Link>
                            </Actions>
                        )}
                    />
                </section>
                {dialog}
            </main>
        </div>
    );
}
