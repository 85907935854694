import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
    BackgroundLoad,
    BackgroundLoadState,
    BackgroundLoadType,
    User,
    UserSetting,
} from '../types';

import {
    API_BASE_URI,
    LOCAL_DATE_TIME_FORMAT,
    MIN_FULL_CHANGES_THRESHOLD_MINUTES,
    MIN_RECENT_CHANGES_THRESHOLD_MINUTES,
    VERSION,
} from '../constants';
import { AppHeader, DetailPanel, InputWrapper } from '../controls';
import RenderIf from '../controls/RenderIf';
import { AlertType, SnackbarContext } from '../controls/Snackbar';
import { State } from '../state/application';
import deTitleCase from '../utils/de-title-case';

dayjs.extend(localizedFormat);

interface Props {
    getApplicationState: () => State,
    backgroundLoadLogs: Array<[string, AlertType, number]>,
    currentUser: User,
    onUnregister: () => void,
    onLogout: () => void,
    onTriggerBackgroundLoad: (loadType: BackgroundLoadType) => void,
    onUpsertSettings: (settings: { [key in keyof typeof UserSetting]: any }) => void,
    lastAutomatedRefresh: BackgroundLoad,
}

export default function Settings(props: Props) {
    const {
        getApplicationState, currentUser, onUnregister, onLogout, lastAutomatedRefresh, backgroundLoadLogs, onTriggerBackgroundLoad,
        onUpsertSettings,
    } = props;

    function logOut() {
        getApplicationState().appHistory.push('/');
        onLogout();
    }

    function unregister() {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure?')) {
            onUnregister();
        }
    }

    const { append: appendSnackbarItem } = useContext(SnackbarContext);
    const [showingLogs, setShowingLogs] = useState(false);
    const [fullUpdatesPollingPeriod, setFullUpdatesPollingPeriod] = useState(`${currentUser.userSettings.fullUpdatesPollingPeriod ?? ''}`);
    const [recentUpdatesPollingPeriod, setRecentUpdatesPollingPeriod] = useState(`${currentUser.userSettings.recentUpdatesPollingPeriod ?? ''}`);

    const handleFullUpdatesPollingPeriodChanged = (value: string) => {
        setFullUpdatesPollingPeriod(value);

        const asNumber = Number.parseInt(value, 10);

        if (value !== '' && asNumber < MIN_FULL_CHANGES_THRESHOLD_MINUTES) {
            appendSnackbarItem(`Minimum value for full refresh must be at least ${MIN_FULL_CHANGES_THRESHOLD_MINUTES}`, AlertType.Warning, 3000);
        }
    };
    const handleRecentUpdatesPollingPeriodChanged = (value: string) => {
        setRecentUpdatesPollingPeriod(value);

        const asNumber = Number.parseInt(value, 10);

        if (value !== '' && asNumber < MIN_RECENT_CHANGES_THRESHOLD_MINUTES) {
            appendSnackbarItem(`Minimum value for recent updates refresh must be at least ${MIN_RECENT_CHANGES_THRESHOLD_MINUTES}`, AlertType.Warning, 3000);
        }
    };

    const saveSettings = useCallback(() => {
        onUpsertSettings({
            recentUpdatesPollingPeriod: Number.parseInt(recentUpdatesPollingPeriod, 10),
            fullUpdatesPollingPeriod: Number.parseInt(fullUpdatesPollingPeriod, 10),
        });
    }, [onUpsertSettings, recentUpdatesPollingPeriod, fullUpdatesPollingPeriod]);

    const emitNotice = () => appendSnackbarItem('Development tool test notice', AlertType.Information);
    const emitWarning = () => appendSnackbarItem('Development tool test warning', AlertType.Warning);
    const emitError = () => appendSnackbarItem('Development tool test error', AlertType.Error);
    const emitPersistentNotice = () => appendSnackbarItem('Development tool test notice', AlertType.Information, true);
    const emitPersistentWarning = () => appendSnackbarItem('Development tool test warning', AlertType.Warning, true);
    const emitPersistentError = () => appendSnackbarItem('Development tool test error', AlertType.Error, true);

    const isFullRefreshIntervalDirty = fullUpdatesPollingPeriod !== `${currentUser.userSettings.fullUpdatesPollingPeriod ?? ''}`;
    const isPartialRefreshIntervalDirty = recentUpdatesPollingPeriod !== `${currentUser.userSettings.recentUpdatesPollingPeriod ?? ''}`;

    const lastFullRefresh = lastAutomatedRefresh[BackgroundLoadType.Full];
    const lastFullRefreshText = BackgroundLoadState[lastFullRefresh] !== undefined
        ? deTitleCase(BackgroundLoadState[lastFullRefresh])
        : dayjs(lastFullRefresh).format(LOCAL_DATE_TIME_FORMAT);
    const backgroundLoadLogContent = backgroundLoadLogs
        .map(([record, recordType, eventTime]) => `${dayjs(eventTime).format(LOCAL_DATE_TIME_FORMAT)} [${AlertType[recordType]}] ${record}`)
        .join('\n');

    const lastRecentRefresh = lastAutomatedRefresh[BackgroundLoadType.Recent];
    const lastRecentRefreshText = BackgroundLoadState[lastRecentRefresh] !== undefined
        ? deTitleCase(BackgroundLoadState[lastRecentRefresh])
        : dayjs(lastRecentRefresh).format(LOCAL_DATE_TIME_FORMAT);

    const fullRefreshIntervalAsNumber = Number.parseInt(fullUpdatesPollingPeriod, 10);
    const isFullRefreshIntervalValid = (fullUpdatesPollingPeriod === '') || (
        !Number.isNaN(fullRefreshIntervalAsNumber) && fullRefreshIntervalAsNumber >= MIN_FULL_CHANGES_THRESHOLD_MINUTES
    );
    const partialRefreshIntervalAsNumber = Number.parseInt(recentUpdatesPollingPeriod, 10);
    const isPartialRefreshIntervalValid = (recentUpdatesPollingPeriod === '') || (
        !Number.isNaN(partialRefreshIntervalAsNumber) && partialRefreshIntervalAsNumber >= MIN_RECENT_CHANGES_THRESHOLD_MINUTES
    );
    const canSaveUserSettings = (isPartialRefreshIntervalDirty || isFullRefreshIntervalDirty)
        && isFullRefreshIntervalValid
        && isPartialRefreshIntervalValid;

    return (
        <div className="settingsView">
            <AppHeader getApplicationState={getApplicationState} hideBackButton>
                <>
                </>
            </AppHeader>

            <h1>Settings</h1>

            <h2>
                User Settings
            </h2>
            <DetailPanel>
                <dl>
                    <dt>ID:</dt>
                    <dd className="employeeID">
                        {currentUser.id}
                    </dd>

                    <dt>Name:</dt>
                    <dd className="employeeName">
                        {currentUser.name}
                    </dd>

                    <dt>Username:</dt>
                    <dd className="employeeUsername">
                        {currentUser.username}
                    </dd>
                </dl>
            </DetailPanel>

            <h2>
                App Settings
            </h2>

            { /* TODO: Fill Following panel with correct information */}
            <DetailPanel>
                <dl>
                    <dt>App Version:</dt>
                    <dd className="appVersion">
                        {VERSION}
                    </dd>

                    <dt>API Endpoint</dt>
                    <dd className="appEndpoint">
                        {API_BASE_URI}
                    </dd>

                    <dt>Full refresh polling (minutes)</dt>
                    <dd className="refreshInterval">
                        <InputWrapper className={classNames({ dirty: isFullRefreshIntervalDirty })}>
                            <input type="number" value={fullUpdatesPollingPeriod} onChange={(evt) => handleFullUpdatesPollingPeriodChanged(evt.target.value)} />
                        </InputWrapper>
                    </dd>

                    <dt>Recent updates polling (minutes)</dt>
                    <dd className="refreshInterval">
                        <InputWrapper className={classNames({ dirty: isPartialRefreshIntervalDirty })}>
                            <input type="number" value={recentUpdatesPollingPeriod} onChange={(evt) => handleRecentUpdatesPollingPeriodChanged(evt.target.value)} />
                        </InputWrapper>
                    </dd>

                    <dt>Last full refresh</dt>
                    <dd className="lastRefresh">
                        <button type="button" onClick={() => setShowingLogs(true)}>
                            {lastFullRefreshText}
                        </button>
                    </dd>

                    <dt>Last recent updates refresh</dt>
                    <dd className="lastRefresh">
                        <button type="button" onClick={() => setShowingLogs(true)}>
                            {lastRecentRefreshText}
                        </button>
                    </dd>
                </dl>

            </DetailPanel>

            { /* TODO: Fill create functions for following buttons */}
            <div className="actions">
                <button
                    type="button"
                    onClick={saveSettings}
                    disabled={!canSaveUserSettings}
                >Save app settings changes
                </button>
                {/* TODO: Add View Logs Button -
                    <button type="button" onClick={viewLogs}>View Logs</button> */}
                <button type="button" onClick={logOut}>Log Out</button>
                <button type="button" className="risky" onClick={unregister}>Re-register PWA</button>
            </div>

            <RenderIf condition={process.env.NODE_ENV === 'development'}>
                <>
                    <h2>Development tools</h2>
                    <div className="actions">
                        <button type="button" onClick={emitNotice}>Emit snackbar notice</button>
                        <button type="button" onClick={emitWarning}>Emit snackbar warning</button>
                        <button type="button" onClick={emitError}>Emit snackbar error</button>

                        <button type="button" onClick={emitPersistentNotice}>Emit persistent snackbar notice</button>
                        <button type="button" onClick={emitPersistentWarning}>Emit persistent snackbar warning</button>
                        <button type="button" onClick={emitPersistentError}>Emit persistent snackbar error</button>

                        <button type="button" onClick={() => onTriggerBackgroundLoad(BackgroundLoadType.Full)}>Trigger full background load</button>
                        <button type="button" onClick={() => onTriggerBackgroundLoad(BackgroundLoadType.Recent)}>Trigger recently changed data background load</button>
                    </div>
                </>
            </RenderIf>
            <RenderIf condition={showingLogs}>
                <div className="modalContent">
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
                    <div className="clickRegion" onClick={() => setShowingLogs(false)} />
                    <pre>{backgroundLoadLogContent}</pre>
                </div>
            </RenderIf>
        </div>
    );
}
