import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { groupBy, sortBy, toPairs } from 'ramda';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import {
    Assignment,
    Category,
    Client,
    EquipmentAsset,
    FleetPageFilters,
    FleetPageType,
    Geolocation,
    Inspection,
    LoadingState,
    Severity,
} from '../../types';
import AppHeader from '../../controls/AppHeader';
import Loading from '../Loading';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';

// eslint-disable-next-line
import { DATE_FORMAT, Unset, Loading as LoadingSymbol, UNKNOWN } from '../../constants';
import List, { ListItem } from '../../controls/List';
import RenderIf from '../../controls/RenderIf';
import { DetailPanel } from '../../controls';
import LocationDetails from '../../controls/LocationDetails';
import { State } from '../../state/application';
import Search from '../../controls/Search';

const NO_CATEGORY = '';
const UNKNOWN_CATEGORY: Category = {
    id: UNKNOWN,
    singularName: 'Uncategorized',
    pluralName: 'Uncategorized',
    shortName: 'Uncategorized',
    slug: 'uncategorized',
    parentId: null,
};

interface Props {
    clients: Array<Client>,
    equipment: Array<EquipmentAsset>,
    assignments: Array<Assignment>,
    locations: Array<Geolocation>,
    inspections: Array<Inspection>,
    categories: Array<Category>,
    photosCache: Record<string, string | symbol>,
    loadPhoto: (id: string) => void,
    getApplicationState: () => State,
    loading: LoadingState,
    match: routerMatch<ExtractRouteParams<string, string>>,
    pageFilters: FleetPageFilters,
}

const bySearchTerm = (searchTerm: string) => (asset: EquipmentAsset) => searchTerm.length === 0
    || asset.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());

export default function ClientFleet(props: Props) {
    const {
        clients,
        categories,
        equipment,
        locations,
        assignments,
        inspections,
        getApplicationState,
        loading,
        photosCache,
        loadPhoto,
        match: {
            params: {
                clientId,
                categoryId: categoryIdAsText,
                locationId: locationIdAsText,
            },
        },
        pageFilters,
    } = props;

    if (loading.clients
        || loading.equipment
        || loading.assignments
        || loading.inspections
        || loading.locations
        || loading.categories
    ) {
        return <Loading />;
    }

    const client = clients.find(({ id }) => `${id}` === clientId);

    if (!client) {
        // TODO: Need test case for this situation
        return null;
    }

    const categoryId = categoryIdAsText;
    const targetRouteCategory = categories.find((cat) => cat.id === categoryId);

    const bySelectedParentCategory = (asset: EquipmentAsset) => {
        // If we routed here with a specific category, attempt to filter by that category
        if (categoryId) {
            // If our specified category isn't an exact match, we should check the parent category
            if (asset.categoryId !== categoryId) {
                const assetCategory = categories.find((cat) => cat.id === asset.categoryId);

                return assetCategory?.parentId === categoryId;
            }
        }
        return true;
    };

    const locationId = locationIdAsText;
    const location = locations.find((loc) => {
        if (Number.isNaN(locationId)) {
            return loc.id === client.locationId;
        }
        return loc.id === locationId;
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const bySelectedLocation = (asset: EquipmentAsset) => !locationId || asset.currentLocationId === locationId;

    const clientAssets = equipment
        .filter((asset) => asset.clientId === client?.id)
        .filter(bySelectedParentCategory)
        .filter(bySelectedLocation)
        .filter(bySearchTerm(pageFilters.search));

    const assetsByParentCategory = groupBy<EquipmentAsset, string>(
        (asset) => {
            const baseCategory = categories.find((cat) => cat.id === asset.categoryId)
                || UNKNOWN_CATEGORY;

            if (baseCategory?.parentId !== null && baseCategory?.parentId !== undefined) {
                const parentCategory = categories.find((cat) => cat.id === baseCategory.parentId);

                return `${parentCategory?.id}`;
            }

            return `${baseCategory?.id}`;
        },
        clientAssets,
    );

    const equipmentList = sortBy((pair) => pair[0], toPairs(assetsByParentCategory))
        .map(([parentCategoryIdTxt, categoryEquipment]) => {
            const parentEquipmentCategoryId = parentCategoryIdTxt;
            const parentCategory = categories.find(({ id }) => id === parentEquipmentCategoryId);
            const assetsByCategory = groupBy<EquipmentAsset, string>(
                (asset) => {
                    const immediateCategory = categories
                        .filter((cat) => cat.parentId !== null)
                        .find((cat) => cat.id === asset.categoryId);

                    return immediateCategory?.pluralName ?? NO_CATEGORY;
                },
                categoryEquipment,
            );

            const subcatEquipmentList = sortBy((pair) => pair[0], toPairs(assetsByCategory))
                .map(([childCategoryName, subcategoryEquipment]) => {
                    const childEquipmentCategoryId = subcategoryEquipment[0].categoryId;
                    const childCategory = categories.find(({ id }) => id === childEquipmentCategoryId);

                    const catEquipmentItems: Array<ListItem> = subcategoryEquipment.map((asset) => {
                        const { severity } = querySeverityAndCount.byAsset(
                            asset,
                            client,
                            assignments,
                            inspections,
                        );
                        const inspectionDates = inspections
                            .filter((insp) => insp.equipmentId === asset.id)
                            .map((insp) => dayjs(insp.createdOn));
                        const nextInspectionDate = sortBy(
                            (date) => date.valueOf(),
                            inspectionDates,
                        )[0];

                        let photoClasses = ['empty'];
                        let photo = null;
                        if (asset.primaryImageId) {
                            if (!photosCache[asset.primaryImageId]) {
                                loadPhoto(asset.primaryImageId);
                            } else {
                                const photoData = photosCache[asset.primaryImageId];

                                photoClasses = ['hasPhoto'];
                                if (photoData === Unset) {
                                    photoClasses.push('errored');
                                } else if (photoData !== LoadingSymbol) {
                                    const atts = {
                                        width: 100,
                                        height: 100,
                                    };

                                    photoClasses.push('loaded');
                                    photo = (
                                        <img alt="" src={photoData as string} {...atts} />
                                    );
                                }
                            }
                        }
                        const categoryClass = classNames('asset-photo', photoClasses, parentCategory?.slug);

                        return {
                            id: asset.id ?? UNKNOWN,
                            route: `/clients/${clientId}/equipment/${asset.id}`,
                            title: asset.name,
                            className: classNames({ isSelected: pageFilters.selectedAsset === asset.id }),
                            prepend: (
                                <>
                                </>
                            ),
                            children: (
                                <>
                                    <div className={categoryClass}>
                                        {photo}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span className="name">{asset.name}</span>
                                        <span className="model-year">{asset.year ?? ''}</span>
                                    </div>
                                    <span
                                        className="dueDate"
                                    >{nextInspectionDate?.format(DATE_FORMAT) ?? ''}
                                    </span>
                                    <span className={classNames('severity', Severity[severity])} />
                                </>
                            ),
                        };
                    });

                    const catEquipmentList = (
                        <List
                            className="whitebars equipment-list"
                            items={catEquipmentItems}
                            itemClass=""
                        />
                    );

                    if (childCategoryName === NO_CATEGORY) {
                        return catEquipmentList;
                    }

                    const subcatTitleElement = targetRouteCategory
                        ? null
                        : <h3>{childCategoryName}</h3>;

                    return (
                        <>
                            <Link className={`${childCategoryName} subcat`} to={`/clients/${clientId}/fleet/${childCategory?.id}`}>
                                {subcatTitleElement}
                            </Link>
                            {catEquipmentList}
                        </>
                    );
                });

            const parentTitleElement = targetRouteCategory
                ? null
                : <h2>{parentCategory?.pluralName}</h2>;

            return (
                <>
                    <Link className={`${parentCategory?.pluralName} cat`} to={`/clients/${clientId}/fleet/${parentCategory?.id}`}>
                        {parentTitleElement}
                    </Link>
                    {subcatEquipmentList}
                </>
            );
        });

    let title;
    let parentLink;

    if (targetRouteCategory?.pluralName) {
        title = <h1>{targetRouteCategory?.pluralName}</h1>;
        parentLink = (
            <div className="backToFleetList">
                <Link to={`/clients/${clientId}/fleet`}>View all equipment</Link>
            </div>
        );
    } else if (location && location.id !== client.locationId) {
        title = <h1>{location.name}</h1>;
        parentLink = (
            <div className="backToFleetList">
                <Link to={`/clients/${clientId}/fleet`}>View all equipment</Link>
            </div>
        );
    } else {
        title = <h1>Fleet</h1>;
        parentLink = null;
    }

    let newEquipmentRoute;

    if (locationId) {
        newEquipmentRoute = `/clients/${clientId}/locations/${locationId}/equipment/new/edit`;
    } else {
        newEquipmentRoute = `/clients/${clientId}/equipment/new/edit`;
    }

    return (
        <div className="fleetListing">
            <main>
                <AppHeader getApplicationState={getApplicationState} title={client.name}>
                    <Link className="icn-plus" to={newEquipmentRoute}>Add Equipment</Link>
                </AppHeader>
                <section className="fleet">
                    {title}
                    {parentLink}

                    <RenderIf condition={pageFilters.pageType === FleetPageType.Location}>
                        <DetailPanel>
                            <dl>
                                <LocationDetails
                                    client={client}
                                    location={location}
                                />
                            </dl>
                        </DetailPanel>
                    </RenderIf>

                    <Search
                        value={pageFilters.search}
                        searchUpdate={pageFilters.onSearchChanged}
                    />

                    {equipmentList}
                </section>
            </main>
        </div>
    );
}
