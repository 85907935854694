import { Dispatch } from 'react';

// @eslint-disable-next-line import/no-cycle
import { Action, actionCreators } from '../index';

const CLIENTS_ROUTE_PATTERN = /^\/clients\/([^\\/]+)/i;

export default function handleClientRouteVisited(dispatch: Dispatch<Action>) {
    return (path: string) => {
        const clientRouteMatch = path.match(CLIENTS_ROUTE_PATTERN);

        if (clientRouteMatch !== null) {
            const clientId = clientRouteMatch[1];

            if (clientId) {
                dispatch(actionCreators.addRecentClient(clientId));
            }
        }
    };
}
