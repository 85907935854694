import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Geolocation } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function deleteLocation(dispatch: Dispatch<Action>, getState: () => State) {
    return (locationId: string) => {
        const { locations } = getState();

        if (locations === Unset || locations === Loading) {
            return;
        }

        dispatch(actionCreators.setLocations((locations as Array<Geolocation>).filter(
            (item) => item.id !== locationId,
        )));
    };
}
