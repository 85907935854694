import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { AlertType } from '../../../controls/Snackbar';
import {
    BackgroundLoadState,
    BackgroundLoadType,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    Identifiable,
    Geolocation,
} from '../../../types';

type LogHandler = (text: string, alertType: AlertType) => void;

/**
 *
 * @param addLogEntry
 * @param toHandle
 */
function handleLoadFailures(loadType: BackgroundLoadType, addLogEntry: LogHandler, toHandle: Array<[Promise<any>, string]>) {
    const loadTypeName = BackgroundLoadType[loadType];
    const errors: Array<string> = [];
    const caught = toHandle.map(([fallible, name]) => fallible.catch(() => {
        errors.push(name);
    }));

    const processStartedAt = new Date();

    Promise.all(caught)
        .then(() => {
            if (errors.length) {
                addLogEntry(`One or more services failed to load: ${errors.join(', ')}`, AlertType.Error);
            } else {
                const runDuration = new Date().getTime() - processStartedAt.getTime();

                addLogEntry(`Background load process (${loadTypeName}) completed in ${runDuration}ms.`, AlertType.Information);
            }
        });
}

function tryMergeIdentifiableList<T extends Identifiable>(existing: symbol | Array<T>, updated: Array<T>): Array<T> | null {
    if (typeof existing === 'object') {
        const unaffected = existing.filter((item) => !updated.find((other) => other.id === item.id));

        return [
            ...unaffected,
            ...updated,
        ];
    }
    return null;
}

export default function backgroundLoad(dispatch: Dispatch<Action>, getState: () => State, onError: LogHandler) {
    return (loadType: BackgroundLoadType) => {
        const { apiServices, backgroundLoad: currentLoadState } = getState();

        const anyRunning = [BackgroundLoadType.Recent, BackgroundLoadType.Full]
            .some((key) => currentLoadState[key] === BackgroundLoadState.Running);

        if (anyRunning) {
            return;
        }

        if (currentLoadState[loadType] === BackgroundLoadState.Errored) {
            // If we've previously errored trying this process, we currently want to skip...
            return;
        }

        dispatch(actionCreators.setBackgroundLoad(BackgroundLoadState.Running, loadType));

        const loads: Array<[Promise<any>, string, (res: Array<any>) => void]> = [];

        if (loadType === BackgroundLoadType.Full) {
            loads.push([apiServices.getEquipmentMakes(), 'Makes', actionCreators.setMakes]);
            loads.push([apiServices.getEmployees(), 'Employees', actionCreators.setEmployees]);
            loads.push([apiServices.getCategories(), 'Categories', actionCreators.setCategories]);
            loads.push([apiServices.getClients(), 'Clients', actionCreators.setClients]);
            loads.push([apiServices.getEquipment(), 'Equipment', actionCreators.setEquipment]);
            loads.push([apiServices.getLocations(), 'Locations', actionCreators.setLocations]);
            loads.push([apiServices.getAssignments(), 'Assignments', actionCreators.setAssignments]);
            loads.push([apiServices.getAssignmentShares(), 'Assignment Shares', actionCreators.setAssignmentShares]);
            loads.push([apiServices.getClientContacts(), 'Client Contacts', actionCreators.setClientContacts]);
            loads.push([apiServices.getInspections(), 'Inspections', actionCreators.setInspections]);
        } else {
            const latestRun = Math.max(
                currentLoadState[BackgroundLoadType.Full],
                currentLoadState[BackgroundLoadType.Recent],
            );

            loads.push([apiServices.getEmployees(latestRun), 'Employees', (updated: Array<Employee>) => {
                const { employees } = getState();
                const toUpdate = tryMergeIdentifiableList(employees, updated);

                if (toUpdate) {
                    dispatch(actionCreators.setEmployees(toUpdate));
                }
            }]);
            loads.push([apiServices.getCategories(latestRun), 'Categories', (updated: Array<Category>) => {
                const { categories } = getState();
                const toUpdate = tryMergeIdentifiableList(categories, updated);

                if (toUpdate) {
                    dispatch(actionCreators.setCategories(toUpdate));
                }
            }]);
            loads.push([apiServices.getClients(latestRun), 'Clients', (updated: Array<Client>) => {
                const { clients } = getState();
                const toUpdate = tryMergeIdentifiableList(clients, updated);

                if (toUpdate) {
                    dispatch(actionCreators.setClients(toUpdate));
                }
            }]);
            loads.push([apiServices.getEquipment(latestRun), 'Equipment', (updated: Array<EquipmentAsset>) => {
                const { equipment } = getState();
                const toUpdate = tryMergeIdentifiableList(equipment, updated);

                if (toUpdate) {
                    dispatch(actionCreators.setEquipment(toUpdate));
                }
            }]);
            loads.push([apiServices.getLocations(latestRun), 'Locations', (updated: Array<Geolocation>) => {
                const { locations } = getState();
                const toUpdate = tryMergeIdentifiableList(locations, updated);

                if (toUpdate) {
                    dispatch(actionCreators.setLocations(toUpdate));
                }
            }]);
        }

        handleLoadFailures(
            loadType,
            (text, alertType) => {
                if (alertType === AlertType.Error) {
                    onError(text, alertType);
                }
                dispatch(actionCreators.appendBackgroundLoadLogs(text, alertType));
            },
            loads.map(([loadProcess, name]) => [loadProcess, name]),
        );

        const promises = loads.map(([loadProcess]) => loadProcess);

        // Handle load success:
        Promise.all(promises).then(() => {
            loads.forEach(([loadProcess, , handler]) => {
                loadProcess.then(handler);
            });
        });

        // Handle process completion
        Promise.allSettled(promises)
            .then(() => {
                dispatch(actionCreators.setBackgroundLoad(new Date().getTime(), loadType));
            });
    };
}
