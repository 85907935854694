import React, {
    ChangeEventHandler, useState, ReactElement, useEffect,
} from 'react';
import classNames from 'classnames';
import { EMPTY_NOTES_READ_ONLY } from '../constants';

const DEFAULT_NOTES_INPUT_PROPS = {
    className: '',
    severity: false,
    severityOnChange() { return null; },
    showFieldLabel: true,
};

interface NotesInputFieldProps {
    className?: string,
    showFieldLabel?: boolean,
    name: string,
    label: string,
    value: any,
    severity?: boolean,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
    severityOnChange?: ChangeEventHandler<HTMLSelectElement> | undefined,
}

export default function NotesInputField(props: NotesInputFieldProps): ReactElement {
    const {
        className: initialClassName,
        name,
        label,
        value,
        onChange: initOnChange,
        severity,
        severityOnChange,
        showFieldLabel,
    } = props;

    const [notes, setNotes] = useState<string | null>(null);

    useEffect(() => {
        if (value) {
            setNotes(value);
        }
    }, [value]);

    const id = `notesInput${name}`;
    const severityId = `notesInput${name}Severity`;

    const className = classNames(
        'field',
        id,
        initialClassName,
        'notesInputWrapper',
    );

    const fieldLabel = showFieldLabel ? <span>{label}</span> : null;
    const placeholderText = initOnChange === undefined
        ? EMPTY_NOTES_READ_ONLY
        : label;

    return (
        <div className={className}>
            { severity && false && (
                <label htmlFor={severityId}>
                    <span>Edit</span>
                    <select onChange={severityOnChange} name="severity" id={severityId}>
                        <option value="">--Please choose an option--</option>
                        <option value="nominal">Nominal</option>
                        <option value="warning">Warning</option>
                        <option value="critical">Critical</option>
                    </select>
                </label>
            )}

            <label htmlFor={id}>
                {fieldLabel}
                { notes === null && initOnChange
                    ? (
                        <button type="button" className="plus" onClick={() => setNotes('')}>Add Notes</button>
                    )
                    : (
                        <textarea
                            id={id}
                            placeholder={placeholderText}
                            value={value}
                            readOnly={initOnChange === undefined}
                            onChange={initOnChange}
                        />
                    )}
            </label>
        </div>
    );
}

NotesInputField.defaultProps = DEFAULT_NOTES_INPUT_PROPS;
