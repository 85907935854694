import classNames from 'classnames';
import React from 'react';
import { Severity } from '../../types';

interface Props {
    title: string,
    errorIndicator: JSX.Element | null,
    location: any,
    severity: Severity,
    count: number,
}

interface WideProps extends Props {
    assignmentDueDate: string,
    dateRange: string,
}

function WideTemplate(props: WideProps) {
    const {
        title, errorIndicator, location, assignmentDueDate, dateRange, severity, count,
    } = props;

    return (
        <>
            <div className="row-section">
                <span className="name">
                    <span>{title}</span>
                    {errorIndicator}
                </span>
                <span className="location">{location?.city}</span>
                <div className={classNames('severity', 'mobile-only', Severity[severity])}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </div>
            <div className="row-section">
                <span className="dueDate">{assignmentDueDate}</span>
                <span className="inspectionDates">Inspections {dateRange}</span>
                <div className={classNames('severity', Severity[severity])}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </div>
        </>
    );
}

function NarrowTemplate(props: Props) {
    const {
        title, errorIndicator, location, severity, count,
    } = props;

    return (
        <>
            <div className="whole-row">
                <span className="name">
                    <span>{title}</span>
                    {errorIndicator}
                </span>
                <span className="location">{location?.city}</span>
                <div className={classNames('severity', Severity[severity])}>
                    <span className="inspectionsCount">{count}</span>
                </div>
            </div>
        </>
    );
}

export { WideTemplate, NarrowTemplate };
