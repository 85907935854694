import React, { useMemo } from 'react';
import SplitView from '../../controls/SplitView';

import upsertAssignment from '../../state/application/thunks/upsert-assignment';
import Assignments from '../../views/Assignments';
import EditAssignment from '../../views/EditAssignment';
import upsertAssignmentShare from '../../state/application/thunks/upsert-assignment-share';
import { AppComponentProps } from '../route-wrapper';
import { actionCreators } from '../../state/application';
import {
    Assignment,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    StatusFilter,
    UserLogin,
} from '../../types';
import Loading from '../../views/Loading';

export default function EditAssignmentConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleError,
        handleWarning,
    } = props;
    const state = getState();

    const assignmentsPageFilters = useMemo(() => ({
        ...state.viewState.assignments,
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setAssignmentsViewStateSearch(search),
        ),
        onDateStartChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateStart(date),
        ),
        onDateEndChanged: (date: Date | null) => dispatch(
            actionCreators.setAssignmentsViewStateDateEnd(date),
        ),
        onStatusFilterChanged: (filter: StatusFilter) => dispatch(
            actionCreators.setAssignmentsViewStateFilter(filter),
        ),
    }), [dispatch, state.viewState.assignments]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.employees
        || loading.currentUser
    ) {
        return <Loading />;
    }

    return (
        <>
            <SplitView
                className="assignmentsList"
                leftPane={(
                    <Assignments
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        pageFilters={assignmentsPageFilters}
                    />
                )}
                rightPane={(
                    <EditAssignment
                        onError={handleError}
                        onWarning={handleWarning}
                        loading={loading}
                        apiServices={state.apiServices}
                        onCreated={upsertAssignment(dispatch, getState)}
                        onShareAdded={upsertAssignmentShare(dispatch, getState)}
                        onCancel={() => state.appHistory.goBack()}
                        employees={state.employees as Array<Employee>}
                        assignments={state.assignments as Array<Assignment>}
                        currentUser={state.currentUser as UserLogin}
                        clients={state.clients as Array<Client>}
                        getApplicationState={getState}
                        match={match}
                    />
                )}
            />
        </>
    );
}
