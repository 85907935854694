import { Geolocation } from '../types';

export enum LocationDisplayType {
    None,
    Address,
    Geo,
}

export default function parseLocationDisplayDetails(
    location: Geolocation | null,
): LocationDisplayType {
    if (location?.street1 || location?.city || location?.state) {
        return LocationDisplayType.Address;
    }
    if (location?.lat && location?.lng) {
        return LocationDisplayType.Geo;
    }
    return LocationDisplayType.None;
}
