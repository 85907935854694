import React from 'react';

export default function Footer() {
    return (
        <footer>
            <h2>Thank you for choosing CFI Tire!</h2>

            <div className="footer-info">
                <div className="logo" />
                <section className="address">
                    <strong>CFI Tire Service</strong>
                    <br />
                    <span>2601 Dixon St</span>
                    <br />
                    <span>Des Moines, IA 50316</span>
                </section>
                <section className="contactDetails">
                    <strong>Get in Touch</strong>
                    <br />
                    <span>
                        <em>Phone:</em>
                        515-262-1359
                    </span>
                    <br />
                    <span>
                        <em>Email:</em>
                        sales@cfitire.com
                    </span>
                </section>
            </div>

            <a rel="noreferrer" target="_blank" href="https://www.cfitire.com">www.cfitire.com</a>
        </footer>
    );
}
