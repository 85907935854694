import { Dispatch } from 'react';
import { Location } from 'history';
import { Action, actionCreators } from '../index';
import { FleetPageType } from '../../../types';

// The following routes, when visited, will cause us to flesh out viewstate such that
// we can subsequently get back to that location as needed:

// Fleet listings
const CLIENTS_FLEET_MAYBE_BY_CATEGORY_ROUTE = /^\/(clients)\/([0-9a-f-]+)\/(fleet)\/?([0-9a-f-]+)?$/;
const CLIENTS_FLEET_BY_LOCATION_ROUTE = /^\/(clients)\/([0-9a-f-]+)\/(locations)\/([0-9a-f-]+)\/(equipment)$/;

const CLIENT_ASSIGNMENT_ROUTE = /^\/(clients)\/([0-9a-f-]+)\/(assignments)\/([0-9a-f-]+)$/;
const CLIENTS_ROUTE = /^\/(clients)\/?([0-9a-f-]+)$/;
const EMPLOYEES_ROUTE = /^\/(employees)\/([0-9a-f-]+)$/;
const ASSIGNMENTS_ROUTE = /^\/(assignments)\/([0-9a-f-]+)$/;

const EQUIPMENT_ASSET_ROUTE = /^\/clients\/([0-9a-f-]+)\b.*equipment\/([0-9a-f-]+)$/;

export default function handleLocationEvent(dispatch: Dispatch<Action>) {
    return (event: Location) => {
        let routeBase: string | null = null;
        let clientSelection: string | null = null;
        let employeeSelection: string | null = null;
        let assignmentSelection: string | null = null;
        let fleetSelection: string | null = null;
        let fleetPageType: FleetPageType | null = null;
        let assetSelection: string | null = null;

        const standardMatch = [
            CLIENTS_FLEET_MAYBE_BY_CATEGORY_ROUTE,
            CLIENTS_FLEET_BY_LOCATION_ROUTE,
            CLIENT_ASSIGNMENT_ROUTE,
            CLIENTS_ROUTE,
            EMPLOYEES_ROUTE,
            ASSIGNMENTS_ROUTE,
        ]
            .map(
                (pat) => event.pathname.match(pat),
            )
            .find((x) => x);
        const assetMatch = event.pathname.match(EQUIPMENT_ASSET_ROUTE);

        if (assetMatch) {
            const [, clientIdText, assetIdText] = assetMatch;

            clientSelection = clientIdText;
            assetSelection = assetIdText;
        } else if (standardMatch) {
            const [, part, idText, subPart, subIdText] = standardMatch;

            routeBase = part;

            if (subPart === 'assignments') {
                routeBase = 'assignments';
                assignmentSelection = subIdText;
            } else if (part === 'clients' && subPart) {
                clientSelection = idText;
                fleetSelection = subIdText;

                switch (subPart) {
                    case 'fleet':
                        if (fleetSelection !== null) {
                            fleetPageType = FleetPageType.Category;
                            break;
                        }
                        fleetPageType = FleetPageType.Client;
                        break;
                    case 'locations':
                        fleetPageType = FleetPageType.Location;
                        break;
                    default:
                        break;
                }
            } else {
                switch (part) {
                    case 'clients':
                        clientSelection = idText;
                        break;
                    case 'employees':
                        employeeSelection = idText;
                        break;
                    default:
                        // eslint-disable-next-line no-console
                        console.error(`Unfamiliar path part ${part}, ignoring.`);
                        break;
                }
            }
        }

        if (assetSelection) {
            dispatch(actionCreators.setFleetViewStateAssetSelection(assetSelection));
        } else if (routeBase === 'clients') {
            dispatch(actionCreators.setClientsViewStateSelection(clientSelection));
            dispatch(actionCreators.setFleetViewStatePageType(fleetPageType));
            dispatch(actionCreators.setFleetViewStateSelection(fleetSelection));
            dispatch(actionCreators.setFleetViewStateAssetSelection(null));
        } else if (routeBase === 'employees') {
            dispatch(actionCreators.setEmployeesViewStateSelection(employeeSelection));
        } else if (routeBase === 'assignments') {
            dispatch(actionCreators.setAssignmentsViewStateSelection(assignmentSelection));
        }
    };
}
