import React, { useMemo } from 'react';
import deleteEquipmentAsset from '../../state/application/thunks/delete-equipment';
import upsertAssignment from '../../state/application/thunks/upsert-assignment';
import tryDeleteEquipment from '../../utils/try-delete-equipment';
import EquipmentDetail from '../../views/EquipmentDetail';
import upsertAssignmentShare from '../../state/application/thunks/upsert-assignment-share';
import SplitView from '../../controls/SplitView';
import ClientFleet from '../../views/Clients/ClientFleet';
import loadPhoto from '../../state/application/thunks/load-photo';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment,
    Category,
    Client,
    Employee,
    EquipmentAsset,
    FleetPageFilters,
    FleetPageType,
    Geolocation,
    Inspection,
    InspectionStatus,
} from '../../types';
import { actionCreators } from '../../state/application';
import Loading from '../../views/Loading';
import upsertInspection from '../../state/application/thunks/upsert-inspection';

export default function EquipmentDetailConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleWarning,
        handleError,
        handleInformation,
    } = props;
    const state = getState();

    const fleetPageFilters: FleetPageFilters = useMemo(() => ({
        ...state.viewState.fleet,
        onPageTypeChanged: (pageType: FleetPageType) => dispatch(
            actionCreators.setFleetViewStatePageType(pageType),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setFleetViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.fleet]);

    const onInspectionUpdated = (inspection: Inspection) => {
        const existingInspection = (getState().inspections as Array<Inspection>)
            .find((item) => item.id === inspection.id);
        const existingStatus = existingInspection?.status;

        upsertInspection(dispatch, getState)(inspection);

        if (existingStatus !== inspection.status && inspection.status === InspectionStatus.SubmittedForReview) {
            handleInformation('The inspection has been marked as Completed.');
        }
    };

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.categories
    ) {
        return <Loading />;
    }

    return (
        <>
            <SplitView
                className="clientsList--equipment-detail"
                leftPane={(
                    <ClientFleet
                        photosCache={state.photosRepo.cache}
                        loadPhoto={(id: string) => loadPhoto(dispatch, getState)(id, handleWarning)}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        categories={state.categories as Array<Category>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        pageFilters={fleetPageFilters}
                    />
                )}
                rightPane={(
                    <EquipmentDetail
                        employees={state.employees as Array<Employee>}
                        photosCache={state.photosRepo.cache}
                        onInspectionAdded={onInspectionUpdated}
                        onAssignmentAdded={upsertAssignment(dispatch, getState)}
                        onShareAdded={upsertAssignmentShare(dispatch, getState)}
                        onError={handleError}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        categories={state.categories as Array<Category>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        onDelete={tryDeleteEquipment(
                            state.apiServices,
                            deleteEquipmentAsset(dispatch, getState),
                            handleError,
                        )}
                        apiServices={state.apiServices}
                    />
                )}
            />
        </>
    );
}
