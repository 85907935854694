import { History } from 'history';
import { Subject } from 'rxjs';
import {
    AppMessage,
    Assignment,
    AssignmentShare,
    AssignmentsPageFilterCriteria,
    BackgroundLoad,
    BackgroundLoadState,
    BackgroundLoadType,
    Category,
    Client,
    ClientContact,
    ClientFilter,
    ClientsPageFilterCriteria,
    Employee,
    EquipmentAsset,
    FleetPageFilterCriteria,
    FleetPageType,
    Geolocation,
    Inspection,
    PageFilterCriteria,
    PhotosRepo,
    StatusFilter,
    User,
} from '../../types';
import Services from '../../services';
import { Unset } from '../../constants';
import {
    getInitialAssignmentsPageFilter,
    getInitialClientsPageFilter,
    getInitialFleetPageFilter,
    getInitialSearchFilter,
} from '../../types/constructors';
import { AlertType } from '../../controls/Snackbar';

export interface State {
    appEvents: Subject<AppMessage>,
    apiServices: Services,
    currentUser: User | symbol,
    initialized: symbol,
    backgroundLoad: BackgroundLoad,
    backgroundLoadLogs: Array<[string, AlertType, number]>,

    makes: Array<string> | symbol,
    employees: Array<Employee> | symbol,
    clients: Array<Client> | symbol,
    equipment: Array<EquipmentAsset> | symbol,
    assignments: Array<Assignment> | symbol,
    inspections: Array<Inspection> | symbol,
    locations: Array<Geolocation> | symbol,
    categories: Array<Category> | symbol,
    assignmentShares: Array<AssignmentShare> | symbol,
    clientContacts: Array<ClientContact> | symbol,

    viewState: {
        clients: ClientsPageFilterCriteria,
        employees: PageFilterCriteria,
        assignments: AssignmentsPageFilterCriteria,
        fleet: FleetPageFilterCriteria,
    },

    recentClients: Set<string>,
    photosRepo: PhotosRepo,

    appHistory: History,
}

export enum ActionType {
    setInitialized,

    setMakes,
    setAssignments,
    setCategories,
    setClients,
    setCurrentUser,
    setEmployees,
    setEquipment,
    setInspections,
    setLocations,
    setAssignmentShares,
    setClientContacts,

    addRecentClient,
    clearRecentClients,

    setClientsViewStateSearch,
    setClientsViewStateFilter,
    setClientsViewStateSelection,

    setEmployeesViewStateSearch,
    setEmployeesViewStateSelection,

    setAssignmentsViewStateSearch,
    setAssignmentsViewStateFilter,
    setAssignmentsViewStateSelection,
    setAssignmentsViewStateDateStart,
    setAssignmentsViewStateDateEnd,

    setFleetViewStateSearch,
    setFleetViewStateSelection,
    setFleetPageType,
    setFleetViewStateAssetSelection,

    addPhotoToRepo,
    addPhotosToRepo,
    setPhotoLoading,
    deletePhotoFromCache,

    setClientUpdateRequests,
    setBackgroundLoad,
    appendBackgroundLoadLogs,
}

export interface Action {
    actionType: ActionType,
}
export interface ValueAction<T> extends Action {
    value: T,
}

export interface AppendBackgroundLoadLogsAction extends ValueAction<[string, AlertType, number]> {}
export interface SetPhotoLoadingAction extends ValueAction<string> {}
export interface AddPhotoToRepoAction extends ValueAction<{ id: string, src: string | symbol }> {}
export interface AddPhotosToRepoAction extends ValueAction<Record<number, string>> {}
export interface DeletePhotoAction extends ValueAction<string> {}
export interface SetClientsViewStateSearchAction extends ValueAction<string> {}
export interface SetClientsViewStateFilterAction extends ValueAction<ClientFilter> {}
export interface SetClientsViewStateSelectionAction extends ValueAction<string | null> {}
export interface SetEmployeesViewStateSearchAction extends ValueAction<string> {}
export interface SetEmployeesViewStateSelectionAction extends ValueAction<string | null> {}
export interface SetAssignmentsViewStateSearchAction extends ValueAction<string> {}
export interface SetAssignmentsViewStateFilterAction extends ValueAction<StatusFilter> {}
export interface SetAssignmentsViewStateDateStartAction extends ValueAction<Date | null> {}
export interface SetAssignmentsViewStateDateEndAction extends ValueAction<Date | null> {}
export interface SetAssignmentsViewStateSelectionAction extends ValueAction<string | null> {}
export interface SetFleetViewStateSearchAction extends ValueAction<string> {}
export interface SetFleetViewStateSelectionAction extends ValueAction<string | null> {}
export interface SetFleetViewStatePageTypeAction extends ValueAction<FleetPageType | null> {}
export interface SetFleetViewStateAssetSelectionAction extends ValueAction<string | null> {}
export interface SetInitializedAction extends ValueAction<symbol> {}
export interface SetAssignmentsAction extends ValueAction<symbol | Array<Assignment>> {}
export interface SetCategoriesAction extends ValueAction<symbol | Array<Category>> {}
export interface SetClientsAction extends ValueAction<symbol | Array<Client>> {}
export interface SetCurrentUserAction extends ValueAction<symbol | User> {}
export interface SetMakesAction extends ValueAction<symbol | Array<string>> {}
export interface SetEmployeesAction extends ValueAction<symbol | Array<Employee>> {}
export interface SetEquipmentAction extends ValueAction<symbol | Array<EquipmentAsset>> {}
export interface SetInspectionsAction extends ValueAction<symbol | Array<Inspection>> {}
export interface SetLocationsAction extends ValueAction<symbol | Array<Geolocation>> {}
export interface SetAssignmentSharesAction extends ValueAction<symbol | Array<AssignmentShare>> {}
export interface SetClientContactsAction extends ValueAction<symbol | Array<ClientContact>> {}
export interface AddRecentClientAction extends ValueAction<string> {}
export interface ClearRecentClientsAction extends Action {}
export interface SetBackgroundLoadAction extends ValueAction<{ loadState: BackgroundLoadState | number, loadType: BackgroundLoadType }> {}

export const actionCreators = {
    appendBackgroundLoadLogs(record: string, recordType: AlertType): AppendBackgroundLoadLogsAction {
        return {
            actionType: ActionType.appendBackgroundLoadLogs,
            value: [record, recordType, new Date().getTime()],
        };
    },
    setMakes(makes: Array<string> | symbol): SetMakesAction {
        return {
            actionType: ActionType.setMakes,
            value: makes,
        };
    },
    setPhotoLoading(id: string): SetPhotoLoadingAction {
        return {
            actionType: ActionType.setPhotoLoading,
            value: id,
        };
    },
    addPhotoToRepo(id: string, src: string | symbol): AddPhotoToRepoAction {
        return {
            actionType: ActionType.addPhotoToRepo,
            value: {
                src,
                id,
            },
        };
    },
    addPhotosToRepo(photos: Record<number, string>): AddPhotosToRepoAction {
        return {
            actionType: ActionType.addPhotosToRepo,
            value: photos,
        };
    },
    deletePhotoFromCache(value: string): DeletePhotoAction {
        return { value, actionType: ActionType.deletePhotoFromCache };
    },
    setClientsViewStateSearch(value: string): SetClientsViewStateSearchAction {
        return { value, actionType: ActionType.setClientsViewStateSearch };
    },
    setClientsViewStateFilter(value: ClientFilter): SetClientsViewStateFilterAction {
        return { value, actionType: ActionType.setClientsViewStateFilter };
    },
    setClientsViewStateSelection(value: string | null): SetClientsViewStateSelectionAction {
        return { value, actionType: ActionType.setClientsViewStateSelection };
    },
    setEmployeesViewStateSearch(value: string): SetEmployeesViewStateSearchAction {
        return { value, actionType: ActionType.setEmployeesViewStateSearch };
    },
    setEmployeesViewStateSelection(value: string | null): SetEmployeesViewStateSelectionAction {
        return { value, actionType: ActionType.setEmployeesViewStateSelection };
    },
    setAssignmentsViewStateSearch(value: string): SetAssignmentsViewStateSearchAction {
        return { value, actionType: ActionType.setAssignmentsViewStateSearch };
    },
    setAssignmentsViewStateFilter(value: StatusFilter): SetAssignmentsViewStateFilterAction {
        return { value, actionType: ActionType.setAssignmentsViewStateFilter };
    },
    setAssignmentsViewStateDateStart(value: Date | null): SetAssignmentsViewStateDateStartAction {
        return { value, actionType: ActionType.setAssignmentsViewStateDateStart };
    },
    setAssignmentsViewStateDateEnd(value: Date | null): SetAssignmentsViewStateDateEndAction {
        return { value, actionType: ActionType.setAssignmentsViewStateDateEnd };
    },
    setAssignmentsViewStateSelection(value: string | null): SetAssignmentsViewStateSelectionAction {
        return { value, actionType: ActionType.setAssignmentsViewStateSelection };
    },
    setFleetViewStateSearch(value: string): SetFleetViewStateSearchAction {
        return { value, actionType: ActionType.setFleetViewStateSearch };
    },
    setFleetViewStatePageType(value: FleetPageType | null): SetFleetViewStatePageTypeAction {
        return { value, actionType: ActionType.setFleetPageType };
    },
    setFleetViewStateSelection(value: string | null): SetFleetViewStateSelectionAction {
        return { value, actionType: ActionType.setFleetViewStateSelection };
    },
    setFleetViewStateAssetSelection(value: string | null): SetFleetViewStateAssetSelectionAction {
        return { value, actionType: ActionType.setFleetViewStateAssetSelection };
    },
    setInitialized(value: symbol): SetInitializedAction {
        return { value, actionType: ActionType.setInitialized };
    },
    setAssignments(value: symbol | Array<Assignment>): SetAssignmentsAction {
        return { value, actionType: ActionType.setAssignments };
    },
    setClientContacts(value: symbol | Array<ClientContact>): SetClientContactsAction {
        return { value, actionType: ActionType.setClientContacts };
    },
    setAssignmentShares(value: symbol | Array<AssignmentShare>): SetAssignmentSharesAction {
        return { value, actionType: ActionType.setAssignmentShares };
    },
    setCategories(value: symbol | Array<Category>): SetCategoriesAction {
        return { value, actionType: ActionType.setCategories };
    },
    setClients(value: symbol | Array<Client>): SetClientsAction {
        return { value, actionType: ActionType.setClients };
    },
    setCurrentUser(value: symbol | User): SetCurrentUserAction {
        return { value, actionType: ActionType.setCurrentUser };
    },
    setEmployees(value: symbol | Array<Employee>): SetEmployeesAction {
        return { value, actionType: ActionType.setEmployees };
    },
    setEquipment(value: symbol | Array<EquipmentAsset>): SetEquipmentAction {
        return { value, actionType: ActionType.setEquipment };
    },
    setInspections(value: symbol | Array<Inspection>): SetInspectionsAction {
        return { value, actionType: ActionType.setInspections };
    },
    setLocations(value: symbol | Array<Geolocation>): SetLocationsAction {
        return { value, actionType: ActionType.setLocations };
    },
    addRecentClient(value: string): AddRecentClientAction {
        return { value, actionType: ActionType.addRecentClient };
    },
    clearRecentClients(): ClearRecentClientsAction {
        return { actionType: ActionType.clearRecentClients };
    },
    setBackgroundLoad(loadState: BackgroundLoadState | number, loadType: BackgroundLoadType): SetBackgroundLoadAction {
        return {
            value: {
                loadState,
                loadType,
            },
            actionType: ActionType.setBackgroundLoad,
        };
    },
};

export function getInitialState(
    apiServices: Services,
    appHistory: History,
    appEvents: Subject<AppMessage>,
): State {
    return {
        appEvents,
        apiServices,
        appHistory,
        initialized: Unset,
        backgroundLoad: {
            [BackgroundLoadType.Full]: BackgroundLoadState.NeverRun,
            [BackgroundLoadType.Recent]: BackgroundLoadState.NeverRun,
        },
        backgroundLoadLogs: [],
        assignments: Unset,
        categories: Unset,
        clients: Unset,
        currentUser: Unset,
        employees: Unset,
        equipment: Unset,
        inspections: Unset,
        locations: Unset,
        assignmentShares: Unset,
        clientContacts: Unset,

        makes: Unset,

        recentClients: new Set(),
        photosRepo: {
            cache: {},
            pending: [],
        },

        viewState: {
            clients: getInitialClientsPageFilter(),
            employees: getInitialSearchFilter(),
            assignments: getInitialAssignmentsPageFilter(),
            fleet: getInitialFleetPageFilter(),
        },
    };
}
