import { ascend, groupBy, sortWith } from 'ramda';
import classNames from 'classnames';
import React from 'react';
import {
    AxleAlignment, EquipmentAsset, Geolocation, Inspection, Severity, TrackTireInspection,
} from '../../types';
import { getSeverityBySubjectCondition } from '../../utils/lookups';
import Axle from '../InspectionDetail/Axle';
import { formatAssetTitle, formatCondition } from './shared';
import Subject from './Subject';
import { RenderIf } from '../../controls';
import Photo from '../../controls/Photo';

interface InspectedAssetProps {
    asset: EquipmentAsset,
    inspection: Inspection,
    locations: Array<Geolocation>,
    photos: Record<number, Blob>,
    assetPhoto: Blob | null,
}

export default function InspectedAsset(props: InspectedAssetProps) {
    const {
        asset,
        inspection,
        assetPhoto,
        locations,
        photos,
    } = props;
    const severity = getSeverityBySubjectCondition(inspection.condition);
    const assetLocation = locations.find((item) => item.id === (asset.currentLocationId ?? -1));

    const axles = groupBy((subj) => subj.axle || '(unknown)', inspection.subjects);
    const subjectCountByAxleAndSide = Object
        .keys(axles)
        .reduce((memo: Record<string, number>, axleName) => {
            const subjects = axles[axleName];

            const leftSubjects = subjects.filter((subj) => subj.position.alignment === AxleAlignment.Left);
            const rightSubjects = subjects.filter((subj) => subj.position.alignment === AxleAlignment.Right);

            return {
                ...memo,
                [`${axleName}:${AxleAlignment.Left}`]: leftSubjects.length,
                [`${axleName}:${AxleAlignment.Right}`]: rightSubjects.length,
            };
        }, {});
    const axlesList = Object
        .keys(axles)
        .sort()
        .map(
            (name) => {
                const subjects = axles[name];

                return (
                    <Axle
                        isReadOnly
                        key={name}
                        name={name}
                        subjects={subjects}
                        selectedSubject={null}
                        setSelectedSubject={null}
                        addSubjectOnSide={() => undefined}
                    />
                );
            },
        );

    const sortedSubjects = sortWith([
        ascend((s: TrackTireInspection) => s.axle),
        ascend((s: TrackTireInspection) => s.position.axlePosition),
        ascend((s: TrackTireInspection) => s.position.alignment),
    ], inspection.subjects);
    const subjectsList = sortedSubjects
        .map((subject) => {
            const count = subjectCountByAxleAndSide[`${subject.axle}:${subject.position.alignment}`];

            return <Subject photos={photos} subject={subject} subjectCount={count} />;
        });

    return (
        <section className="asset">
            <section className="vehicleAttributes">
                <h3 id={`${inspection.id}`}>
                    <div className={classNames('severity', Severity[severity])} />
                    {formatAssetTitle(asset)}
                </h3>
                <RenderIf condition={Boolean(assetPhoto)}>
                    <Photo blob={assetPhoto as Blob} />
                </RenderIf>
                <dl>
                    <dd className="condition">Condition:</dd>
                    <dt>{formatCondition(inspection.condition)}</dt>

                    <dd className="hours">Hours:</dd>
                    <dt>{inspection.hours}</dt>

                    <dd className="location">Location:</dd>
                    <dt>{assetLocation?.name}</dt>

                    <dd className="vin">VIN:</dd>
                    <dt>{asset.vin}</dt>

                    <dd className="idNum">ID Num:</dd>
                    <dt>{asset.internalIdentifier}</dt>
                </dl>
                <dl className="notes">
                    <dd>Notes:</dd>
                    <dt>{inspection.notes}</dt>
                </dl>
            </section>
            <section className="inspectionSubjects axlesList">{axlesList}</section>
            {subjectsList}
        </section>
    );
}
