import React from 'react';
import NamedInput from './NamedInput';

interface GeoProps {
    canEdit: boolean,
    latitude: number | null | undefined,
    longitude: number | null | undefined,
    setLatitude: (lat: number) => void,
    setLongitude: (lng: number) => void,
    handleGeolocation: () => void,
}

export default function Geo(props: GeoProps) {
    const {
        canEdit,
        latitude,
        longitude,
        setLatitude,
        setLongitude,
        handleGeolocation,
    } = props;

    return (
        <div className="geo">
            <section className="coords">
                <NamedInput
                    name="latitude"
                    type="number"
                    label="Latitude"
                    value={latitude}
                    onChange={canEdit
                        ? (event) => setLatitude(parseFloat(event.target.value))
                        : undefined}
                />
                <NamedInput
                    name="longitude"
                    type="number"
                    label="Longitude"
                    value={longitude}
                    onChange={canEdit
                        ? (event) => setLongitude(parseFloat(event.target.value))
                        : undefined}
                />
            </section>
            <button type="button" className="locate" onClick={handleGeolocation}>GEO</button>
        </div>
    );
}
