import Services from '../services';
import { Client, Geolocation } from '../types';

export default async function createNewClient(
    apiServices: Services,
    client: Client,
    location: Geolocation,
): Promise<{ client: Client, location: Geolocation }> {
    // This is currently necessary because there's no single server-side endpoint to handle this
    // behavior at this time:

    // Step 1: provision client record
    const newClient = await apiServices.createClient(client);

    if (!newClient.id) {
        throw new Error('Should not happen');
    }

    // Step 2: provision location record
    const newLocation = await apiServices.createLocation({
        ...location,
        clientId: newClient.id,
    });

    if (!newLocation.id) {
        throw new Error('Should not happen');
    }

    // Step 3: assign newly created location to client
    const clientUpdate = apiServices.updateClient({
        ...newClient,
        locationId: newLocation.id,
    });

    // Step 4: return newly created client and location information
    return clientUpdate
        .then((updatedClient) => ({
            client: updatedClient,
            location: newLocation,
        }));
}
