import React from 'react';
import classNames from 'classnames';
import RenderIf from './RenderIf';

interface Props {
    className?: string,
    childId: string,
    children: React.ReactChildren | React.ReactNode,
    label: string,
    error?: string | undefined,
}

export default function LabelWrapper(props: Props) {
    const {
        className, childId, children, label, error,
    } = props;

    return (
        <div className={classNames(className, 'labelWrapper', { hasError: error })}>
            <label htmlFor={childId}>
                <span>{label}</span>
                <RenderIf condition={error !== undefined}>
                    <span className="error">{error}</span>
                </RenderIf>
                {children}
            </label>
        </div>
    );
}
LabelWrapper.defaultProps = { className: '', error: undefined };
