import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { getCurrentRouteForTopLevel } from '../../../utils/try-identify-parent-route';
import { ClientFilter, StatusFilter, TopLevelPage } from '../../../types';

const IS_ROOT_ROUTE = /^\/(\w*)$/;

export default function performFooterNavigation(dispatch: Dispatch<Action>, getState: () => State) {
    return (desiredEntity: TopLevelPage) => {
        const state = getState();
        const { route } = getCurrentRouteForTopLevel(desiredEntity, state);

        if (IS_ROOT_ROUTE.test(route)) {
            // special case: we're going up to the top, flush out existing filters/selections
            switch (desiredEntity) {
                case TopLevelPage.Employees:
                    dispatch(actionCreators.setEmployeesViewStateSearch(''));
                    dispatch(actionCreators.setEmployeesViewStateSelection(null));
                    break;
                case TopLevelPage.Assignments:
                    dispatch(actionCreators.setAssignmentsViewStateFilter(StatusFilter.Assigned));
                    dispatch(actionCreators.setAssignmentsViewStateSearch(''));
                    dispatch(actionCreators.setAssignmentsViewStateSelection(null));
                    dispatch(actionCreators.setAssignmentsViewStateDateStart(null));
                    dispatch(actionCreators.setAssignmentsViewStateDateEnd(null));
                    break;
                case TopLevelPage.Clients:
                    dispatch(actionCreators.setClientsViewStateSelection(null));
                    dispatch(actionCreators.setClientsViewStateSearch(''));
                    dispatch(actionCreators.setClientsViewStateFilter(ClientFilter.AllClients));
                    dispatch(actionCreators.setFleetViewStatePageType(null));
                    dispatch(actionCreators.setFleetViewStateSelection(null));
                    dispatch(actionCreators.setFleetViewStateSearch(''));
                    break;
                default:
                    break;
            }
        }

        state.appHistory.push(route);
    };
}
