import React from 'react';
import classNames from 'classnames';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import { AppHeader } from '../../controls/index';
import { Assignment, Employee, PageFilters } from '../../types';
import List, { ListItem } from '../../controls/List';
import Loading from '../Loading';
import { State } from '../../state/application';
import isConsideredOpen from '../../utils/is-considered-open';
import Search from '../../controls/Search';
import { UNKNOWN } from '../../constants';

interface Props {
    getApplicationState: () => State,
    loading: {
        employees: boolean,
        assignments: boolean,
    },
    employees: Array<Employee>,
    assignments: Array<Assignment>,
    match: routerMatch<ExtractRouteParams<string, string>>,
    pageFilters: PageFilters,
}

export default function Employees(props: Props) {
    const {
        getApplicationState,
        loading,
        employees,
        assignments,
        match,
        pageFilters,
    } = props;

    if (loading.employees || loading.assignments) {
        return <Loading />;
    }

    const employeeItems: Array<ListItem> = employees
        .filter((employee) => (pageFilters.search
            ? employee.name.toLowerCase().includes(pageFilters.search.toLowerCase())
            : true))
        .map((employee) => {
            const employeeAssignments = assignments.filter((asg) => asg.employeeId === employee.id);
            const activeAssignments = employeeAssignments.filter(
                (asg) => isConsideredOpen(asg.status),
            );
            const isSelected = match?.params.employeeId === `${employee.id}`;

            const assignmentsLabel = employeeAssignments.length
                ? `${activeAssignments.length} of ${employeeAssignments.length} Assignments`
                : 'no assignments';

            return {
                id: employee.id ?? UNKNOWN,
                className: classNames({ isSelected }),
                title: employee.name,
                children: (
                    <>
                        <span className="name">{employee.name}</span>
                        <span className="assignmentCount">{assignmentsLabel}</span>
                    </>
                ),
                route: `/employees/${employee.id}`,
            };
        });

    return (
        <main className="employeesList">
            <AppHeader getApplicationState={getApplicationState} hideBackButton>
                <></>
            </AppHeader>

            <div className="leftPanel">
                <h1>Employees</h1>

                <Search
                    value={pageFilters.search}
                    searchUpdate={pageFilters.onSearchChanged}
                />

                <List
                    groupByFirstLetter
                    className="employees whitebars"
                    items={employeeItems}
                    itemClass="employee"
                />
            </div>
        </main>
    );
}
