import React, { ReactElement } from 'react';
import NoResults from './NoResults';

const DEFAULT_SAFELIST_PROPS = { passedClasses: '', noResultsString: 'No results available' };

interface NoResultsSafeListProps {
    passedClasses?: string,
    listResults: Array<ReactElement> | ReactElement | null,
    noResultsString?: string
}

export default function NoResultsSafeList(props: NoResultsSafeListProps) {
    const { listResults, passedClasses, noResultsString } = props;
    const isPopulatedArray = listResults instanceof Array && listResults.length;
    const isSingle = listResults && !(listResults instanceof Array);

    return isPopulatedArray || isSingle
        ? (
            <ul className={passedClasses}>
                {listResults}
            </ul>
        )
        : <NoResults defaultString={noResultsString} />;
}

NoResultsSafeList.defaultProps = DEFAULT_SAFELIST_PROPS;
