import React, { useEffect } from 'react';

interface ScrollerProps {
    history: object
}

export default function Scroller(props: ScrollerProps) {
    const { history } = props;
    // @ts-ignore
    const path = history.location.pathname;

    const appEl = document.querySelector('.App');

    useEffect(() => {
        if (appEl) {
            appEl.scrollTo({ top: -10, behavior: 'auto' });
        }
    }, [appEl, path]);

    return (
        <></>
    );
}

// Scroller.defaultProps = { history: {} };
