import React from 'react';
import { RenderIf } from '.';

interface Props {
    value: string,
    searchUpdate: Function,
}

export default function Search(props: Props) {
    const { value, searchUpdate } = props;

    return (
        <div className="search-wrapper">
            <input
                type="search"
                placeholder="Search"
                value={value}
                onChange={(event) => searchUpdate(event.target.value)}
            />
            <RenderIf condition={(value !== '')}>
                <button
                    onClick={() => searchUpdate('')}
                    type="button"
                    className="clearSearch"
                >X
                </button>
            </RenderIf>
        </div>
    );
}
