import Services from '../services';

export const convertImageBlobToDataUrl = (
    imageBlob: Blob,
) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
        resolve(reader.result as string);
    });
    reader.addEventListener('error', () => reject(reader.error));
    reader.addEventListener('abort', () => reject(new Error('Aborted image load')));

    reader.readAsDataURL(imageBlob);
});

export default function getImageSrc(imageId: string, apiServices: Services) {
    return apiServices.getPhoto(imageId)
        .then(convertImageBlobToDataUrl);
}
