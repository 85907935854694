import React, { ChangeEventHandler, ReactElement } from 'react';
import classNames from 'classnames';
import LabelWrapper from './LabelWrapper';

const DEFAULT_NAMED_INPUT_PROPS = { className: '', valid: true };

export interface NamedInputProps {
    className?: string,
    valid?: boolean,
    name: string,
    type: 'text' | 'textarea' | 'number' | 'checkbox' | 'radio' | 'password' | 'email',
    label: string,
    value: any,
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined,
}

interface TextInputProps {
    type: 'text' | 'number' | 'password' | 'email',
    id: string,
    placeholder: string,
    value: any,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined,
}
const TextInput = (props: TextInputProps) => {
    const {
        type, id, placeholder, value, onChange,
    } = props;

    return (
        <input
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={onChange === undefined ? 'disabled' : ''}
            readOnly={onChange === undefined}
        />
    );
};

interface TextAreaInputProps {
    id: string,
    placeholder: string,
    value: any,
    onChange: ChangeEventHandler<HTMLTextAreaElement> | undefined,
}
const TextAreaInput = (props: TextAreaInputProps) => {
    const {
        id, placeholder, value, onChange,
    } = props;

    return (
        <textarea
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={onChange === undefined ? 'disabled' : ''}
            readOnly={onChange === undefined}
        />
    );
};

interface CheckboxInputProps {
    id: string,
    label: string,
    type: 'radio' | 'checkbox',
    placeholder: string,
    value: boolean,
    onChange: ChangeEventHandler<HTMLInputElement> | undefined,
}

const CheckboxInput = (props: CheckboxInputProps) => {
    const {
        id,
        label,
        placeholder,
        value,
        type,
        onChange,
    } = props;

    return (
        <input
            type={type}
            title={label}
            id={id}
            placeholder={placeholder}
            checked={value}
            onChange={onChange}
            className={onChange === undefined ? 'disabled' : ''}
            readOnly={onChange === undefined}
        />
    );
};

export default function NamedInput(props: NamedInputProps): ReactElement {
    const {
        className: initialClassName,
        valid,
        name,
        label,
        value,
        onChange,
        type,
    } = props;

    const id = `namedInput${name}`;

    let inputElement;

    switch (type) {
        case 'checkbox':
        case 'radio':
            inputElement = (
                <CheckboxInput
                    id={id}
                    label={label}
                    type={type}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                />
            );
            break;
        case 'textarea':
            inputElement = (
                <TextAreaInput
                    id={id}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                />
            );
            break;
        case 'email':
        case 'text':
        default:
            inputElement = (
                <TextInput
                    type={type}
                    id={id}
                    placeholder={label}
                    value={value}
                    onChange={onChange}
                />
            );
            break;
    }

    const className = classNames('field', `namedInput${name}`, type, initialClassName, {
        checked: type === 'checkbox' && value,
        selected: type === 'radio' && value,
        invalid: !valid,
    });

    return (
        <LabelWrapper
            className={className}
            childId={id}
            label={label}
        >{inputElement}
        </LabelWrapper>
    );
}
NamedInput.defaultProps = DEFAULT_NAMED_INPUT_PROPS;
