import React, { useCallback, useEffect, useState } from 'react';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import NamedInput from '../controls/NamedInput';
import { Client, Geolocation } from '../types';
import Loading from './Loading';
import Services from '../services';
import AppHeader from '../controls/AppHeader';
import Geo from '../controls/Geo';
import { GEOLOCATION_OPTIONS, UNKNOWN } from '../constants';
import { State } from '../state/application';

const DEFAULT_LOCATION: Geolocation = {
    city: '',
    clientId: UNKNOWN,
    lat: 0,
    lng: 0,
    name: '',
    notes: '',
    postalCode: '',
    state: '',
    street1: '',
    street2: '',
};

interface Props {
    onError: (error: string) => void,
    clients: Array<Client>,
    locations: Array<Geolocation>,
    getApplicationState: () => State,
    loading: {
        clients: boolean,
        locations: boolean,
    },
    onLocationUpdated: (location: Geolocation) => void,
    onLocationDeleted: (locationId: string) => void,
    apiServices: Services,
    match: routerMatch<ExtractRouteParams<string, string>>,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function LocationEdit(props: Props) {
    const {
        match,
        clients,
        locations,
        loading,
        onLocationUpdated,
        onLocationDeleted,
        apiServices,
        getApplicationState,
        onError,
    } = props;
    const { locationId, clientId } = match.params;

    const [initializedLocation, setInitializedLocation] = useState<string | null>(null);
    const [canEdit, setCanEdit] = useState(false);

    const [name, setName] = useState(DEFAULT_LOCATION.name);
    const [street1, setStreet1] = useState(DEFAULT_LOCATION.street1);
    const [street2, setStreet2] = useState(DEFAULT_LOCATION.street2);
    const [city, setCity] = useState(DEFAULT_LOCATION.city);
    const [addressState, setAddressState] = useState(DEFAULT_LOCATION.state);
    const [postalCode, setPostalCode] = useState(DEFAULT_LOCATION.postalCode);
    const [latitude, setLatitude] = useState(DEFAULT_LOCATION.lat);
    const [longitude, setLongitude] = useState(DEFAULT_LOCATION.lng);

    useEffect(() => {
        if (initializedLocation !== locationId && !loading.locations && !loading.clients) {
            const location = locationId === 'new'
                ? DEFAULT_LOCATION
                : locations.find((loc) => `${loc.id}` === locationId);
            const client = clients.find((cli) => `${cli.id}` === clientId);

            if (!location || !client) {
                throw new Error('Should not happen.');
            }

            setName(location.name);
            setStreet1(location.street1);
            setStreet2(location.street2);
            setCity(location.city);
            setAddressState(location.state);
            setPostalCode(location.postalCode);
            setLatitude(location.lat);
            setLongitude(location.lng);

            setInitializedLocation(locationId ?? '');
            setCanEdit(client.locationId !== location.id);
        }
    }, [
        initializedLocation,
        loading.locations,
        locations,
        locationId,
        loading.clients,
        clients,
        clientId,
    ]);

    const handleGeolocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                setLatitude(coords.latitude);
                setLongitude(coords.longitude);
            },
            () => {
                onError('Failed to retrieve location information.');
            },
            GEOLOCATION_OPTIONS,
        );
    }, [onError]);

    const onDelete = useCallback(() => {
        // eslint-disable-next-line no-alert
        if (initializedLocation && window.confirm('Are you sure you wish to delete this location?')) {
            apiServices.deleteLocation(initializedLocation)
                .then(() => {
                    onLocationDeleted(initializedLocation);
                    getApplicationState().appHistory.replace(`/clients/${clientId}`);
                })
                .catch((e) => onError(e.message));
        }
    }, [
        apiServices,
        clientId,
        getApplicationState,
        initializedLocation,
        onLocationDeleted,
        onError,
    ]);
    const onSave = useCallback(() => {
        const location = locationId === 'new'
            ? { ...DEFAULT_LOCATION, clientId: clientId ?? UNKNOWN }
            : locations.find((loc) => `${loc.id}` === locationId);

        if (!location) {
            throw new Error('Should not happen.');
        }

        const toSave: Geolocation = {
            ...location,
            name,
            street1,
            street2,
            city,
            state: addressState,
            postalCode,
            lat: latitude,
            lng: longitude,
        };

        const updateMethod = (locationId === 'new' ? apiServices.createLocation : apiServices.updateLocation)
            .bind(apiServices);

        updateMethod(toSave)
            .then((saved) => {
                onLocationUpdated(saved);
                getApplicationState().appHistory.replace(`/clients/${clientId}`);
            })
            .catch((e) => onError(e.message));
    }, [
        onError,
        addressState,
        apiServices,
        city,
        clientId,
        getApplicationState,
        locationId,
        latitude,
        locations,
        longitude,
        name,
        postalCode,
        street1,
        street2,
        onLocationUpdated,
    ]);

    if (loading.locations) {
        return <Loading />;
    }

    // const selectedClient = clients.find((items) => `${items.id}` === clientId);
    const isNew = locationId === 'new';

    const saveButton = canEdit
        ? (
            <button type="button" onClick={() => onSave()}>
                { isNew ? 'Create' : 'Update' }
            </button>
        )
        : null;
    const deleteButton = locationId === 'new' || !canEdit
        ? null
        : (
            <button
                type="button"
                onClick={() => onDelete()}
                className="risky"
            >Delete
            </button>
        );
    const editNotification = canEdit
        ? null
        : <strong>To make changes, please submit a client update request.</strong>;
    const title = isNew ? 'Add New Location' : name;

    return (
        <div className="locationEdit">
            <main>
                <AppHeader getApplicationState={getApplicationState}>
                    <></>
                </AppHeader>

                <h1>{title}</h1>

                <form>
                    <NamedInput
                        name="name"
                        type="text"
                        label="Name"
                        value={name}
                        onChange={canEdit
                            ? (event) => setName(event.target.value)
                            : undefined}
                    />
                    <NamedInput
                        name="street1"
                        type="text"
                        label="Address Line 1"
                        value={street1}
                        onChange={canEdit
                            ? (event) => setStreet1(event.target.value)
                            : undefined}
                    />
                    <NamedInput
                        name="street2"
                        type="text"
                        label="Address Line 2"
                        value={street2}
                        onChange={canEdit
                            ? (event) => setStreet2(event.target.value)
                            : undefined}
                    />
                    <NamedInput
                        name="city"
                        type="text"
                        label="City"
                        value={city}
                        onChange={canEdit
                            ? (event) => setCity(event.target.value)
                            : undefined}
                    />
                    <NamedInput
                        name="state"
                        type="text"
                        label="State"
                        value={addressState}
                        onChange={canEdit
                            ? (event) => setAddressState(event.target.value)
                            : undefined}
                    />
                    <NamedInput
                        name="postalCode"
                        type="text"
                        label="ZIP"
                        value={postalCode}
                        onChange={canEdit
                            ? (event) => setPostalCode(event.target.value)
                            : undefined}
                    />

                    <Geo {...{
                        canEdit, latitude, longitude, setLatitude, setLongitude, handleGeolocation,
                    }}
                    />

                    <div className="actions">
                        {saveButton}
                        {deleteButton}
                        {editNotification}
                    </div>
                </form>
            </main>
        </div>
    );
}
