import React, {
    FormEvent, useCallback, useEffect, useState,
} from 'react';
import { Link } from 'react-router-dom';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import { Client, Geolocation } from '../types';
import NamedInput, { NamedInputProps } from '../controls/NamedInput';
import { Actions, AppHeader } from '../controls';
import Services from '../services';
import Geo from '../controls/Geo';
import { GEOLOCATION_OPTIONS } from '../constants';
import { State } from '../state/application';

interface DeltaInputProps extends NamedInputProps {
    originalValue: string | null,
}

function DeltaInput(props: DeltaInputProps) {
    const formatMaybeEmpty = (val: any) => (typeof val === 'string' ? `${val || '(empty)'}` : val ?? '(empty)');

    const deltaNotice = (props.originalValue ?? '') === (props.value ?? '')
        ? null
        : (
            <div className="different">
                <span>Previous value was </span>
                <span className="previousValue">{formatMaybeEmpty(props.originalValue)}</span>
            </div>
        );

    return (
        <div className="deltaInput">
            <NamedInput
                type={props.type}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                label={props.label}
            />
            {deltaNotice}
        </div>
    );
}

interface Props {
    apiServices: Services,
    getApplicationState: () => State,
    clients: Array<Client>,
    locations: Array<Geolocation>,
    onError: (message: string) => void,
    onLocationUpdated: (location: Geolocation) => void,
    onClientUpdated: (client: Client) => void,
    match: routerMatch<ExtractRouteParams<string, string>>,
}

export default function EditClient(props: Props) {
    const {
        apiServices,
        getApplicationState,
        onError,
        onClientUpdated,
        onLocationUpdated,
        match,
        clients,
        locations,
    } = props;

    const [initialized, setInitialized] = useState(false);
    const [name, setName] = useState<string>('');
    const [address1, setAddress1] = useState<string | null>(null);
    const [address2, setAddress2] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [state, setState] = useState<string | null>(null);
    const [postalCode, setPostalCode] = useState<string | null>(null);
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const isValid = Boolean(name);
    const client = clients.find((item) => `${item.id}` === match.params.clientId);
    const primaryLocation = locations.find((loc) => loc.id === client?.locationId);

    const handleSubmission = useCallback((event: FormEvent) => {
        event.preventDefault();

        if (isValid && client && primaryLocation) {
            const clientUpdate = apiServices.updateClient({
                ...client,
                name,
                email,
                phoneNumber: phone,
            });
            const locationUpdate = clientUpdate.then(() => apiServices.updateLocation({
                ...primaryLocation,
                street1: address1 ?? undefined,
                street2: address2 ?? undefined,
                city: city ?? undefined,
                state: state ?? undefined,
                postalCode: postalCode ?? '',
                lat: latitude,
                lng: longitude,
            }));

            Promise.all([clientUpdate, locationUpdate])
                .then(([updatedClient, updatedLocation]) => {
                    onClientUpdated(updatedClient);
                    onLocationUpdated(updatedLocation);

                    getApplicationState().appHistory.replace(`/clients/${client.id}`);
                });
        } else {
            if (!isValid) {
                onError('The client form is not filled correctly and cannot be submitted.');
            }
            if (!client) {
                onError('An unexpected error occurred with the client record being modified.');
            }
            if (!primaryLocation) {
                onError('This record cannot be saved at this time (E01773).');
            }
        }
    }, [
        address1,
        address2,
        apiServices,
        city,
        client,
        email,
        getApplicationState,
        isValid,
        latitude,
        longitude,
        name,
        onClientUpdated,
        onError,
        onLocationUpdated,
        phone,
        postalCode,
        primaryLocation,
        state,
    ]);
    const handleGeolocation = useCallback(() => {
        navigator.geolocation.getCurrentPosition(
            ({ coords }) => {
                setLatitude(coords.latitude);
                setLongitude(coords.longitude);
            },
            () => {
                onError('Failed to retrieve location information.');
            },
            GEOLOCATION_OPTIONS,
        );
    }, [onError]);

    useEffect(() => {
        if (!initialized && client && primaryLocation) {
            setInitialized(true);

            setName(client.name ?? '');
            setAddress1(primaryLocation.street1 ?? '');
            setAddress2(primaryLocation.street2 ?? '');
            setCity(primaryLocation.city ?? '');
            setState(primaryLocation.state ?? '');
            setPostalCode(primaryLocation.postalCode ?? '');
            setLatitude(primaryLocation.lat ?? null);
            setLongitude(primaryLocation.lng ?? null);
            setPhone(client.phoneNumber ?? '');
            setEmail(client.email ?? '');
        }
    }, [
        client,
        initialized,
        primaryLocation,
    ]);

    if (!client) {
        onError('You have attempted to edit a client that could not be found.');
        getApplicationState().appHistory.replace(`/clients/${match.params.clientId}`);
    }
    if (!primaryLocation) {
        onError('An error occurred while editing this client (primary location not set correctly)');
        getApplicationState().appHistory.replace(`/clients/${match.params.clientId}`);
    }

    return (
        <div className="editClient">
            <main>
                <AppHeader getApplicationState={getApplicationState} title={client?.name}>
                    <></>
                </AppHeader>
                <h1>Edit Client</h1>
                <form method="POST" onSubmit={handleSubmission}>
                    <DeltaInput
                        type="text"
                        name="name"
                        value={name}
                        originalValue={client?.name ?? null}
                        onChange={(event) => setName(event.target.value)}
                        label="Client Name"
                    />

                    <div className="title-divider"><span>Address</span></div>
                    <DeltaInput
                        type="text"
                        name="address1"
                        value={address1}
                        originalValue={primaryLocation?.street1 ?? null}
                        onChange={(event) => setAddress1(event.target.value)}
                        label="Address Line 1"
                    />
                    <DeltaInput
                        type="text"
                        name="address2"
                        value={address2}
                        originalValue={primaryLocation?.street2 ?? null}
                        onChange={(event) => setAddress2(event.target.value)}
                        label="Address Line 2"
                    />
                    <DeltaInput
                        type="text"
                        name="city"
                        value={city}
                        originalValue={primaryLocation?.city ?? null}
                        onChange={(event) => setCity(event.target.value)}
                        label="City"
                    />
                    <DeltaInput
                        type="text"
                        name="state"
                        value={state}
                        originalValue={primaryLocation?.state ?? null}
                        onChange={(event) => setState(event.target.value)}
                        label="State"
                    />
                    <DeltaInput
                        type="text"
                        name="postalCode"
                        value={postalCode}
                        originalValue={primaryLocation?.postalCode ?? null}
                        onChange={(event) => setPostalCode(event.target.value)}
                        label="Zip"
                    />
                    <Geo {...{
                        canEdit: true, latitude, longitude, setLatitude, setLongitude, handleGeolocation,
                    }}
                    />

                    <div className="title-divider"><span>Contact Info</span></div>
                    <DeltaInput
                        type="text"
                        name="phone"
                        value={phone}
                        originalValue={client?.phoneNumber ?? null}
                        onChange={(event) => setPhone(event.target.value)}
                        label="Phone"
                    />
                    <DeltaInput
                        type="text"
                        name="email"
                        value={email}
                        originalValue={client?.email ?? null}
                        onChange={(event) => setEmail(event.target.value)}
                        label="Email"
                    />
                    <Actions>
                        <button disabled={!isValid} type="submit">Save changes</button>
                        <Link to={`/clients/${client?.id}`} className="risky">Cancel</Link>
                    </Actions>
                </form>
            </main>
        </div>
    );
}
