import React, { ReactChildren, ReactNode } from 'react';

interface Props {
    children: ReactChildren | ReactNode,
}

export default function Actions(props: Props) {
    const { children } = props;

    return <div className="actions">{children}</div>;
}
