import React, {
    useCallback,
    useEffect,
    useRef,
    useReducer, ChangeEvent, FormEvent, FormEventHandler, ChangeEventHandler, MouseEventHandler,
} from 'react';
import Services from '../services';
import { ApiError, ApiErrorType, User } from '../types';

export interface Props {
    services: Services,
    onLogin: (user: User) => void,
    onError: (message: string) => void,
}

interface ForgotPasswordFormProps {
    focusRef: React.RefObject<HTMLInputElement>,
    handleSubmit: FormEventHandler<HTMLFormElement>,
    onCancel: () => void,
    onErrorDismissed: () => void,
    username: string,
    onUsernameChanged: ChangeEventHandler<HTMLInputElement>,
    errorMessage: string,
}

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
    const {
        errorMessage,
        focusRef,
        handleSubmit,
        onUsernameChanged,
        onCancel,
        onErrorDismissed,
        username,
    } = props;

    const error = errorMessage && (
        <section className="errorMessage">
            <div className="modalContent">
                <span>{errorMessage}</span>
                <button type="button" onClick={onErrorDismissed}>Ok</button>
            </div>
        </section>
    );

    return (
        <form className="login forgot-password" onSubmit={handleSubmit}>
            <label htmlFor="username">
                <span>Enter Your Username</span>
                <input
                    ref={focusRef}
                    id="username"
                    name="username"
                    value={username}
                    onChange={onUsernameChanged}
                />
            </label>
            {error}
            <div className="form-buttons">
                <button
                    type="submit"
                    className="submit-reset"
                >Reset Password
                </button>
                <button
                    type="button"
                    className="cancel"
                    onClick={onCancel}
                >Cancel
                </button>
            </div>
        </form>
    );
}

export interface LoginFormProps {
    focusRef: React.RefObject<HTMLInputElement>,
    handleSubmit: FormEventHandler<HTMLFormElement>,
    username: string,
    onUsernameChanged: ChangeEventHandler<HTMLInputElement>,
    password: string,
    onPasswordChanged: ChangeEventHandler<HTMLInputElement>,
    errorMessage: string,
    isValid: boolean,
    submitting: boolean,
    onForgotPassword: MouseEventHandler<HTMLButtonElement>,
}

function LoginForm(props: LoginFormProps) {
    const {
        focusRef,
        handleSubmit,
        username,
        onUsernameChanged,
        password,
        onPasswordChanged,
        errorMessage,
        isValid,
        submitting,
        onForgotPassword,
    } = props;
    const error = errorMessage && <section className="errorMessage">{errorMessage}</section>;

    return (
        <form className="login" onSubmit={handleSubmit}>
            <label htmlFor="username">
                <span>Username</span>
                <input
                    ref={focusRef}
                    id="username"
                    name="username"
                    value={username}
                    onChange={onUsernameChanged}
                />
            </label>
            <label htmlFor="password">
                Password
                <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onPasswordChanged}
                />
            </label>

            {error}

            <div className="form-buttons">
                <button
                    type="submit"
                    className="login"
                    disabled={!isValid || submitting}
                >Login
                </button>
                <button
                    type="button"
                    className="forgot-password"
                    onClick={onForgotPassword}
                >Forgot Password?
                </button>
            </div>
        </form>
    );
}

type State = {
    submitting: any,
    username: string,
    password: string,
    errorMessage: string,
    showForgotPassword: any,
};

type Action =
    | { type: 'submitting', value: any }
    | { type: 'username', value: string }
    | { type: 'password', value: string }
    | { type: 'showForgotPassword', value: any }
    | { type: 'errorMessage', value: string };

function reducer(state: State, action: Action): State {
    const newState = { ...state };
    newState[action.type] = action.value;
    return newState;
}

const initState: State = {
    submitting: false,
    username: '',
    password: '',
    errorMessage: '',
    showForgotPassword: false,
};

export default function Login(props: Props) {
    const { onError: handleApplicationError } = props;
    const [state, dispatch] = useReducer(reducer, initState);

    const focusRef = useRef<HTMLInputElement>(null);
    const { onLogin, services } = props;

    const isValid = Boolean(state.username) && Boolean(state.password);

    useEffect(() => {
        if (focusRef?.current) {
            focusRef.current.focus();
        }
    }, [focusRef]);

    const clearError = () => dispatch({ type: 'errorMessage', value: '' });

    const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({ type: 'submitting', value: true });
        try {
            await services.tryLogin(state.username, state.password);

            const currentUser = services.getCurrentUser();

            if (!currentUser) {
                throw new Error('Failed to identify current user');
            }

            dispatch({ type: 'submitting', value: false });

            onLogin(currentUser);
        } catch (err) {
            dispatch({ type: 'submitting', value: false });

            if (err instanceof ApiError && (err as ApiError).type === ApiErrorType.InvalidLogin) {
                dispatch({ type: 'errorMessage', value: 'Username or Password not recognized.' });
            } else {
                handleApplicationError(err.message);
            }
        }
    }, [handleApplicationError, services, state, onLogin]);

    const forgotPasswordClicked = useCallback(async () => {
        dispatch({ type: 'showForgotPassword', value: true });
        dispatch({ type: 'errorMessage', value: '' });
    }, []);
    const forgotPasswordCancelled = useCallback(async () => {
        dispatch({ type: 'showForgotPassword', value: false });
        dispatch({ type: 'errorMessage', value: '' });
    }, []);

    const onForgotPasswordSubmitted = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch({
            type: 'errorMessage',
            value: 'Please contact your administrator to reset your password.',
        });
    }, []);

    const onUsernameChanged = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: 'username', value: event.target.value });
    };
    const onPasswordChanged = (event: ChangeEvent<HTMLInputElement>) => dispatch({
        type: 'password',
        value: event.target.value,
    });
    const form = state.showForgotPassword
        ? (
            <ForgotPasswordForm
                onErrorDismissed={clearError}
                errorMessage={state.errorMessage}
                focusRef={focusRef}
                handleSubmit={onForgotPasswordSubmitted}
                username={state.username}
                onUsernameChanged={onUsernameChanged}
                onCancel={forgotPasswordCancelled}
            />
        )
        : (
            <LoginForm
                focusRef={focusRef}
                handleSubmit={handleSubmit}
                username={state.username}
                onUsernameChanged={onUsernameChanged}
                password={state.password}
                onPasswordChanged={onPasswordChanged}
                errorMessage={state.errorMessage}
                isValid={isValid}
                submitting={state.submitting}
                onForgotPassword={forgotPasswordClicked}
            />
        );

    return (
        <div className="login-wrapper">

            <div className="login-wrapper--int">
                <img alt="TreadRX" src="logo.svg" />

                {form}
            </div>

            <div className="login-footer">
                <img alt="CFI Tire Stamp" src="./CFITireLogo-Mark.png" />
            </div>
        </div>
    );
}
