import { Assignment, EquipmentAsset, Inspection } from '../types';
import isConsideredOpen from './is-considered-open';

export default function findUnboundEquipmentForClient(
    clientId: string,
    assignments: Array<Assignment>,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
): Array<EquipmentAsset> {
    const clientAssignments = assignments
        .filter((item) => item.clientId === clientId)
        .filter((asg) => isConsideredOpen(asg.status));

    if (!clientAssignments.length) {
        return [];
    }

    const clientInspections = inspections
        .filter((insp) => clientAssignments.some(
            (asg) => asg.id === insp.assignmentId,
        ));

    return equipment
        .filter((item) => item.clientId === clientId)
        .filter((asset) => !clientInspections.some(
            (asg) => asg.equipmentId === asset.id,
        ));
}
