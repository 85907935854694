import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { Inspection } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function upsertInspection(dispatch: Dispatch<Action>, getState: () => State) {
    return (inspection: Inspection) => {
        const { inspections } = getState();

        if (inspections === Unset || inspections === Loading) {
            return;
        }

        dispatch(actionCreators.setInspections([
            ...(inspections as Array<Inspection>).filter(
                (item) => item.id !== inspection.id,
            ),
            inspection,
        ]));
    };
}
