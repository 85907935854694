import { InspectionStatus, Severity, SubjectCondition } from './types';

/**
 * The common date format used throughout the TreadRx application (to be used as a format string
 * for DayJS).
 */
export const DATE_FORMAT = 'M/D/YYYY';

export const UNKNOWN = 'unknown';

/**
 * Defines the base URI used for API requests.  By default, this will
 * pull from the environment variable `REACT_APP_API_ENDPOINT`.
 */
export const API_BASE_URI = process.env.REACT_APP_API_ENDPOINT;
export const VERSION = process.env.REACT_APP_VERSION;
export const GEOLOCATION_OPTIONS = {};
export const APPLE_MAPS_BASE_URI = 'http://maps.apple.com/';
export const MS_PER_DAY = 86400 * 1000;
export const MS_PER_MINUTE = 60 * 1000;
export const MS_BETWEEN_BACKGROUND_LOAD_ATTEMPTS = 15 * 1000;
export const ESC_KEY = 'Escape';
export const ADMIN = 'admin';
export const EMPTY_NOTES_READ_ONLY = '(no notes supplied)';
export const NEW = 'new';
export const LOCAL_DATE_TIME_FORMAT = 'l LT';

/**
 * For users whose full background refresh interval have not been previously defined, this value operates
 * as a stand-in default.
 */
export const DEFAULT_FULL_BACKGROUND_LOAD_DURATION_MINUTES = 20;

/**
 * For users whose recent changes background refresh interval have not been previously defined, this value operates
 * as a stand-in default.
 */
export const DEFAULT_RECENT_UPDATES_BACKGROUND_LOAD_DURATION_MINUTES = 4;

/**
 * The following constant defines the minimum threshold in minutes for automated polling of recently changed data.
 */
export const MIN_RECENT_CHANGES_THRESHOLD_MINUTES = 1;

/**
 * The following constant defines the minimum threshold in minutes for automated polling for full data refresh.
 */
export const MIN_FULL_CHANGES_THRESHOLD_MINUTES = 5;

export const INSPECTION_STATUS_DISPLAY_NAMES: Record<InspectionStatus, string> = {
    [InspectionStatus.Open]: 'Open',
    [InspectionStatus.Closed]: 'Closed',
    [InspectionStatus.Errored]: 'Errored',
    [InspectionStatus.SubmittedForReview]: 'Submitted for Review',
};

export const SEVERITY_BY_INSPECTION_STATUS: Map<InspectionStatus, Severity> = new Map();

SEVERITY_BY_INSPECTION_STATUS.set(InspectionStatus.Open, Severity.Warning);
SEVERITY_BY_INSPECTION_STATUS.set(InspectionStatus.Closed, Severity.Nominal);
SEVERITY_BY_INSPECTION_STATUS.set(InspectionStatus.SubmittedForReview, Severity.Nominal);
SEVERITY_BY_INSPECTION_STATUS.set(InspectionStatus.Errored, Severity.Critical);

export const SEVERITY_BY_SUBJECT_CONDITION: Map<SubjectCondition, Severity> = new Map();

SEVERITY_BY_SUBJECT_CONDITION.set(SubjectCondition.Good, Severity.Nominal);
SEVERITY_BY_SUBJECT_CONDITION.set(SubjectCondition.Fair, Severity.Warning);
SEVERITY_BY_SUBJECT_CONDITION.set(SubjectCondition.Poor, Severity.Critical);

export const Loading = Symbol('loading');
export const Unset = Symbol('unset');
export const Unit = Symbol('unit');
