import Services from '../services';
import { Assignment, AssignmentShare, Client } from '../types';
import { createAssignment, createNewAssignmentShare } from '../types/constructors';
import { UNKNOWN } from '../constants';

export default async function tryAddClientAssignment(
    client: Client,
    apiService: Services,
): Promise<[Assignment, AssignmentShare | null]> {
    const currentUser = apiService.getCurrentUser();
    if (!currentUser) {
        throw new Error('Current user could not be identified.');
    }

    const assignmentToCreate = createAssignment(client.id ?? UNKNOWN, currentUser.id ?? UNKNOWN);

    return apiService.createAssignment(assignmentToCreate)
        .then((assignment) => {
            if (client.email) {
                const emailAddress = client.name
                    ? `"${client.name}" <${client.email}>`
                    : client.email;

                return apiService.createAssignmentShare({
                    ...createNewAssignmentShare(client.id ?? UNKNOWN, assignment.id ?? UNKNOWN),
                    emailAddress,
                }).then((share) => [assignment, share]);
            }

            return Promise.resolve([assignment, null]);
        });
}
