import React, { useMemo } from 'react';
import SplitView from '../../controls/SplitView';
import upsertEquipment from '../../state/application/thunks/upsert-equipment';
import EquipmentEdit from '../../views/EquipmentEdit';
import Clients from '../../views/Clients/Clients';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment, Category,
    Client,
    ClientFilter,
    ClientsPageFilters,
    Employee,
    EquipmentAsset,
    Geolocation, Inspection,
} from '../../types';
import { actionCreators } from '../../state/application';
import LoadingView from '../../views/Loading';
import { convertImageBlobToDataUrl } from '../../utils/get-image-src';
import loadPhoto from '../../state/application/thunks/load-photo';

export default function EditEquipmentConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleError,
        handleWarning,
    } = props;
    const state = getState();

    const clientsPageFilters: ClientsPageFilters = useMemo(() => ({
        ...state.viewState.clients,
        onFilterChanged: (filter: ClientFilter) => dispatch(
            actionCreators.setClientsViewStateFilter(filter),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setClientsViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.clients]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.employees
        || loading.categories
        || loading.makes
    ) {
        return <LoadingView />;
    }

    const onImageRequested = (imageId: string) => {
        loadPhoto(dispatch, getState)(imageId, handleWarning);
    };
    const onImageDeleted = (imageId: string) => getState().apiServices
        .deletePhoto(imageId)
        .then(() => {
            dispatch(actionCreators.deletePhotoFromCache(imageId));
        })
        .catch((err) => {
            handleError(err.message);

            return Promise.reject(err);
        });
    const onImageAdded = (imageId: string, data: Blob) => {
        dispatch(actionCreators.setPhotoLoading(imageId));

        convertImageBlobToDataUrl(data)
            .then((url) => {
                dispatch(actionCreators.addPhotoToRepo(imageId, url));
            });
    };

    return (
        <>
            <SplitView
                className="clientsList--edit-equipment"
                leftPane={(
                    <Clients
                        getApplicationState={getState}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        employees={state.employees as Array<Employee>}
                        loading={loading}
                        pageFilters={clientsPageFilters}
                        selectedClientId={match.params.clientId}
                    />
                )}
                rightPane={(
                    <EquipmentEdit
                        onRequestingPhoto={onImageRequested}
                        onPhotoAdded={onImageAdded}
                        onDeletingPhoto={onImageDeleted}
                        photosCache={state.photosRepo.cache}
                        apiServices={state.apiServices}
                        makes={state.makes as Array<string>}
                        locations={state.locations as Array<Geolocation>}
                        categories={state.categories as Array<Category>}
                        clients={state.clients as Array<Client>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        loading={loading}
                        match={match}
                        getApplicationState={getState}
                        onUpdated={upsertEquipment(dispatch, getState)}
                        onError={handleError}
                    />
                )}
            />
        </>
    );
}
