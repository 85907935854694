import React from 'react';
import {
    Category, EquipmentAsset, Geolocation, Inspection,
} from '../../types';
import InspectionCategory from './InspectionCategory';

interface InspectedEquipmentListProps {
    categories: Array<Category>,
    inspections: Array<Inspection>,
    equipment: Array<EquipmentAsset>,
    locations: Array<Geolocation>,
    photos: Record<number, Blob>,
}

export default function InspectedEquipmentList(props: InspectedEquipmentListProps) {
    const {
        inspections,
        equipment,
        categories,
        locations,
        photos,
    } = props;

    const categoryList = categories
        .map((category) => (
            <InspectionCategory
                categories={categories}
                category={category}
                inspections={inspections}
                equipment={equipment}
                locations={locations}
                photos={photos}
            />
        ));

    return (
        <section className="inspectedEquipment">
            <h2>Inspected Equipment</h2>
            {categoryList}
        </section>
    );
}
