import dayjs from 'dayjs';
import React from 'react';
import {
    Assignment, Client, Employee, Geolocation,
} from '../../types';
import { RenderIf } from '../../controls';
import { DATE_FORMAT } from '../../constants';
import formatStreetAddress from './shared';

interface HeaderProps {
    client: Client,
    inspector: Employee | null,
    assignment: Assignment,
    clientLocation: Geolocation | null,
}

export default function Header(props: HeaderProps) {
    const {
        client,
        clientLocation,
        assignment,
        inspector,
    } = props;

    // Full width on mobile devices
    const viewportMeta = document.getElementById('viewport-meta');
    if (viewportMeta) {
        viewportMeta.remove();
    }

    const inspectionDate = dayjs(assignment.completionDate);

    return (
        <header>
            <div className="logo" />
            <div className="clientDetail">
                <span>Comprehensive Inspection for</span>
                <h1>{client.name}</h1>
                <section className="clientMeta address">
                    <RenderIf condition={Boolean(clientLocation)}>
                        <span>{clientLocation?.street1}</span>
                        <span className="separator" />
                        <span>{formatStreetAddress(clientLocation)}</span>
                    </RenderIf>
                </section>
                <section className="clientMeta email">
                    <span>My first phone</span>
                    <span className="separator" />
                    <span>My first email</span>
                </section>
            </div>
            <RenderIf condition={Boolean(inspector)}>
                <dl className="inspectionDetail">
                    <RenderIf condition={Boolean(inspector)}>
                        <>
                            <dt>Inspected by:</dt>
                            <dd>{inspector?.name}</dd>
                        </>
                    </RenderIf>
                    <RenderIf condition={inspectionDate.isValid()}>
                        <>
                            <dt>Date:</dt>
                            <dd>{inspectionDate.format(DATE_FORMAT)}</dd>
                        </>
                    </RenderIf>
                </dl>
            </RenderIf>
        </header>
    );
}
