import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Severity } from '../../types';

interface Props {
    values: Record<Severity, number>,
    width: number,
    height: number,
}

// Defines scale factor for SVG contents to increase padding:
const PAD_FACTOR = 0.7;

// Defines the radius for rendering the chart
const CHART_RADIUS_FACTOR = 0.666;

// Defines the radius for rendering text annotations
const TEXT_DISTANCE_FACTOR = 0.95;

export default function Pie(props: Props) {
    const { values, width, height } = props;
    const ref = useRef(null);
    const radius = Math.min(width, height) * ((PAD_FACTOR * CHART_RADIUS_FACTOR) / 2);

    useEffect(() => {
        const data = [
            values[Severity.Nominal] ?? 0,
            values[Severity.Warning] ?? 0,
            values[Severity.Critical] ?? 0,
        ];
        const totalCount = data.reduce((memo, item) => memo + item, 0);

        if (ref.current) {
            // @ts-ignore
            ref.current.innerHTML = '';

            const g = d3
                .select(ref.current)
                .append('g')
                // Position at center of region
                .attr('transform', `translate(${width / 2}, ${height / 2})`);
            const pie = d3.pie();

            const arc = d3.arc()
                .innerRadius(0)
                .outerRadius(radius);

            const arcs = g.selectAll('arc')
                .data(pie(data))
                .enter()
                .append('g')
                .attr('class', 'arc');

            arcs.append('path')
                .attr('class', (unused, index) => Severity[index])
                .attr('d', arc as any);

            g.selectAll('arc')
                .data(pie(data))
                .enter()
                .append('text')
                .text((val) => {
                    if (val.value) {
                        return `${((100 * val.value) / totalCount).toFixed(0)}%`;
                    }
                    return '';
                })
                .attr('transform', (v) => {
                    const centroidPos = arc.centroid(v as any);
                    const targetPos = centroidPos.map((val) => val * ((PAD_FACTOR * TEXT_DISTANCE_FACTOR) / ((PAD_FACTOR * CHART_RADIUS_FACTOR) / 2)));

                    return `translate(${targetPos})`;
                })
                .style('text-anchor', 'middle')
                .attr('class', 'annotation');
        }
    }, [
        height,
        radius,
        values,
        width,
    ]);

    return <svg ref={ref} width={width} height={height} />;
}
