import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { AssignmentShare } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function deleteAssignmentShare(dispatch: Dispatch<Action>, getState: () => State) {
    return (assignmentShareId: string) => {
        const { assignmentShares } = getState();

        if (assignmentShares === Unset || assignmentShares === Loading) {
            return;
        }

        dispatch(actionCreators.setAssignmentShares(
            (assignmentShares as Array<AssignmentShare>).filter(
                (item) => item.id !== assignmentShareId,
            ),
        ));
    };
}
