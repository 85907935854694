import { Link } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';
import {
    Assignment,
    Client,
    ClientFilter,
    ClientsPageFilters,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    InspectionStatus, LoadingState,
    Severity,
} from '../../types';
import Loading from '../Loading';
import { AppHeader, InputWrapper, NamedInput } from '../../controls';
import List, { ListItem } from '../../controls/List';
import getAssignedEmployeeForClient from '../../utils/get-assigned-employee-for-client';

import { State } from '../../state/application';
import { AssignedTemplate, NarrowTemplate } from './ClientRowTemplates';
import Search from '../../controls/Search';
import { UNKNOWN } from '../../constants';

// import { ReactFnCompPropsChecker } from '../controls/ReactFnCompPropsChecker';

interface Props {
    employees: Array<Employee>,
    assignments: Array<Assignment>,
    clients: Array<Client>,
    equipment: Array<EquipmentAsset>,
    inspections: Array<Inspection>,
    getApplicationState: () => State,
    locations: Array<Geolocation>,
    pageFilters: ClientsPageFilters,
    loading: LoadingState,
    selectedClientId?: undefined | string,
    paneSide? : string | boolean,
}

function ClientsList(props: Props) {
    const {
        assignments,
        employees,
        inspections,
        equipment,
        clients,
        locations,
        getApplicationState,
        loading,
        pageFilters,
        selectedClientId,
        paneSide,
    } = props;

    if (
        loading.clients
        || loading.locations
        || loading.inspections
        || loading.equipment
        || loading.employees
        || loading.assignments
    ) {
        return <Loading />;
    }

    const clientsIfLoaded = clients
        .filter((client) => client.name)
        .filter((client) => {
            if (pageFilters.filter === ClientFilter.AllClients) {
                return true;
            }

            const assignedTo = getAssignedEmployeeForClient(client, assignments, employees);

            return (pageFilters.filter === ClientFilter.Assigned && assignedTo?.employee)
                || (pageFilters.filter === ClientFilter.Unassigned && !assignedTo?.employee);
        })
        .filter(({ name }) => {
            if (pageFilters.search) {
                return name.toLowerCase().includes(pageFilters.search.toLowerCase());
            }
            return true;
        });

    const clientItems: Array<ListItem> = clientsIfLoaded
        .sort((lhs, rhs) => lhs.name.toLowerCase().localeCompare(rhs.name.toLowerCase()))
        .map((client) => {
            const primaryLocation = locations.find((loc) => loc.id === client.locationId);
            const pendingAssignments = assignments.filter((asg) => asg.clientId === client.id);
            const assignedTo = getAssignedEmployeeForClient(client, assignments, employees);
            const dueDate = dayjs(assignedTo?.assignment.dueDate);
            const isSelected = selectedClientId === `${client.id}`;
            const { severity, count } = querySeverityAndCount.byClient(client, assignments, equipment, inspections);
            const className = classNames(
                'severity',
                Severity[severity],
                { hasAssignments: Boolean(pendingAssignments.length) },
            );

            const errorFlag = pendingAssignments[0]?.status === InspectionStatus.Errored ? <div className="errored" /> : null;
            const useAssignedTemplate = pageFilters.filter === ClientFilter.Assigned && !paneSide;

            return {
                id: client.id ?? UNKNOWN,
                route: `/clients/${client.id}?listing=${encodeURIComponent('/clients')}`,
                className: classNames({ isSelected }, `${useAssignedTemplate ? 'assigned' : ''}`),
                children: (
                    <>
                        { useAssignedTemplate
                            ? (
                                <AssignedTemplate
                                    errorFlag={errorFlag}
                                    primaryLocation={primaryLocation}
                                    client={client}
                                    count={count}
                                    className={className}
                                    assignedTo={assignedTo}
                                    dueDate={dueDate}
                                />
                            )
                            : (
                                <NarrowTemplate
                                    errorFlag={errorFlag}
                                    primaryLocation={primaryLocation}
                                    client={client}
                                />
                            )}
                    </>
                ),
                title: client.name,
            };
        });
    const clientsList = (
        <List
            groupByFirstLetter
            className="clients whitebars"
            itemClass="client"
            items={clientItems}
        />
    );

    return (
        <main className="clientsList">
            <AppHeader getApplicationState={getApplicationState} hideBackButton>
                <Link className="icn-plus" to="/clients/new">Add Client</Link>
            </AppHeader>

            <h1>Clients</h1>

            <Search
                value={pageFilters.search}
                searchUpdate={pageFilters.onSearchChanged}
            />

            <InputWrapper className="filters">

                <NamedInput
                    name="allClients"
                    type="radio"
                    label="All&nbsp;Clients"
                    value={pageFilters.filter === ClientFilter.AllClients}
                    onChange={() => pageFilters.onFilterChanged(ClientFilter.AllClients)}
                />
                <NamedInput
                    name="assigned"
                    type="radio"
                    label="Assigned"
                    value={pageFilters.filter === ClientFilter.Assigned}
                    onChange={() => pageFilters.onFilterChanged(ClientFilter.Assigned)}
                />
                <NamedInput
                    name="unassigned"
                    type="radio"
                    label="Unassigned"
                    value={pageFilters.filter === ClientFilter.Unassigned}
                    onChange={() => pageFilters.onFilterChanged(ClientFilter.Unassigned)}
                />

            </InputWrapper>

            {clientsList}
        </main>
    );
}
ClientsList.defaultProps = { selectedClientId: undefined };

/*
export default function Checked(props: Props) {
    return (
        <>
            { /!* @ts-ignore *!/ }
            <ReactFnCompPropsChecker childrenProps={props}>
                {
                    // @ts-ignore eslint-disable-next-line
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    (inner) => <ClientsList {...inner} />
                }
            </ReactFnCompPropsChecker>
        </>
    );
}
Checked.defaultProps = { selectedClientId: undefined };
*/

ClientsList.defaultProps = { paneSide: false };

export default React.memo(ClientsList);
