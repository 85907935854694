import React from 'react';

interface NoResultsProps {
    defaultString?: string | undefined,
}

const DEFAULT_NORESULTS_PROPS = { defaultString: 'No results available' };

export default function NoResults(props: NoResultsProps) {
    const { defaultString } = props;

    return (
        <span className="no-results">
            {defaultString}
        </span>
    );
}

NoResults.defaultProps = DEFAULT_NORESULTS_PROPS;
