import { EquipmentAsset, Geolocation, SubjectCondition } from '../../types';

export function formatAssetTitle(asset: EquipmentAsset | null) {
    if (asset?.year) {
        return `${asset?.year} ${asset?.name}`;
    }
    return asset?.name;
}

export function formatCondition(condition: SubjectCondition) {
    return SubjectCondition[condition];
}

export default function formatStreetAddress(address: Geolocation | null) {
    if (address === null) {
        return '';
    }

    const delimitedParts = [
        address.city,
        address.state,
    ]
        .filter((item) => item)
        .join(', ');

    return [delimitedParts, address.postalCode].join(' ');
}
