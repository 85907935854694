import classNames from 'classnames';
import React from 'react';
import { EquipmentAsset, Inspection, Severity } from '../../types';
import { getSeverityBySubjectCondition } from '../../utils/lookups';
import { formatAssetTitle } from './shared';
import Pie from './Pie';
import { RenderIf } from '../../controls';

interface InspectionOverviewProps {
    inspections: Array<Inspection>,
    equipment: Array<EquipmentAsset>,
}

export default function InspectionOverview(props: InspectionOverviewProps) {
    const { inspections, equipment } = props;
    const includedInspections = inspections.filter((item) => item.includeInReport);

    const equipmentInspectionList = includedInspections
        .map((item) => {
            const matchingAsset = equipment.find((sub) => sub.id === item.equipmentId) ?? null;
            const severity = getSeverityBySubjectCondition(item.condition);

            return (
                <li>
                    <div className={classNames('severity', Severity[severity])} />
                    <a href={`#${item.id}`}>{formatAssetTitle(matchingAsset)}</a>
                </li>
            );
        });
    const inspectedSubjects = includedInspections.reduce((memo, item) => {
        const completedSubjects = item.subjects.filter((subj) => subj.completionStatus);

        return memo + completedSubjects.length;
    }, 0);
    const inspectionStatuses: Array<Severity> = includedInspections.map((insp) => getSeverityBySubjectCondition(insp.condition));
    const statusCounts = inspectionStatuses.reduce(
        (memo, status) => ({
            ...memo,
            [status]: 1 + (memo[status] ?? 0),
        }),
        {} as Record<Severity, number>,
    );

    return (
        <section className="inspectionOverview">
            <div className="metrics">
                <h2>Inspection Overview</h2>
                <figure>
                    <figcaption>Pieces of<br />Equipment Inspected</figcaption>
                    <span>{inspections.length}</span>
                </figure>
                <figure>
                    <figcaption>Tires / Tracks<br />Inspected</figcaption>
                    <span>{inspectedSubjects}</span>
                </figure>
                <section className="breakdown">
                    <h3>Health Breakdown</h3>
                    <Pie
                        width={200}
                        height={200}
                        values={{
                            [Severity.Critical]: statusCounts[Severity.Critical],
                            [Severity.Nominal]: statusCounts[Severity.Nominal],
                            [Severity.Warning]: statusCounts[Severity.Warning],
                            [Severity.Ignore]: statusCounts[Severity.Ignore],
                        }}
                    />
                    <section className="legend">
                        <RenderIf condition={Boolean(statusCounts[Severity.Nominal])}>
                            <div>
                                <div className="severity Nominal" />
                                <span>No Issues</span>
                            </div>
                        </RenderIf>
                        <RenderIf condition={Boolean(statusCounts[Severity.Warning])}>
                            <div>
                                <div className="severity Warning" />
                                <span>Label Here</span>
                            </div>
                        </RenderIf>
                        <RenderIf condition={Boolean(statusCounts[Severity.Critical])}>
                            <div>
                                <div className="severity Critical" />
                                <span>Needs Attention</span>
                            </div>
                        </RenderIf>
                    </section>
                </section>
            </div>
            <ul className="equipment">{equipmentInspectionList}</ul>
        </section>
    );
}
