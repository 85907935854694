export default function generateShareEmail(name: string | null, emailAddress: string, slug: string) {
    const nameAndEmail = name
        ? `"${name}" <${emailAddress}>`
        : emailAddress;

    const to = encodeURIComponent(nameAndEmail);
    const subject = 'CFI Inspection Report';
    const body = encodeURIComponent(
        'You have been invited to review a recent equipment inspection performed by CFI Tire.'
        + ` You may find the results here: ${window.location.origin}${window.location.pathname}?slug=${slug}`,
    );

    return `mailto:${to}?subject=${subject}&body=${body}`;
}
