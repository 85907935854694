import React, { useMemo } from 'react';
import SplitView from '../../controls/SplitView';
import ClientFleet from '../../views/Clients/ClientFleet';
import Clients from '../../views/Clients/Clients';
import loadPhoto from '../../state/application/thunks/load-photo';
import { AppComponentProps } from '../route-wrapper';
import {
    Assignment, Category,
    Client,
    ClientFilter,
    ClientsPageFilters,
    Employee,
    EquipmentAsset,
    FleetPageFilters,
    FleetPageType,
    Geolocation,
    Inspection,
} from '../../types';
import { actionCreators } from '../../state/application';
import LoadingView from '../../views/Loading';

export default function ClientFleetConnector(props: AppComponentProps) {
    const {
        loading,
        getState,
        dispatch,
        match,
        handleWarning,
    } = props;
    const state = getState();

    const clientsPageFilters: ClientsPageFilters = useMemo(() => ({
        ...state.viewState.clients,
        onFilterChanged: (filter: ClientFilter) => dispatch(
            actionCreators.setClientsViewStateFilter(filter),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setClientsViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.clients]);
    const fleetPageFilters: FleetPageFilters = useMemo(() => ({
        ...state.viewState.fleet,
        onPageTypeChanged: (pageType: FleetPageType) => dispatch(
            actionCreators.setFleetViewStatePageType(pageType),
        ),
        onSearchChanged: (search: string) => dispatch(
            actionCreators.setFleetViewStateSearch(search),
        ),
    }), [dispatch, state.viewState.fleet]);

    if (loading.clients
        || loading.assignments
        || loading.locations
        || loading.equipment
        || loading.inspections
        || loading.employees
        || loading.categories
    ) {
        return <LoadingView />;
    }

    return (
        <>
            <SplitView
                className="clientsList--fleet"
                leftPane={(
                    <Clients
                        getApplicationState={getState}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        employees={state.employees as Array<Employee>}
                        loading={loading}
                        pageFilters={clientsPageFilters}
                        selectedClientId={match.params.clientId}
                    />
                )}
                rightPane={(
                    <ClientFleet
                        photosCache={state.photosRepo.cache}
                        loadPhoto={(id: string) => loadPhoto(dispatch, getState)(id, handleWarning)}
                        clients={state.clients as Array<Client>}
                        assignments={state.assignments as Array<Assignment>}
                        locations={state.locations as Array<Geolocation>}
                        equipment={state.equipment as Array<EquipmentAsset>}
                        inspections={state.inspections as Array<Inspection>}
                        categories={state.categories as Array<Category>}
                        getApplicationState={getState}
                        loading={loading}
                        match={match}
                        pageFilters={fleetPageFilters}
                    />
                )}
            />
        </>
    );
}
