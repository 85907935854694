import React from 'react';
import { Action, actionCreators, State } from '../index';
import { TrackTireInspection } from '../../../types';

type Dispatcher = React.Dispatch<Action>;

export default function getReplaceSelectedSubjectHandler(
    dispatch: Dispatcher,
    getState: () => State,
    handlePhotosAdded: (photos: Record<number, string>) => void,
) {
    return (replaceWith: TrackTireInspection, newlyAddedImages: Record<number, string>) => {
        const state = getState();
        const otherSubjects = state.subjects.filter(
            (subject) => subject !== state.selectedSubject,
        );

        dispatch(actionCreators.setSubjects([replaceWith, ...otherSubjects]));
        dispatch(actionCreators.setSelectedSubject(replaceWith));

        handlePhotosAdded(newlyAddedImages);

        dispatch(actionCreators.setPendingSave(true));
    };
}
