import { groupBy } from 'ramda';
import {
    Assignment,
    Inspection,
    UserLogin,
} from '../types';
import isConsideredOpen from './is-considered-open';

export default function getPendingAssignmentsCount(
    inspections: Array<Inspection>,
    assignments: Array<Assignment>,
    currentUser: UserLogin,
) {
    const pendingAssignments = assignments.filter((asg) => isConsideredOpen(asg.status));
    const myAssignments = pendingAssignments.filter((asg) => asg.employeeId === currentUser.id);
    const unassigned = pendingAssignments.filter((asg) => asg.employeeId === null);
    const assignedByClient: Record<string, Array<Assignment>> = groupBy((asg) => `${asg.clientId}`, myAssignments);

    return { unassigned, myAssignments, assignedByClient };
}
