import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { ExtractRouteParams, match as routerMatch } from 'react-router';
import {
    Assignment,
    Client,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    Severity,
} from '../../types';
import { AppHeader, DetailPanel, NamedInput } from '../../controls/index';
import { ADMIN, NEW, UNKNOWN } from '../../constants';
import Loading from '../Loading';
import List, { ListItem } from '../../controls/List';
import * as querySeverityAndCount from '../../utils/query-severity-and-count';
import { State } from '../../state/application';

interface Props {
    onError: (message: string) => void,
    assignments: Array<Assignment>,
    inspections: Array<Inspection>,
    equipment: Array<EquipmentAsset>,
    clients: Array<Client>,
    locations: Array<Geolocation>,
    resetPassword: (userId: string, newPassword: string) => Promise<void>,
    employees: Array<Employee>,
    loading: {
        assignments: boolean,
        inspections: boolean,
        equipment: boolean,
        clients: boolean,
        locations: boolean,
        employees: boolean,
    },
    getApplicationState: () => State,
    match: routerMatch<ExtractRouteParams<string, string>>,
}

interface ResetPasswordProps {
    onSubmit: (newPassword: string) => void,
    onCancel: () => void,
}

function ResetPasswordDialog(props: ResetPasswordProps) {
    const { onSubmit, onCancel } = props;

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const confirmButton = password === confirmPassword && password.length
        ? <button type="button" className="risky" onClick={() => onSubmit(password)}>Update password</button>
        : null;

    return (
        <section className="resetPassword">
            <form>
                <NamedInput
                    name="password"
                    type="password"
                    label="Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                />
                <NamedInput
                    name="confirmPassword"
                    type="password"
                    label="Confirm password"
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                />

                <div className="actions">
                    {confirmButton}
                    <button type="button" onClick={onCancel}>Cancel</button>
                </div>
            </form>
        </section>
    );
}

export default function EmployeeDetail(props: Props) {
    const {
        onError,
        employees,
        clients,
        assignments,
        inspections,
        equipment,
        locations,
        resetPassword,
        match,
        getApplicationState,
        loading,
    } = props;

    const [initialized, setInitialized] = useState<string | null>(null);
    const [showingResetPwdDialog, setShowingResetPwdDialog] = useState(false);

    useEffect(() => {
        if (match.params.employeeId !== NEW && initialized !== match.params.employeeId) {
            setShowingResetPwdDialog(false);
            setInitialized(match.params.employeeId ?? '');
        }
    }, [initialized, match.params.employeeId]);

    if (loading.employees
        || loading.assignments
        || loading.clients
        || loading.locations
        || loading.equipment
        || loading.inspections
    ) {
        return <Loading />;
    }

    const employee = employees.find((item) => `${item.id}` === match.params.employeeId);

    if (!employee) {
        throw new Error();
    }

    if (showingResetPwdDialog) {
        return (
            <ResetPasswordDialog
                onCancel={() => setShowingResetPwdDialog(false)}
                onSubmit={(newPassword: string) => {
                    resetPassword(employee.id ?? UNKNOWN, newPassword)
                        .then(() => setShowingResetPwdDialog(false))
                        .catch((e) => onError(e.message));
                }}
            />
        );
    }

    const assignmentItems: Array<ListItem> = assignments
        .filter((assignment) => assignment.employeeId === employee.id)
        .map((assignment) => {
            const client = clients.find((item) => item.id === assignment.clientId);
            const primaryLocation = locations.find((item) => item.id === client?.locationId);
            const dueDate = assignment.dueDate
                ? dayjs(assignment.dueDate).format('M/d/YYYY')
                : '(date not set)';
            const { severity, count } = querySeverityAndCount.byAssignment(
                assignment,
                equipment,
                inspections,
            );
            const className = classNames(
                'severity',
                Severity[severity],
            );
            const title = client?.name ?? '';

            return {
                id: assignment.id ?? UNKNOWN,
                title,
                route: `/clients/${client?.id}/assignments/${assignment.id}`,
                children: (
                    <>
                        <div className="row-section">
                            <div className="name">{title}</div>
                            <div className="location">{primaryLocation?.city}</div>
                            <div className={`${className} mobile-only`}>
                                <span className="inspectionsCount">{count}</span>
                            </div>
                        </div>
                        <div className="row-section">
                            <div className="dueDate">Due {dueDate}</div>
                            <div className={className}>
                                <span className="inspectionsCount">{count}</span>
                            </div>
                        </div>
                    </>
                ),
            };
        });

    return (

        <div className="employeeDetail">
            <main>
                <AppHeader getApplicationState={getApplicationState}>
                    <></>
                </AppHeader>

                <h1>{employee.name}</h1>
                <DetailPanel>
                    <dl>
                        <dt>Phone:</dt>
                        <dd>{employee.phoneNumber}</dd>

                        <dt>Username:</dt>
                        <dd>{employee.username}</dd>

                        <dt>Email:</dt>
                        <dd>{employee.email}</dd>

                        <dt>Password:</dt>
                        <dd>*********</dd>

                        <dt>Is Admin?</dt>
                        <dd>{employee.role === ADMIN ? 'Yes' : 'No'}</dd>
                    </dl>
                </DetailPanel>

                <section>
                    <h2>Assignments</h2>

                    <List
                        className="assignmentsList whitebars"
                        items={assignmentItems}
                        itemClass=""
                    />
                </section>

                <section className="actions">
                    <button
                        type="button"
                        onClick={() => setShowingResetPwdDialog(true)}
                    >Reset password
                    </button>
                </section>
            </main>
        </div>
    );
}
