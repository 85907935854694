import { Dispatch, useContext } from 'react';
import { Action, actionCreators, State } from '../index';
import { Loading, Unit, Unset } from '../../../constants';
import { BackgroundLoadState, BackgroundLoadType, User } from '../../../types';

import handleClientRouteVisited from './handle-client-route-visited';
import { AlertType, SnackbarContext } from '../../../controls/Snackbar';

export default function initialLoad(dispatch: Dispatch<Action>, getState: () => State) {
    return (currentUser: User) => {
        const { append: appendError } = useContext(SnackbarContext);
        const { initialized } = getState();

        if (initialized !== Unset) {
            // Our initializer should only successfully execute once
            return;
        }

        const initialRoute = getState().appHistory.location.pathname;

        dispatch(actionCreators.setInitialized(Loading));
        dispatch(actionCreators.setBackgroundLoad(BackgroundLoadState.Running, BackgroundLoadType.Full));

        if (!currentUser) {
            throw new Error('Unable to load current user.');
        }

        const settingsLoad = getState().apiServices.getUserSettings(currentUser.id ?? '')
            .then((settings) => {
                const mergedSettings = {
                    ...currentUser.userSettings,
                    ...settings,
                };

                dispatch(actionCreators.setCurrentUser({
                    ...currentUser,
                    userSettings: mergedSettings,
                }));
            });

        settingsLoad.catch(() => {
            appendError('Failed to load user settings', AlertType.Error);
        });

        getState().appHistory.listen((location) => {
            const path = location.pathname;

            handleClientRouteVisited(dispatch)(path);
        });

        dispatch(actionCreators.setMakes(Loading));

        const makesLoad = getState().apiServices.getEquipmentMakes()
            .then((makes) => {
                dispatch(actionCreators.setMakes(makes));
            });

        makesLoad.catch(() => {
            appendError('Failed to load makes', AlertType.Error);
        });

        const recentClientsLoad = getState().apiServices.getRecentClients()
            .then((ids) => {
                dispatch(actionCreators.clearRecentClients());

                ids.forEach((id) => dispatch(actionCreators.addRecentClient(id)));

                handleClientRouteVisited(dispatch)(initialRoute);
            });

        recentClientsLoad.catch(() => {
            appendError('Failed to load recent clients', AlertType.Error);
        });

        dispatch(actionCreators.setEmployees(Loading));
        const employeesLoad = getState().apiServices.getEmployees()
            .then((items) => {
                dispatch(actionCreators.setEmployees(items));

                return items;
            });

        employeesLoad.catch(() => {
            appendError('Failed to load employees', AlertType.Error);
        });

        dispatch(actionCreators.setCategories(Loading));
        const categoriesLoad = getState().apiServices.getCategories()
            .then((items) => {
                dispatch(actionCreators.setCategories(items));

                return items;
            });

        categoriesLoad.catch(() => {
            appendError('Failed to load categories', AlertType.Error);
        });

        dispatch(actionCreators.setClients(Loading));
        const clientsLoad = getState().apiServices.getClients()
            .then((items) => {
                dispatch(actionCreators.setClients(items));

                return items;
            });

        clientsLoad.catch(() => {
            appendError('Failed to load clients', AlertType.Error);
        });

        dispatch(actionCreators.setEquipment(Loading));

        const equipmentLoad = getState().apiServices.getEquipment()
            .then((equipment) => {
                dispatch(actionCreators.setEquipment(equipment));
            });

        equipmentLoad.catch(() => {
            appendError('Failed to load equipment', AlertType.Error);
        });

        dispatch(actionCreators.setLocations(Loading));

        const locationsLoad = getState().apiServices.getLocations()
            .then((locations) => {
                dispatch(actionCreators.setLocations(locations));
            });

        locationsLoad.catch(() => {
            appendError('Failed to load locations', AlertType.Error);
        });

        dispatch(actionCreators.setAssignments(Loading));

        const assignmentsLoad = getState().apiServices.getAssignments()
            .then((items) => {
                dispatch(actionCreators.setAssignments(items));

                return items;
            });

        assignmentsLoad.catch(() => {
            appendError('Failed to load assignments', AlertType.Error);
        });

        dispatch(actionCreators.setAssignmentShares(Loading));

        const assignmentSharesLoad = getState().apiServices.getAssignmentShares()
            .then((items) => {
                dispatch(actionCreators.setAssignmentShares(items));

                return items;
            });

        assignmentSharesLoad.catch(() => {
            appendError('Failed to load assignment shares', AlertType.Error);
        });

        dispatch(actionCreators.setClientContacts(Loading));

        const clientContactsLoad = getState().apiServices.getClientContacts()
            .then((items) => {
                dispatch(actionCreators.setClientContacts(items));

                return items;
            });

        clientContactsLoad.catch(() => {
            appendError('Failed to load client contacts', AlertType.Error);
        });

        dispatch(actionCreators.setInspections(Loading));

        const inspectionsLoad = getState().apiServices.getInspections()
            .then((items) => {
                dispatch(actionCreators.setInspections(items));

                return items;
            });

        inspectionsLoad.catch(() => {
            appendError('Failed to load inspections', AlertType.Error);
        });

        // Ensure that when all dependencies' load processes are finished, we use that as the starting point
        // for subsequent background load timestamp.
        Promise.allSettled([
            makesLoad,
            recentClientsLoad,
            employeesLoad,
            categoriesLoad,
            clientsLoad,
            equipmentLoad,
            locationsLoad,
            assignmentsLoad,
            assignmentSharesLoad,
            clientContactsLoad,
            inspectionsLoad,
        ])
            .then(() => {
                dispatch(actionCreators.setBackgroundLoad(new Date().getTime(), BackgroundLoadType.Full));
            });

        dispatch(actionCreators.setInitialized(Unit));
    };
}
