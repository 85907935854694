import React, { ReactNode } from 'react';
import classNames from 'classnames';
import {
    Assignment, Inspection, LoadingState, TopLevelPage, UserLogin,
} from '../types';
import getPendingAssignmentsCount from '../utils/get-pending-assignments-count';

interface Props {
    location: any
    currentUser: UserLogin | Symbol,
    performNavigation: (entity: TopLevelPage) => void,
    assignments: Array<Assignment> | Symbol,
    inspections: Array<Inspection> | Symbol,
    loading: LoadingState,
}

interface FooterLinkProps {
    to: TopLevelPage,
    children: ReactNode,
    performNavigation: (entity: TopLevelPage) => void,
}

function FooterLink(props: FooterLinkProps) {
    const { to, children, performNavigation } = props;
    const onClick = () => performNavigation(to);

    return (
        <button
            onClick={onClick}
            className="footerLink"
            type="button"
        >{children}
        </button>
    );
}

export default function AppFooter(props: Props) {
    const {
        location,
        currentUser,
        assignments,
        inspections,
        loading,
        performNavigation,
    } = props;

    if (loading.assignments || loading.inspections || loading.currentUser) {
        return null;
    }

    let activePane;

    if (location.pathname.match(/\/assignments(\/\d+)?$/)) {
        activePane = 'assignments';
    } else if (location.pathname.includes('settings')) {
        activePane = 'settings';
    } else if (location.pathname.includes('employees')) {
        activePane = 'employees';
    } else if (location.pathname.includes('clients')) {
        activePane = 'clients';
    } else {
        activePane = 'dashboard';
    }

    const { myAssignments } = getPendingAssignmentsCount(
        inspections as Array<Inspection>,
        assignments as Array<Assignment>,
        currentUser as UserLogin,
    );

    const home = (
        <FooterLink key="footer-home" to={TopLevelPage.Dashboard} performNavigation={performNavigation}>
            <span
                className={classNames(['icon', 'home', { active: activePane === 'dashboard' }])}
            >Home
            </span>
            <span className="count badge">{myAssignments.length}</span>
        </FooterLink>
    );
    const employees = (
        <FooterLink key="footer-employees" to={TopLevelPage.Employees} performNavigation={performNavigation}>
            <span
                className={classNames(['icon', 'employees', { active: activePane === 'employees' }])}
            >Employees
            </span>
        </FooterLink>
    );
    const clients = (
        <FooterLink key="footer-clients" to={TopLevelPage.Clients} performNavigation={performNavigation}>
            <span
                className={classNames(['icon', 'clients', { active: activePane === 'clients' }])}
            >Clients
            </span>
        </FooterLink>
    );
    const assignmentsLink = (
        <FooterLink key="footer-assignments" to={TopLevelPage.Assignments} performNavigation={performNavigation}>
            <span
                className={classNames(['icon', 'assignments', { active: activePane === 'assignments' }])}
            >Assignments
            </span>
        </FooterLink>
    );
    const settings = (
        <FooterLink key="footer-settings" to={TopLevelPage.Settings} performNavigation={performNavigation}>
            <span
                className={classNames(['icon', 'settings', { active: activePane === 'settings' }])}
            >Settings
            </span>
        </FooterLink>
    );

    const links = (currentUser as UserLogin).role === 'admin'
        ? [home, employees, clients, assignmentsLink, settings]
        : [home, clients, settings];

    return (
        <section className="app-footer">
            {links}
        </section>
    );
}
