import React from 'react';
import { History } from 'history';
import { Action, actionCreators, State } from '../index';
import { Client, Inspection, InspectionStatus } from '../../../types';
import submitInspectionUpdate from '../../../utils/update-inspection';
import Services from '../../../services';
import isConsideredOpen from '../../../utils/is-considered-open';

type Dispatcher = React.Dispatch<Action>;

function appendLine(start: string | undefined | null, toAppend: string) {
    if (start?.length) {
        return `${start}\n${toAppend}`;
    }
    return toAppend;
}

export default function getInspectionStatusUpdateHandler(
    dispatch: Dispatcher,
    getState: () => State,
    onError: (message: string) => void,
    apiServices: Services,
    inspection: Inspection | undefined,
    client: Client | undefined,
    onInspectionUpdated: (inspection: Inspection) => void,
    history: History,
) {
    return (newStatus: InspectionStatus, stayOnPage: boolean, reason?: string | undefined) => {
        const state = getState();
        const { notes } = state;
        const notesWithReason = reason?.length
            ? appendLine(notes, reason)
            : notes;

        if (notes !== notesWithReason) {
            dispatch(actionCreators.setNotes(notesWithReason));
        }

        dispatch(actionCreators.setStatus(newStatus));

        submitInspectionUpdate(
            inspection,
            newStatus,
            state.condition,
            state.hours,
            state.includeInReport,
            notesWithReason,
            state.subjects,
            apiServices,
            onError,
            onInspectionUpdated,
        )
            .then((updated) => {
                if (!updated) {
                    throw new Error('Failed to update inspection.');
                }

                if (!isConsideredOpen(updated.status) && !stayOnPage) {
                    history.replace(`/clients/${client?.id}`);
                }
            })
            .catch((e) => onError(e.message));
    };
}
