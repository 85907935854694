import { Dispatch } from 'react';
import { Action, actionCreators, State } from '../index';
import { ClientContact } from '../../../types';
import { Loading, Unset } from '../../../constants';

export default function deleteClientContact(dispatch: Dispatch<Action>, getState: () => State) {
    return (clientContactId: string) => {
        const { clientContacts } = getState();

        if (clientContacts === Unset || clientContacts === Loading) {
            return;
        }

        dispatch(actionCreators.setClientContacts(
            (clientContacts as Array<ClientContact>).filter(
                (item) => item.id !== clientContactId,
            ),
        ));
    };
}
