import debounce from 'debounce';
import {
    Inspection, InspectionStatus, SubjectCondition, TrackTireInspection,
} from '../types';
import Services from '../services';

const DEBOUNCE_INTERVAL = 2000;
export default function submitInspectionUpdate(
    inspection: Inspection | undefined,
    status: InspectionStatus,
    condition: SubjectCondition,
    hours: number | null,
    includeInReport: boolean,
    notes: string | null,
    subjects: TrackTireInspection[],
    apiServices: Services,
    handleError: (message: string) => void,
    onInspectionUpdated: (inspection: Inspection) => void,
): Promise<Inspection | void> {
    if (!inspection) {
        throw new Error('Should not happen.');
    }

    const toSave: Inspection = {
        ...inspection,
        status,
        condition,
        hours,
        includeInReport,
        notes,
        subjects,
    };

    return apiServices.updateInspection(toSave)
        .then((updated) => {
            onInspectionUpdated(updated);

            return updated;
        })
        .catch((e) => {
            handleError(e.message);
        });
}
export const debouncedUpdate = debounce(submitInspectionUpdate, DEBOUNCE_INTERVAL);
