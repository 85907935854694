import { InspectionStatus, Severity, SubjectCondition } from '../types';
import { SEVERITY_BY_INSPECTION_STATUS, SEVERITY_BY_SUBJECT_CONDITION } from '../constants';

export const getSeverityByInspectionStatus = (status: InspectionStatus): Severity => {
    const knownSeverity = SEVERITY_BY_INSPECTION_STATUS.get(status);

    if (knownSeverity !== undefined) {
        return knownSeverity;
    }

    return Severity.Ignore;
};

export const getSeverityBySubjectCondition = (condition: SubjectCondition): Severity => {
    const knownSeverity = SEVERITY_BY_SUBJECT_CONDITION.get(condition);

    if (knownSeverity !== undefined) {
        return knownSeverity;
    }

    return Severity.Ignore;
};
