import { last } from 'ramda';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import {
    ApiError, ApiErrorType,
    Assignment, AssignmentShare,
    Category,
    Client,
    ClientContact,
    CreateEmployeeDto,
    Employee,
    EquipmentAsset,
    Geolocation,
    Inspection,
    User, UserSetting,
} from '../types';
import {
    API_BASE_URI,
    DEFAULT_FULL_BACKGROUND_LOAD_DURATION_MINUTES,
    DEFAULT_RECENT_UPDATES_BACKGROUND_LOAD_DURATION_MINUTES,
} from '../constants';
import inspectionsFromDtoEntities from '../utils/inspections-from-dto-entities';

interface ApiJwtPayload extends JwtPayload {
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': string,
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string,
    'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': string,
}

export default class Services {
    userToken: string | null;

    static tryGetAuthenticatedService(): Services {
        const user = localStorage.getItem('user');

        if (!user) {
            return new Services(null);
        }
        return new Services(user);
    }

    async tryLogin(
        username: string,
        password: string,
    ): Promise<void> {
        const formData = new URLSearchParams();

        formData.append('username', username);
        formData.append('password', password);

        const resp = await fetch(`${API_BASE_URI}/login?${formData}`, { method: 'POST' });

        if (resp.ok) {
            const jwt = await resp.text();

            localStorage.setItem('user', jwt);
            this.userToken = jwt;
        } else {
            throw new ApiError('Login failed.', ApiErrorType.InvalidLogin);
        }
    }

    constructor(token: string | null) {
        this.userToken = token;
    }

    logout() {
        localStorage.removeItem('user');
        this.userToken = null;
    }

    getCurrentUser(): User | null {
        if (!this.userToken) {
            return null;
        }

        const decoded = jwtDecode<ApiJwtPayload>(this.userToken);
        const tokenExpiresAt = (decoded?.exp ?? NaN);
        const now = new Date().getTime() / 1000;

        if (tokenExpiresAt > now) {
            const userId = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];

            return {
                id: userId,
                name: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
                username: decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
                role: decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
                userSettings: {
                    fullUpdatesPollingPeriod: DEFAULT_FULL_BACKGROUND_LOAD_DURATION_MINUTES,
                    recentUpdatesPollingPeriod: DEFAULT_RECENT_UPDATES_BACKGROUND_LOAD_DURATION_MINUTES,
                },
            };
        }
        return null;
    }

    getEmployees(updatedSince?: number): Promise<Array<Employee>> {
        const headers: Record<string, string> = { Authorization: `Bearer ${this.userToken ?? ''}` };

        if (updatedSince) {
            headers['if-modified-since'] = new Date(updatedSince).toUTCString();
        }
        return fetch(`${API_BASE_URI}/employees`, {
            method: 'GET',
            headers,
        })
            .then((resp) => {
                if (resp.status === 304) {
                    return [];
                }
                return resp.json();
            })
            .then((employees: Array<any>) => employees.map((emp) => {
                const { password: unused, ...remainingProps } = emp;

                return remainingProps as Employee;
            }))
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getUserSettings(id: string): Promise<{ [key in keyof typeof UserSetting]?: any }> {
        return fetch(`${API_BASE_URI}/employees/${id}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .then((emp: any) => {
                const { userSettings } = emp;
                const wellFormedSettings = JSON.parse(userSettings || '{}');

                return wellFormedSettings;
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createEmployee(employee: CreateEmployeeDto): Promise<Employee> {
        return fetch(`${API_BASE_URI}/employees`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(employee),
        })
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    const { password, ...employeeMinusPassword } = employee;

                    return {
                        ...employeeMinusPassword,
                        id,
                    };
                }
                return Promise.reject(new ApiError('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateEmployee(employee: Employee): Promise<Employee> {
        return fetch(`${API_BASE_URI}/employees/${employee.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(employee),
        })
            .then((resp) => {
                if (resp.ok) {
                    return employee;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getClients(updatedSince?: number): Promise<Array<Client>> {
        const headers: Record<string, string> = { Authorization: `Bearer ${this.userToken ?? ''}` };

        if (updatedSince) {
            headers['if-modified-since'] = new Date(updatedSince).toUTCString();
        }

        return fetch(`${API_BASE_URI}/clients`, {
            method: 'GET',
            headers,
        })
            .then((resp) => {
                if (resp.status === 304) {
                    return [];
                }
                return resp.json();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createClient(client: Client) {
        return fetch(`${API_BASE_URI}/clients`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(client),
        })
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...client,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateClient(client: Client) {
        return fetch(`${API_BASE_URI}/clients/${client.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(client),
        })
            .then(() => client)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    // eslint-disable-next-line class-methods-use-this
    getRecentClients(): Promise<Array<string>> {
        // TODO
        return Promise.resolve([]);
    }

    getEquipment(updatedSince?: number): Promise<Array<EquipmentAsset>> {
        const headers: Record<string, string> = { Authorization: `Bearer ${this.userToken ?? ''}` };

        if (updatedSince) {
            headers['if-modified-since'] = new Date(updatedSince).toUTCString();
        }
        return fetch(`${API_BASE_URI}/equipment`, {
            method: 'GET',
            headers,
        })
            .then((resp) => {
                if (resp.status === 304) {
                    return [];
                }
                return resp.json();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    // eslint-disable-next-line class-methods-use-this
    getEquipmentMakes(): Promise<Array<string>> {
        return fetch(`${API_BASE_URI}/get-makes-list`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getEquipmentByClient(clientId: string | number): Promise<Array<EquipmentAsset>> {
        return fetch(`${API_BASE_URI}/clients/${clientId}/equipment`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteEquipment(assetId: string): Promise<void> {
        return fetch(`${API_BASE_URI}/equipment/${assetId}`, {
            method: 'DELETE',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new ApiError('Deletion failed.');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    setUserPassword(userId: string, password: string): Promise<void> {
        return fetch(`${API_BASE_URI}/employees/${userId}/set-password`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(password),
        })
            .then((resp) => {
                if (!resp.ok) {
                    return Promise.reject(new Error('Did not receive successful response'));
                }
                return Promise.resolve();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    setUserSettings(userId: string, settingsJson: string): Promise<void> {
        return fetch(`${API_BASE_URI}/employees/${userId}/set-settings`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(settingsJson),
        })
            .then((resp) => {
                if (!resp.ok) {
                    return Promise.reject(new Error('Did not receive successful response'));
                }
                return Promise.resolve();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createAssignment(assignment: Assignment): Promise<Assignment> {
        return fetch(`${API_BASE_URI}/assignments`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(assignment),
        })
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...assignment,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateAssignment(assignment: Assignment): Promise<Assignment> {
        return fetch(`${API_BASE_URI}/assignments/${assignment.id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(assignment),
        })
            .then((resp) => {
                if (resp.ok) {
                    return assignment;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteAssignment(id: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/assignments/${id}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Deletion request failed.');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getAssignments(): Promise<Array<Assignment>> {
        return fetch(`${API_BASE_URI}/assignments`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getInspections(): Promise<Array<Inspection>> {
        return fetch(`${API_BASE_URI}/inspections`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .then(inspectionsFromDtoEntities)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getInspectionsByAssignment(assignmentId: string | number): Promise<Array<Inspection>> {
        return fetch(`${API_BASE_URI}/assignments/${assignmentId}/inspections`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .then(inspectionsFromDtoEntities)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateInspection(inspection: Inspection): Promise<Inspection> {
        const reparsedSubjects = inspection.subjects.map((subject) => ({
            ...subject,
            position: `${subject.position.alignment} ${subject.position.axlePosition}`,
        }));
        const payloadToSubmit = {
            ...inspection,
            subjects: reparsedSubjects,
        };

        return fetch(
            `${API_BASE_URI}/inspections/${inspection.id}`,
            {
                body: JSON.stringify(payloadToSubmit),
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then(() => inspection)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createInspection(inspection: Inspection): Promise<Inspection> {
        return fetch(`${API_BASE_URI}/inspections`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.userToken ?? ''}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(inspection),
        })
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts) ?? 'unknown';

                    return {
                        ...inspection,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteInspection(id: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/inspections/${id}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Deletion request failed.');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getLocationsByClient(clientId: string | number): Promise<Array<Geolocation>> {
        return fetch(`${API_BASE_URI}/clients/${clientId}/locations`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => resp.json())
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getLocations(updatedSince?: number): Promise<Array<Geolocation>> {
        const headers: Record<string, string> = { Authorization: `Bearer ${this.userToken ?? ''}` };

        if (updatedSince) {
            headers['if-modified-since'] = new Date(updatedSince).toUTCString();
        }
        return fetch(`${API_BASE_URI}/locations`, {
            method: 'GET',
            headers,
        })
            .then((resp) => {
                if (resp.status === 304) {
                    return [];
                }
                return resp.json();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateEquipmentAsset(
        details: EquipmentAsset,
    ): Promise<EquipmentAsset> {
        return fetch(
            `${API_BASE_URI}/equipment/${details.id}`,
            {
                body: JSON.stringify(details),
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    return Promise.reject(new Error('Failed to update the equipment; please review your selections.'));
                }
                return resp;
            })
            .then(() => details)
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createEquipmentAsset(details: EquipmentAsset): Promise<EquipmentAsset> {
        return fetch(
            `${API_BASE_URI}/equipment`,
            {
                body: JSON.stringify(details),
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...details,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createLocation(location: Geolocation): Promise<Geolocation> {
        return fetch(
            `${API_BASE_URI}/locations`,
            {
                body: JSON.stringify(location),
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...location,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateLocation(location: Geolocation): Promise<Geolocation> {
        return fetch(
            `${API_BASE_URI}/locations/${location.id}`,
            {
                body: JSON.stringify(location),
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return location;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteLocation(locationId: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/locations/${locationId}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Did not receive successful response');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deletePhoto(id: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/images/${id}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Did not receive successful response');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getPhoto(id: string): Promise<Blob> {
        return fetch(`${API_BASE_URI}/images/${id}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
        })
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Failed to retrieve image by ID');
                }
                return resp.blob();
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    uploadPhoto(imageData: Blob): Promise<string> {
        const formData = new FormData();

        formData.append('file', imageData);

        return fetch(
            `${API_BASE_URI}/images`,
            {
                method: 'POST',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
                body: formData,
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    if (!id) {
                        throw new Error('Expected resource location');
                    }

                    return id;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getCategories(updatedSince?: number): Promise<Array<Category>> {
        const headers: Record<string, string> = { Authorization: `Bearer ${this.userToken ?? ''}` };

        if (updatedSince) {
            headers['if-modified-since'] = new Date(updatedSince).toUTCString();
        }
        return fetch(
            `${API_BASE_URI}/categories`,
            {
                method: 'GET',
                headers,
            },
        )
            .then((resp) => {
                if (resp.status === 304) {
                    return [];
                }
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getAssignmentShares(): Promise<Array<AssignmentShare>> {
        return fetch(
            `${API_BASE_URI}/shares`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createAssignmentShare(assignmentShare: AssignmentShare): Promise<AssignmentShare> {
        return fetch(
            `${API_BASE_URI}/shares`,
            {
                body: JSON.stringify(assignmentShare),
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...assignmentShare,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateAssignmentShare(assignmentShare: AssignmentShare): Promise<AssignmentShare> {
        return fetch(
            `${API_BASE_URI}/shares/${assignmentShare.id}`,
            {
                body: JSON.stringify(assignmentShare),
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return assignmentShare;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getShare(assignmentShareId: string): Promise<AssignmentShare> {
        return fetch(
            `${API_BASE_URI}/shares/${assignmentShareId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteAssignmentShare(assignmentShareId: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/shares/${assignmentShareId}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Did not receive successful response');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    getClientContacts(): Promise<Array<ClientContact>> {
        return fetch(
            `${API_BASE_URI}/contacts`,
            {
                method: 'GET',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    createClientContact(clientContact: ClientContact): Promise<ClientContact> {
        return fetch(
            `${API_BASE_URI}/contacts`,
            {
                body: JSON.stringify(clientContact),
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    const resourceUri = resp.headers.get('Location');
                    const resourceParts = resourceUri?.split('/') ?? [];
                    const id = last(resourceParts);

                    return {
                        ...clientContact,
                        id,
                    };
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    updateClientContact(clientContact: ClientContact): Promise<ClientContact> {
        return fetch(
            `${API_BASE_URI}/contacts/${clientContact.id}`,
            {
                body: JSON.stringify(clientContact),
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${this.userToken ?? ''}`,
                    'content-type': 'application/json',
                },
            },
        )
            .then((resp) => {
                if (resp.ok) {
                    return clientContact;
                }
                return Promise.reject(new Error('Did not receive successful response'));
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }

    deleteClientContact(clientContactId: string): Promise<void> {
        return fetch(
            `${API_BASE_URI}/contacts/${clientContactId}`,
            {
                method: 'DELETE',
                headers: { Authorization: `Bearer ${this.userToken ?? ''}` },
            },
        )
            .then((resp) => {
                if (!resp.ok) {
                    throw new Error('Did not receive successful response');
                }
            })
            .catch((e) => Promise.reject(new ApiError(e.message, ApiErrorType.FetchFailure)));
    }
}
