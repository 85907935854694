import { prop, uniq } from 'ramda';
import { AddNewAxleAction, State } from '../index';
import { createEmptyTireInspection } from '../../../types/constructors';
import { AxleAlignment, AxlePosition } from '../../../types';

export default function addNewAxle(state: State, action: AddNewAxleAction): State {
    const axleCount = uniq(state.subjects.map(prop('axle'))).length;
    const newAxleName = `Axle ${axleCount + 1}`;

    const leftSubject = {
        ...createEmptyTireInspection(),
        equipmentId: action.equipmentId,
        axle: newAxleName,
        position: {
            alignment: AxleAlignment.Left,
            axlePosition: AxlePosition.Outside,
        },
    };
    const rightSubject = {
        ...createEmptyTireInspection(),
        equipmentId: action.equipmentId,
        axle: newAxleName,
        position: {
            alignment: AxleAlignment.Right,
            axlePosition: AxlePosition.Outside,
        },
    };

    return {
        ...state,
        pendingSave: true,
        subjects: [
            ...state.subjects,
            leftSubject,
            rightSubject,
        ],
    };
}
