import React, { useEffect, useState } from 'react';

import { convertImageBlobToDataUrl } from '../utils/get-image-src';

interface Props {
    blob: Blob,
    photoAttributes?: object,
}

export default function Photo(props: Props) {
    const { blob, photoAttributes } = props;

    const [initialized, setInitialized] = useState(false);
    const [photoUrl, setPhotoUrl] = useState<string | null>(null);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);

            convertImageBlobToDataUrl(blob)
                .then(setPhotoUrl);
        }
    }, [blob, initialized]);

    if (!photoUrl) {
        return null;
    }
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...photoAttributes} src={photoUrl} />;
}
Photo.defaultProps = { photoAttributes: {} };
