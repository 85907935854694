import React from 'react';
import { Action, actionCreators } from '../index';
import Services from '../../../services';
import {
    EquipmentAsset,
    Inspection,
    InspectionStatus,
    SubjectCondition,
    TrackTireInspection,
} from '../../../types';

type Dispatcher = React.Dispatch<Action>;

const flattenSubjectPhotoIds = (
    memo: Array<string>,
    item: TrackTireInspection,
): Array<string> => [...memo, ...item.images];

export default function getInitializeStateHandler(
    dispatch: Dispatcher,
    apiServices: Services,
    handlePhotoRequested: (id: string) => void,
) {
    return (inspection: Inspection, asset: EquipmentAsset) => {
        const subjects = inspection?.subjects || [];

        dispatch(actionCreators.setStatus(inspection?.status ?? InspectionStatus.Open));
        dispatch(actionCreators.setCondition(inspection?.condition ?? SubjectCondition.Unknown));
        dispatch(actionCreators.setHours(inspection?.hours || null));
        dispatch(actionCreators.setNotes(inspection?.notes || null));
        dispatch(actionCreators.setIncludeInReport(inspection?.includeInReport || false));
        dispatch(actionCreators.setSubjects(subjects));

        dispatch(actionCreators.setSelectedSubject(null));

        if (asset?.primaryImageId) {
            handlePhotoRequested(asset.primaryImageId);
        }

        const subjectPhotosToLoad = subjects.reduce(flattenSubjectPhotoIds, []);

        subjectPhotosToLoad.forEach(handlePhotoRequested);

        dispatch(actionCreators.setInitialized());
    };
}
