import { Category } from '../types';

export default function tryFindParentCategory(
    categoryId: string,
    categories: Array<Category>,
): Category | null {
    const targetCategory = categories.find((item) => item.id === categoryId);

    if (targetCategory?.parentId === null) {
        return targetCategory;
    }

    const parentCategory = categories.find((item) => item.id === targetCategory?.parentId);

    return parentCategory ?? null;
}
