import React, { ChangeEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { SubjectCondition, Severity } from '../types';
import getConditionOptions from '../utils/get-inspection-options';
import { getSeverityBySubjectCondition } from '../utils/lookups';

interface Props {
    isReadOnly?: boolean,
    onChange: (status: SubjectCondition) => void,
    value: SubjectCondition,
    label: string,
    id: string,
    children?: React.ReactChildren | React.ReactElement | null,
}

interface EditModeProps {
    onChange: (value: SubjectCondition) => void,
    onCancel: () => void,
    id: string,
    value: SubjectCondition,
}

function EditMode(props: EditModeProps) {
    const {
        id, value, onChange, onCancel,
    } = props;

    const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newConditionValue = parseInt(event.target.value, 10);

        if (Number.isNaN(newConditionValue)) {
            throw new Error('Unknown condition value provided.');
        }

        onChange(newConditionValue as SubjectCondition);
    };

    return (
        <div className="modal">
            <select
                id={id}
                value={value}
                onChange={handleChange}
            >
                {getConditionOptions()}
            </select>
            <button type="button" onClick={onCancel}>Cancel</button>
        </div>
    );
}

interface ViewModeProps {
    isReadOnly: boolean,
    switchToEditMode: () => void,
    value: SubjectCondition,
}

function ViewMode(props: ViewModeProps) {
    const { switchToEditMode, value, isReadOnly } = props;
    const severity = getSeverityBySubjectCondition(value);

    return (
        <>
            <button disabled={isReadOnly} type="button" onClick={switchToEditMode}>Edit</button>
            <span className={classNames('severity', Severity[severity])} />
        </>
    );
}

export default function ConditionSelector(props: Props) {
    const {
        onChange,
        value,
        id,
        label,
        children,
        isReadOnly,
    } = props;

    const [inEditMode, setInEditMode] = useState(false);

    const setEditable = useCallback(() => {
        setInEditMode(!isReadOnly);
    }, [isReadOnly]);
    const updateValue = useCallback((newValue: SubjectCondition) => {
        setInEditMode(false);
        onChange(newValue);
    }, [onChange]);
    const cancelEditMode = useCallback(() => {
        setInEditMode(false);
    }, []);

    const innerControl = inEditMode
        ? (
            <EditMode
                id={id}
                value={value}
                onChange={updateValue}
                onCancel={cancelEditMode}
            />
        )
        : <ViewMode isReadOnly={isReadOnly ?? false} value={value} switchToEditMode={setEditable} />;

    return (
        <div className="condition-selector">
            <div className="inner">
                <label htmlFor={id}>{label}</label>
                {innerControl}
            </div>
            {children}
        </div>
    );
}
ConditionSelector.defaultProps = {
    children: null,
    isReadOnly: false,
};
