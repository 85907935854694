import React from 'react';
import { Link } from 'react-router-dom';
import { Category, Client } from '../types';

interface Props {
    client: Client,
    category: Category | null,
    parentCategory: Category | null,
}

interface CategoryNodeProps {
    client: Client,
    category: Category,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CategoryNode({ client, category }: CategoryNodeProps) {
    const target = `/clients/${client.id}/fleet/${category.id}`;

    return <Link to={target}>{category.singularName}</Link>;
}

export default function CategoryTrail(props: Props) {
    const { category, parentCategory, client } = props;

    const parts = [];

    if (!category) {
        return null;
    }
    if (parentCategory) {
        parts.push(<CategoryNode client={client} category={parentCategory} />);
        parts.push(<span>&gt;</span>);
    }
    parts.push(<CategoryNode client={client} category={category} />);

    return <div className="category-trail">{parts}</div>;
}
